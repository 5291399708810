.cookie-banner {
  position: fixed;

  z-index: 2;
  padding: 3.4rem 3.4rem 3.6rem;

  background: var(--smalt);
  color: var(--white);

  width: 100%;
  bottom: 0;

  -webkit-transform: translateY(100%);

          transform: translateY(100%);
  -webkit-transition: -webkit-transform var(--transition-duration--normal)
    var(--transition-ease--out-quintic);
  transition: -webkit-transform var(--transition-duration--normal)
    var(--transition-ease--out-quintic);
  transition: transform var(--transition-duration--normal)
    var(--transition-ease--out-quintic);
  transition: transform var(--transition-duration--normal)
    var(--transition-ease--out-quintic), -webkit-transform var(--transition-duration--normal)
    var(--transition-ease--out-quintic);
}

  @media (min-width: 768px) {.cookie-banner {
    padding-bottom: 5rem
}
  }

  .cookie-banner--enter-done {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  .cookie-banner__upper-wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
            justify-content: space-between;
  }

  .cookie-banner__upper-wrapper .svg {
      cursor: pointer;
    }

  .cookie-banner__bottom-wrapper {
    display: -webkit-box;
    display: flex;

    -webkit-box-orient: vertical;

    -webkit-box-direction: normal;

            flex-direction: column;
    -webkit-box-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
            align-items: center;
  }

  @media (min-width: 768px) {

  .cookie-banner__bottom-wrapper {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
              flex-direction: row
  }
    }

  .cookie-banner__title {
    font-weight: var(--font-bold);
    margin-top: 0;
  }

  .cookie-banner__description {
    margin-top: 0;
  }

  @media (min-width: 1024px) {

  .cookie-banner__description {
      width: calc(100% / 3 * 2)
  }
    }

  @media (min-width: 1200px) {

  .cookie-banner__description {
      width: calc(100% / 2)
  }
    }

  .cookie-banner__buttons {
    width: 100%;

    margin-top: 2.4rem;
  }

  @media (min-width: 768px) {

  .cookie-banner__buttons {
      width: auto;

      margin-top: 0;
      margin-left: 3.4rem
  }
    }

  .cookie-banner__buttons .button {
      width: 100%;
      margin: 0;
    }

  @media (min-width: 768px) {

  .cookie-banner__buttons .button {
        width: auto;

        padding-left: 6.8rem;
        padding-right: 6.8rem
    }
      }
