.parallax-container {
  position: relative;
  z-index: 0;
  height: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

  .parallax-container--friend_quote {
    font-size: 3.1rem;
    font-family: 'GT Sectra Display';
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
  }

  @media (min-width: 768px) {

  .parallax-container--friend_quote {
      display: none
  }
    }

  .parallax-container .media {
    -webkit-touch-callout: none;
    position: absolute;
    -o-object-fit: cover;
       object-fit: cover;
    will-change: transform;
  }
