body {
  font-family: var(--font-paragraph);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-heading);

  margin: 0;

  color: var(--smalt);
}

h1 {
  font-size: 3.5rem;
  line-height: 4.3rem;
}

@media (min-width: 1024px) {

h1 {
    font-size: 6rem;
    line-height: 7.3rem
}
  }

h2 {
  font-size: 2.5rem;
  font-weight: var(--font-normal);
}

/*
h3 {
  font-size: 1.7rem;
}
*/
