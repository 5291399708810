.navitem--orbiting .active .svg svg g, .navitem--orbiting:hover .svg svg g, .navitem--orbiting:focus-within .svg svg g {
          opacity: 1;
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
    .navitem--orbiting .active .svg svg g path {
      -webkit-animation-play-state: paused;
              animation-play-state: paused;
    }
    .navitem--orbiting:hover .svg svg g path, .navitem--orbiting:focus-within .svg svg g path {
        -webkit-animation-play-state: running;
                animation-play-state: running;
      }
    .navitem--orbiting li {
      position: relative;
    }
    .navitem--orbiting .svg {
      position: relative;
      top: 1.4rem;
      left: 2rem;
    }
    .navitem--orbiting .svg svg g {
        -webkit-transition: 0.27s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: 0.27s cubic-bezier(0.645, 0.045, 0.355, 1);
        -webkit-transition-property: opacity, -webkit-transform;
        transition-property: opacity, -webkit-transform;
        transition-property: opacity, transform;
        transition-property: opacity, transform, -webkit-transform;
        -webkit-transform: scale(0.6);
                transform: scale(0.6);
        -webkit-transform-origin: center;
                transform-origin: center;
        opacity: 0;
      }
    .navitem--orbiting .svg svg g path {
          -webkit-transform-origin: center;
                  transform-origin: center;

          -webkit-animation: headerRotateCounterclockwise 25s linear infinite;

                  animation: headerRotateCounterclockwise 25s linear infinite;
          -webkit-animation-play-state: paused;
                  animation-play-state: paused;
        }
    /* &:nth-child(1) {
          transition-delay: 0s;
          path {
            animation-duration: 25s;
          }
        } */
    .navitem--orbiting .svg svg g:nth-child(2) {
          -webkit-transition-delay: 0.1s;
                  transition-delay: 0.1s;
        }
    .navitem--orbiting .svg svg g:nth-child(2) path {
            -webkit-animation-duration: 15s;
                    animation-duration: 15s;
          }
    .navitem--orbiting .svg svg g:nth-child(3) {
          -webkit-transition-delay: 0.2s;
                  transition-delay: 0.2s;
        }
    .navitem--orbiting .svg svg g:nth-child(3) path {
            -webkit-animation-name: headerRotateClockwise;
                    animation-name: headerRotateClockwise;
            -webkit-animation-duration: 10s;
                    animation-duration: 10s;
          }
  .navitem--dropdown {
    overflow: hidden;
  }
  .navitem--dropdown .active span, .navitem--dropdown:hover span, .navitem--dropdown:focus-within span {
        -webkit-transform: translateY(100%);
                transform: translateY(100%);
      }
  .navitem--dropdown a span {
        position: relative;
        display: inline-block;

        -webkit-transition: -webkit-transform var(--transition-duration--normal)
          var(--transition-ease--out-quintic);

        transition: -webkit-transform var(--transition-duration--normal)
          var(--transition-ease--out-quintic);

        transition: transform var(--transition-duration--normal)
          var(--transition-ease--out-quintic);

        transition: transform var(--transition-duration--normal)
          var(--transition-ease--out-quintic), -webkit-transform var(--transition-duration--normal)
          var(--transition-ease--out-quintic);
      }
  .navitem--dropdown a span::before {
          color: var(--scooter);
          position: absolute;
          top: 100%;
          content: attr(title);
          -webkit-transform: translateY(-200%);
                  transform: translateY(-200%);
        }
  .navitem a {
    outline: none;
  }

@-webkit-keyframes headerRotateClockwise {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes headerRotateClockwise {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes headerRotateCounterclockwise {
  from {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@keyframes headerRotateCounterclockwise {
  from {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
