.app {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;

  opacity: 0;
}
  .app--mounted {
    opacity: 1;
  }

body.lifeline-output-body #app {
    height: 100%;
  }

body.lifeline-output-body .app {
    height: 100%;
  }
