.footer {
  border-top: 0.1rem solid var(--smalt);
  position: relative;
  background-color: var(--white);
  z-index: 2;
}

  @media (min-width: 768px) {.footer {
    height: 33.7rem
}
  }

  .lifeline-output-recording .footer {
    display: none;
  }

  .footer a {
    text-decoration: none;
  }

  .footer-link__simple {
    -webkit-transition: opacity var(--transition-duration--normal);
    transition: opacity var(--transition-duration--normal);
    outline: none;
  }

  @media (min-width: 1024px) {

  .footer-link__simple:hover,
    .footer-link__simple:focus {
        opacity: 0.4
    }
      }

  .footer .container {
    height: 100%;
    padding: 5.6vw;
    z-index: 2;
    --container-size: 100vw;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: justify;
            justify-content: space-between;
  }

  @media (min-width: 768px) {

  .footer .container {
      padding: 2.5rem 4rem;
      -webkit-box-pack: end;
              justify-content: flex-end
  }
    }

  .footer .container .section.main {
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
                flex-direction: column;
        -webkit-box-pack: justify;
                justify-content: space-between;
      }

  @media (min-width: 768px) {

  .footer .container .section.main {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
                  flex-direction: row;
          -webkit-box-align: center;
                  align-items: center;
          margin-bottom: 8rem
      }
        }

  .footer .container .section.main .loading-shape-wrapper {
          position: relative;
          width: 8rem;
          height: 8.8rem;
          cursor: pointer;
        }

  @media (min-width: 768px) {

  .footer .container .section.main .loading-shape-wrapper {
            width: 12rem;
            height: 8.8rem
        }
          }

  .footer .container .section.main .loading-shape-wrapper .loading-shape {
            top: 50%;
          }

  .footer .container .section.main .footer-menu-contacts-wrapper {
          display: -webkit-box;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
                  flex-direction: column;
        }

  @media (min-width: 768px) {

  .footer .container .section.main .footer-menu-contacts-wrapper {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
                    flex-direction: row
        }
          }

  .footer .container .section.main .footer-menu-contacts-wrapper .footer-menu-wrapper {
            display: -webkit-box;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
                    flex-direction: row;
            margin: 3vh 0 6vh;
          }

  .footer .container .section.main .footer-menu-contacts-wrapper .footer-menu-wrapper .menu-link {
              overflow: hidden;
            }

  .footer .container .section.main .footer-menu-contacts-wrapper .footer-menu-wrapper .menu-link .active span, .footer .container .section.main .footer-menu-contacts-wrapper .footer-menu-wrapper .menu-link:hover span, .footer .container .section.main .footer-menu-contacts-wrapper .footer-menu-wrapper .menu-link:focus-within span {
                  -webkit-transform: translateY(100%);
                          transform: translateY(100%);
                }

  .footer .container .section.main .footer-menu-contacts-wrapper .footer-menu-wrapper .menu-link a span {
                  position: relative;
                  display: inline-block;

                  -webkit-transition: -webkit-transform var(--transition-duration--normal)
                    var(--transition-ease--out-quintic);

                  transition: -webkit-transform var(--transition-duration--normal)
                    var(--transition-ease--out-quintic);

                  transition: transform var(--transition-duration--normal)
                    var(--transition-ease--out-quintic);

                  transition: transform var(--transition-duration--normal)
                    var(--transition-ease--out-quintic), -webkit-transform var(--transition-duration--normal)
                    var(--transition-ease--out-quintic);
                }

  .footer .container .section.main .footer-menu-contacts-wrapper .footer-menu-wrapper .menu-link a span::before {
                    color: var(--scooter);
                    position: absolute;
                    top: 100%;
                    content: attr(title);
                    -webkit-transform: translateY(-200%);
                            transform: translateY(-200%);
                  }

  .footer .container .section.main .footer-menu-contacts-wrapper .footer-menu-wrapper .active span {
              -webkit-transform: none;
                      transform: none;
            }

  @media (min-width: 1024px) {

  .footer .container .section.main .footer-menu-contacts-wrapper .footer-menu-wrapper span:hover {
                -webkit-transform: translateY(100%);
                        transform: translateY(100%)
            }
              }

  @media (min-width: 768px) {

  .footer .container .section.main .footer-menu-contacts-wrapper .footer-menu-wrapper {
              margin: 0 16vw 0 0
          }
            }

  @media (min-width: 1024px) {

  .footer .container .section.main .footer-menu-contacts-wrapper .footer-menu-wrapper {
              margin: 0 13vw 0 0
          }
            }

  @media (min-width: 1200px) {

  .footer .container .section.main .footer-menu-contacts-wrapper .footer-menu-wrapper {
              margin: 0 19rem 0 0
          }
            }

  .footer .container .section.main .footer-menu-contacts-wrapper .footer-menu-wrapper ul {
              font-size: 1.3rem;
            }

  .footer .container .section.main .footer-menu-contacts-wrapper .footer-menu-wrapper ul .navitem--orbiting {
                position: relative;
              }

  .footer .container .section.main .footer-menu-contacts-wrapper .footer-menu-wrapper ul .navitem--orbiting .active .svg svg g {
                      opacity: 0;
                    }

  @media (min-width: 1024px) {

  .footer .container .section.main .footer-menu-contacts-wrapper .footer-menu-wrapper ul .navitem--orbiting:hover .svg svg g {
                        opacity: 1
                    }
                      }

  .footer .container .section.main .footer-menu-contacts-wrapper .footer-menu-wrapper ul .navitem--orbiting .svg {
                position: absolute;
                top: 50%;
                left: 0;
                -webkit-transform: translate(-50%, -50%);
                        transform: translate(-50%, -50%);
              }

  .footer .container .section.main .footer-menu-contacts-wrapper .footer-menu-wrapper ul:first-child {
                margin-right: 24vw;
                font-weight: var(--font-semibold);
              }

  @media (min-width: 768px) {

  .footer .container .section.main .footer-menu-contacts-wrapper .footer-menu-wrapper ul:first-child {
                  margin-right: 16vw
              }
                }

  @media (min-width: 1024px) {

  .footer .container .section.main .footer-menu-contacts-wrapper .footer-menu-wrapper ul:first-child {
                  margin-right: 13vw
              }
                }

  @media (min-width: 1200px) {

  .footer .container .section.main .footer-menu-contacts-wrapper .footer-menu-wrapper ul:first-child {
                  margin-right: 19rem
              }
                }

  .footer .container .section.main .footer-menu-contacts-wrapper .footer-menu-wrapper ul li {
                margin: 3rem 0;
              }

  @media (min-width: 768px) {

  .footer .container .section.main .footer-menu-contacts-wrapper .footer-menu-wrapper ul li:first-child {
                    margin-top: 0
                }
                  }

  @media (min-width: 768px) {

  .footer .container .section.main .footer-menu-contacts-wrapper .footer-menu-wrapper ul li:last-child {
                    margin-bottom: 0
                }
                  }

  .footer .container .section.main .footer-menu-contacts-wrapper .contacts {
            display: -webkit-box;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                    flex-direction: column;
          }

  @media (min-width: 768px) {

  .footer .container .section.main .footer-menu-contacts-wrapper .contacts {
              margin-right: 0
          }
            }

  @media (min-width: 1024px) {

  .footer .container .section.main .footer-menu-contacts-wrapper .contacts {
              margin-right: calc(13vw - 4rem)
          }
            }

  @media (min-width: 1200px) {

  .footer .container .section.main .footer-menu-contacts-wrapper .contacts {
              margin-right: 19rem
          }
            }

  .footer .container .section.main .footer-menu-contacts-wrapper .contacts .buttons-wrapper {
              margin: 6vh 0;
              display: -webkit-box;
              display: flex;
            }

  @media (min-width: 768px) {

  .footer .container .section.main .footer-menu-contacts-wrapper .contacts .buttons-wrapper {
                margin: 5rem 0 0
            }
              }

  .footer .container .section.main .footer-menu-contacts-wrapper .contacts .buttons-wrapper .button-wrapper {
                width: 3.8rem;
                height: 3.8rem;
                border: 0.5px solid var(--smalt);
                border-radius: 50%;
                margin-right: 1.1rem;
                position: relative;
              }

  .footer .container .section.main .footer-menu-contacts-wrapper .contacts .buttons-wrapper .button-wrapper a {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  display: -webkit-box;
                  display: flex;
                  -webkit-box-align: center;
                          align-items: center;
                  outline: none;
                }

  .footer .container .section.main .footer-menu-contacts-wrapper .contacts .buttons-wrapper .button-wrapper a .icons-wrapper {
                    width: 100%;
                    height: 1.8rem;
                    overflow: hidden;
                    position: relative;
                  }

  .footer .container .section.main .footer-menu-contacts-wrapper .contacts .buttons-wrapper .button-wrapper a .icons-wrapper .svg {
                      position: absolute;
                      left: 50%;
                      -webkit-transition: -webkit-transform var(--transition-duration--normal)
                        var(--transition-ease--out-quintic);
                      transition: -webkit-transform var(--transition-duration--normal)
                        var(--transition-ease--out-quintic);
                      transition: transform var(--transition-duration--normal)
                        var(--transition-ease--out-quintic);
                      transition: transform var(--transition-duration--normal)
                        var(--transition-ease--out-quintic), -webkit-transform var(--transition-duration--normal)
                        var(--transition-ease--out-quintic);
                    }

  .footer .container .section.main .footer-menu-contacts-wrapper .contacts .buttons-wrapper .button-wrapper a .icons-wrapper .svg--facebook_blue,
                      .footer .container .section.main .footer-menu-contacts-wrapper .contacts .buttons-wrapper .button-wrapper a .icons-wrapper .svg--facebook_green {
                        width: 8px;
                      }

  .footer .container .section.main .footer-menu-contacts-wrapper .contacts .buttons-wrapper .button-wrapper a .icons-wrapper .svg:first-of-type {
                      -webkit-transform: translate(-50%, 0);
                              transform: translate(-50%, 0);
                    }

  .footer .container .section.main .footer-menu-contacts-wrapper .contacts .buttons-wrapper .button-wrapper a .icons-wrapper .svg:last-of-type {
                      -webkit-transform: translate(-50%, -100%);
                              transform: translate(-50%, -100%);
                    }

  @media (min-width: 1024px) {
                        .footer .container .section.main .footer-menu-contacts-wrapper .contacts .buttons-wrapper .button-wrapper a:hover .icons-wrapper .svg:first-of-type, .footer .container .section.main .footer-menu-contacts-wrapper .contacts .buttons-wrapper .button-wrapper a:focus .icons-wrapper .svg:first-of-type {
                          -webkit-transform: translate(-50%, 100%);
                                  transform: translate(-50%, 100%);
                        }

                        .footer .container .section.main .footer-menu-contacts-wrapper .contacts .buttons-wrapper .button-wrapper a:hover .icons-wrapper .svg:last-of-type, .footer .container .section.main .footer-menu-contacts-wrapper .contacts .buttons-wrapper .button-wrapper a:focus .icons-wrapper .svg:last-of-type {
                          -webkit-transform: translate(-50%, 0);
                                  transform: translate(-50%, 0);
                        }
                    }

  .footer .container .section.bottom {
        width: 100%;
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
                flex-direction: row;
        -webkit-box-pack: justify;
                justify-content: space-between;
        -webkit-box-align: end;
                align-items: flex-end;
        font-size: 1.2rem;
      }

  .footer .container .section.bottom a {
          color: black;
        }

  .footer .container .section.bottom p {
          margin: 0;
        }

  .footer .container .section.bottom div {
          display: -webkit-box;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
                  flex-direction: column;
        }

  @media (min-width: 768px) {

  .footer .container .section.bottom div {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
                    flex-direction: row
        }
          }

  .footer .container .section.bottom div a {
            margin-bottom: 1.2rem;
          }

  @media (min-width: 768px) {

  .footer .container .section.bottom div a {
              margin-bottom: 0;
              margin-right: 6rem
          }
            }

  .footer .container .section.bottom div a:last-child {
            margin-bottom: 0;
          }
