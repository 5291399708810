.modal {
  width: 100%;
  height: 100%;
  padding: 2rem;

  position: fixed;
  top: 0;
  left: 0;

  display: -webkit-box;

  display: flex;

  overflow: auto;

  opacity: 1;
  z-index: 100;
}

  .modal--close {
    width: 1.5rem;
    height: 1.5rem;

    position: absolute;
    top: 1.3rem;
    right: 1.1rem;

    cursor: pointer;

    background-image: url(https://www.memories.com/assets/images/cross-black--164f933b9bdf9df0729bc911febc6f62.svg);
    background-size: contain;
    background-position: center;
  }

  @media (min-width: 1024px) {

  .modal--close {
      width: 2.3rem;
      height: 2.3rem;

      top: 1.7rem;
      right: 1.6rem
  }
    }

  .modal--overlay {
    content: '';

    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 54, 152, 0.8);

    width: 100%;
    height: 100%;
  }

  .modal--overlay--white {
      background: rgba(255, 255, 255, 0.6);
    }

  .modal--content {
    margin: auto;

    position: relative;

    background: var(--white);
  }

  .modal--content--medium {
      max-width: 76rem;
      width: 100%;
    }

  .modal--content--large {
      max-width: 93rem;
      width: 100%;
    }

  @media (min-width: 1024px) {

  .modal--content {
      min-width: 60rem
  }
    }

  .modal--enter {
    opacity: 0;
  }

  .modal--enter-active {
    opacity: 1;
    -webkit-transition: opacity var(--transition-duration--instant);
    transition: opacity var(--transition-duration--instant);
  }

  .modal--exit {
    opacity: 1;
  }

  .modal--exit-active {
    opacity: 0;
    -webkit-transition: opacity var(--transition-duration--instant);
    transition: opacity var(--transition-duration--instant);
  }
