.lifeline-family--label {
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-pack: end;
          justify-content: flex-end;
}

  @media (min-width: 768px) {.lifeline-family--label {
    max-width: 28rem
}
  }

  @media (min-width: 768px) {

  .lifeline-family--cover + .lifeline-family--label {
      margin-left: calc(var(--column) * 10 + var(--gutter) * 9)
  }
    }

  .lifeline-family--label h3 {
    font-size: 4rem;
    line-height: 4.1rem;
    padding-left: 6.6rem;
  }

  @media (min-width: 768px) {

  .lifeline-family--label h3 {
      font-size: 10rem;
      line-height: 8.9rem;
      padding-left: calc(var(--column) * 2 + var(--gutter) * 1);
      -webkit-transform-origin: left bottom;
              transform-origin: left bottom;
      -webkit-transform: rotate(-90deg) translateY(100%);
              transform: rotate(-90deg) translateY(100%)
  }
    }
