.lifeline-decades {
  --column-width: calc((100vw - 4rem - 11 * 0.5rem) / 12);

  /* 2 decades * 3 steps per decade + 1 transition*/
  height: 600%;

  position: relative;

  -webkit-user-select: none;

     -moz-user-select: none;

      -ms-user-select: none;

          user-select: none;

  -webkit-transition: background-color var(--output-background-transition-speed)
    var(--output-background-easing);

  transition: background-color var(--output-background-transition-speed)
    var(--output-background-easing);

  overflow-x: hidden;
}

  @media (min-width: 768px) {.lifeline-decades {
    --column-width: calc((100vw - 16rem - 11 * 2rem) / 10)
}
  }

  @media (min-width: 1200px) {.lifeline-decades {
    --column-width: calc((100vw - 16rem - 11 * 2rem) / 16)
}
  }

  .lifeline-decades.twenties {
    height: 1120%;

    /* background: linear-gradient(
        45deg,
        transparent 25%,
        #fdfdfc55 25%,
        #fdfdfc55 50%,
        transparent 50%,
        transparent 75%,
        #fdfdfc55 75%
      )
      repeat center / 96px 96px; */
  }

  .lifeline-decades__beacon__background1 {
  }

  .lifeline-decades__beacon__background2__end {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: red;
  }

  .lifeline-decades__decade {
    height: 100%;

    position: relative;
    z-index: 1;
  }

  .twenties .lifeline-decades__decade {
      height: 50%;
    }

  .lifeline-decades__decade_transition {
      width: 100%;
      height: 1.78571428%;

      position: absolute;
      top: 50%;
    }

  .lifeline-decades__year {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
            align-items: center;

    width: 100%;
    height: 100%;

    position: fixed;
    top: 0;

    font-family: var(--font-heading);
    color: var(--white);
    text-align: center;

    -webkit-transition: opacity 1s ease-in-out;

    transition: opacity 1s ease-in-out;
  }

  .lifeline-decades__year--number,
    .lifeline-decades__year--subtitle {
      -webkit-transform: translateY(
        -0.9rem
      );
              transform: translateY(
        -0.9rem
      ); /* this is because the font has digit smaller than line-height */
    }

  .lifeline-decades__year--number {
      font-size: 9rem;
    }

  .lifeline-decades__year--subtitle {
      width: 100%;

      font-size: 2.2rem;
      -webkit-transition: opacity 1s ease-in-out, max-height 1s ease-in-out;
      transition: opacity 1s ease-in-out, max-height 1s ease-in-out;
      position: relative;
    }

  .lifeline-decades__year--subtitle > * {
        -webkit-transition: opacity 1s ease-in-out;
        transition: opacity 1s ease-in-out;
      }

  .show-second-decade .lifeline-decades__year--subtitle .first-decade,
        .show-second-decade .lifeline-decades__year--subtitle .second-decade {
          width: 100%;
          padding: 0 2rem;
        }

  .show-second-decade .lifeline-decades__year--subtitle .first-decade {
          opacity: 0;
        }

  .show-second-decade .lifeline-decades__year--subtitle .second-decade {
          opacity: 1;
        }

  .lifeline-decades__year--subtitle .second-decade {
        width: 88%;
        position: absolute;
        top: 0;
        left: 50%;

        -webkit-transform: translateX(-50%);

                transform: translateX(-50%);
        opacity: 0;
      }

  @media (min-width: 768px) {

  .lifeline-decades__year--subtitle .second-decade {
          width: auto;
          white-space: nowrap
      }
        }

  @media (min-width: 768px) {
      .lifeline-decades__year--number {
        font-size: 17rem;
        line-height: 17rem;
      }
      .lifeline-decades__year--subtitle {
        margin-top: 6.4rem;
        font-size: 4.8rem;
        line-height: 4rem;
        max-height: 10rem;
      }
    }

  @media (min-width: 1600px) {
      .lifeline-decades__year--subtitle {
        font-size: 6rem;
        line-height: 1.17em;
      }
    }

  .lifeline-decades__year.faded {
      opacity: 0.2;
    }

  .lifeline-decades__year.faded--subtitle .lifeline-decades__year--subtitle {
        opacity: 0;
        /* max-height: 0rem; */
      }

  .lifeline-decades__copy-container {
    height: 100%;
    position: relative;
    z-index: 2;
  }

  .lifeline-decades__copy-container p:first-child {
      margin-top: 0;
    }

  .lifeline-decades__copy {
    display: none;

    width: 100%;
    max-width: 63rem;
    padding: 0 2rem;

    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, calc(-50% + 4rem));
            transform: translate(-50%, calc(-50% + 4rem));
    color: var(--white);
    font-family: var(--font-heading);
    text-align: center;
    margin: 0;

    font-size: 2.2rem;
    line-height: 25px;

    pointer-events: none;

    -webkit-transition: opacity 1s ease-in-out, -webkit-transform 1s ease-in-out;

    transition: opacity 1s ease-in-out, -webkit-transform 1s ease-in-out;

    transition: transform 1s ease-in-out, opacity 1s ease-in-out;

    transition: transform 1s ease-in-out, opacity 1s ease-in-out, -webkit-transform 1s ease-in-out;
  }

  @media (min-width: 768px) {

  .lifeline-decades__copy {
      width: 50%;
      padding: 0;

      font-size: 3rem;
      line-height: 4rem
  }
    }

  @media (min-width: 1600px) {

  .lifeline-decades__copy {
      width: 60%;
      max-width: 120rem;

      font-size: 4.8rem;
      line-height: 1.37em
  }
    }

  .lifeline-decades__copy .smallcap {
      font-size: 80%;
    }

  .lifeline-decades__copy.copy_2,
    .lifeline-decades__copy.copy_3 {
      font-family: var(--font-paragraph);
      font-size: 2rem;
      line-height: 2.7rem;
    }

  @media (min-width: 1600px) {

  .lifeline-decades__copy.copy_2,
    .lifeline-decades__copy.copy_3 {
        font-size: 4rem;
        line-height: 1.3em
    }
      }

  .lifeline-decades__copy.enter {
      display: block;
      opacity: 0;
    }

  .lifeline-decades__copy.enter.active {
        opacity: 1;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
      }

  .lifeline-decades__copy.exit {
      display: block;
      opacity: 1;
    }

  .lifeline-decades__copy.exit.active {
        opacity: 0;
        -webkit-transform: translate(-50%, calc(-50% + 4rem));
                transform: translate(-50%, calc(-50% + 4rem));
      }

  .lifeline-decades__copy__beacon {
      visibility: hidden;
      /* background: yellow; */

      height: 1px;
      width: 100vw;
      position: absolute;
    }

  .lifeline-decades__copy__beacon.beacon_1 {
        top: 18%;
      }

  .lifeline-decades__copy__beacon.beacon_2 {
        top: 50%;
      }

  .lifeline-decades__copy__beacon.beacon_3 {
        height: 1rem;
        bottom: 18%;
      }

  .lifeline-decades__decade--second .lifeline-decades__copy__beacon.beacon_3 {
          top: 82%;
          bottom: auto;
        }

  .lifeline-decades__autoscroll-waypoint {
    width: 100%;
    position: absolute;
  }

  /* outline: 1px solid red; */

  .lifeline-decades__autoscroll-waypoint--1 {
      top: calc(18% - 50vh);
    }

  .lifeline-decades__autoscroll-waypoint--2 {
      top: 50%;
    }

  .lifeline-decades__autoscroll-waypoint--3 {
      bottom: calc(18% + 100vh);
    }

  .lifeline-decades__decade--second .lifeline-decades__autoscroll-waypoint--3 {
        top: 82%;
        bottom: auto;
      }

  .lifeline-decades__images-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 100%;
    /*max-width: 144rem;*/
    display: none;
  }

  .lifeline-decades__decade.image_enter .lifeline-decades__images-container,
    .lifeline-decades__decade.image_exit .lifeline-decades__images-container {
      display: block;
    }

  .lifeline-decades__images-container--birth span {
        background-color: rgb(55, 191, 209);
      }

  .lifeline-decades__images-container--birth span:nth-child(2) img {
        mix-blend-mode: luminosity;
      }

  .lifeline-decades__images-container--birth span:nth-child(6) img {
        mix-blend-mode: luminosity;
      }

  .lifeline-decades__images-container--twenty span {
        background-color: rgb(0, 54, 152);
      }

  .lifeline-decades__images-container--twenty span:nth-child(1) img {
        mix-blend-mode: luminosity;
      }

  .lifeline-decades__images-container--twenty span:nth-child(6) img {
        mix-blend-mode: luminosity;
      }

  .lifeline-decades__images-container span {
      font-size: 0;

      position: absolute;
      will-change: transform;
    }

  .lifeline-decades__images-container span img {
        width: 100%;
      }

  .lifeline-decades__images-container span:nth-child(1) {
        top: 5%;
        width: calc(1.1 * (2 * var(--column-width) + 20px));
        left: var(--column-width);
        z-index: 1;
      }

  @media (min-width: 1441px) {

  .lifeline-decades__images-container span:nth-child(1) {
          left: calc(80px + var(--column-width) + 20px)
      }

          @media (max-height: 768px) {

  .lifeline-decades__images-container span:nth-child(1) {
            width: calc(1.1 * (1.3 * var(--column-width) + 20px));
            left: calc(80px + 20px)
      }
          }
        }

  .lifeline-decades__images-container span:nth-child(2) {
        bottom: 70%;
        width: calc(1.1 * (4 * var(--column-width) + 3 * 20px));
        left: calc(2 * var(--column-width));
      }

  @media (min-width: 1441px) {

  .lifeline-decades__images-container span:nth-child(2) {
          left: calc(80px + 2 * (var(--column-width) + 20px))
      }

          @media (max-height: 768px) {

  .lifeline-decades__images-container span:nth-child(2) {
            width: calc(1.1 * (2.9 * var(--column-width) + 3 * 20px));
            left: calc(80px + 1.6 * (var(--column-width) + 20px))
      }
          }
        }

  .lifeline-decades__images-container span:nth-child(3) {
        top: 5%;
        right: var(--column-width);
        width: calc(1.1 * (3 * var(--column-width) + 2 * 20px));
      }

  @media (min-width: 1441px) {

  .lifeline-decades__images-container span:nth-child(3) {
          right: calc(80px + var(--column-width) + 20px)
      }

          @media (max-height: 768px) {

  .lifeline-decades__images-container span:nth-child(3) {
            width: calc(1.1 * (2.1 * var(--column-width) + 2 * 20px));
            right: calc(80px + 20px)
      }
          }
        }

  .lifeline-decades__images-container span:nth-child(4) {
        bottom: 5%;
        left: var(--column-width);
        width: calc(1.1 * (4 * var(--column-width) + 3 * 20px));
      }

  @media (min-width: 768px) {

  .lifeline-decades__images-container span:nth-child(4) {
          left: calc(80px + var(--column-width) + 20px)
      }

          @media (max-height: 768px) {

  .lifeline-decades__images-container span:nth-child(4) {
            width: calc(1.1 * (2.9 * var(--column-width) + 3 * 20px));
            left: calc(80px + 20px)
      }
          }
        }

  .lifeline-decades__images-container span:nth-child(5) {
        bottom: calc(5% + 66px);
        width: calc(1.1 * (2 * var(--column-width) + 20px));
        right: calc(3 * var(--column-width));

        z-index: 1;
      }

  @media (min-width: 768px) {

  .lifeline-decades__images-container span:nth-child(5) {
          right: calc(80px + 3 * (var(--column-width) + 20px))
      }

          @media (max-height: 768px) {

  .lifeline-decades__images-container span:nth-child(5) {
            width: calc(1.1 * (1.3 * var(--column-width) + 20px));
            right: calc(80px + 2.4 * (var(--column-width) + 20px))
      }
          }
        }

  .lifeline-decades__images-container span:nth-child(6) {
        bottom: 2%;
        right: var(--column-width);
        width: calc(1.1 * (4 * var(--column-width) + 3 * 20px));
      }

  @media (min-width: 768px) {

  .lifeline-decades__images-container span:nth-child(6) {
          right: 80px
      }

          @media (max-height: 768px) {

  .lifeline-decades__images-container span:nth-child(6) {
            width: calc(1.1 * (2.9 * var(--column-width) + 3 * 20px))
      }
          }
        }
