.lifeline-not-published-banner {
  position: fixed;
  bottom: 0;
  left: 0;

  width: 100%;

  padding: 1.5rem;
  z-index: 10;

  background: var(--smalt);

  display: -webkit-box;

  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
          justify-content: space-between;

  opacity: 1;
  -webkit-transition: opacity var(--transition-duration--fast) 0.3s
    var(--transition-ease--out-quintic);
  transition: opacity var(--transition-duration--fast) 0.3s
    var(--transition-ease--out-quintic);
}

  .lifeline-not-published-banner--enter {
    opacity: 0;
  }

  .lifeline-not-published-banner--enter-active {
    opacity: 1;
  }

  .lifeline-not-published-banner--exit {
    opacity: 1;
  }

  .lifeline-not-published-banner--exit-active {
    opacity: 0;
  }

  @media (min-width: 768px) {.lifeline-not-published-banner {
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;

    padding: 2rem 4rem
}
  }

  .lifeline-not-published-banner__desktop-text {
    display: none;
  }

  @media (min-width: 768px) {

  .lifeline-not-published-banner__desktop-text {
      display: block
  }
    }

  @media (min-width: 768px) {

  .lifeline-not-published-banner__mobile-text {
      display: none
  }
    }

  .lifeline-not-published-banner__text {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
            justify-content: space-between;

    width: 100%;
  }

  .lifeline-not-published-banner__cross {
    cursor: pointer;
    margin-left: 3rem;
  }

  @media (min-width: 768px) {

  .lifeline-not-published-banner__cross {
      position: absolute;
      top: 50%;
      right: 4rem;

      -webkit-transform: translateY(-50%);

              transform: translateY(-50%)
  }
    }

  .lifeline-not-published-banner__cross svg {
      width: 2.1rem;
      height: 2.1rem;
    }

  .lifeline-not-published-banner p {
    font-size: 1.5rem;
    margin: 0;
    line-height: 2rem;
    color: var(--white);
  }

  .lifeline-not-published-banner .button {
    margin: 2rem 0 0;
    flex-shrink: 0;
  }

  @media (min-width: 768px) {

  .lifeline-not-published-banner .button {
      margin: 0 4rem 0 10rem
  }
    }
