.button {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;

  outline: none;

  margin-left: 1rem;
  margin-right: 1rem;

  position: relative;
  overflow: hidden;

  font-size: 1.5rem;
  font-family: var(--font-paragraph);
  text-align: center;
  line-height: 18px;
  color: var(--white);
  text-decoration: none;

  border-radius: 4.8rem;
  border: 1px solid var(--white);
  background: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;

  -webkit-transition: var(--transition-duration--normal)
    var(--transition-ease--out-quintic);

  transition: var(--transition-duration--normal)
    var(--transition-ease--out-quintic);
  -webkit-transition-property: background-color, color, opacity, box-shadow,
    padding-right, -webkit-transform;
  transition-property: background-color, color, opacity, box-shadow,
    padding-right, -webkit-transform;
  transition-property: background-color, color, opacity, box-shadow,
    transform /* , border-color */, padding-right;
  transition-property: background-color, color, opacity, box-shadow,
    transform , padding-right, -webkit-transform;
}

  @media (min-width: 1024px) and (hover: hover) {
    .button:hover {
      box-shadow: 0 6px 12px rgba(255, 255, 255, 0.4);
      -webkit-transform: translateY(-5px);
              transform: translateY(-5px);
    }
  }

  .button:focus {
    box-shadow: 0 6px 12px rgba(255, 255, 255, 0.4);
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
  }

  .button__loading {
    position: absolute;

    top: 50%;
    right: 3rem;

    width: 1em;
    height: 1em;

    margin: -0.5em;
    opacity: 0;

    font-size: 1.7rem;

    -webkit-transition: all 0.3s;

    transition: all 0.3s;
    -webkit-transition-timing-function: ease;
            transition-timing-function: ease;
    -webkit-transition-timing-function: ease-in;
            transition-timing-function: ease-in;
    -webkit-animation: loadingSpin 1s infinite linear;
            animation: loadingSpin 1s infinite linear;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;

    -webkit-transition: opacity var(--transition-duration--normal)
      var(--transition-ease--out-quintic);

    transition: opacity var(--transition-duration--normal)
      var(--transition-ease--out-quintic);
  }

  .button--icon-left .button__loading {
      left: 3rem;
      right: auto;
    }

  .button__loading::after {
      content: '';

      display: block;

      width: 2em;
      height: 2em;

      box-sizing: border-box;

      -webkit-transform-origin: 0 0;

              transform-origin: 0 0;
      -webkit-transform: translateZ(0) scale(0.5);
              transform: translateZ(0) scale(0.5);

      -webkit-backface-visibility: hidden;

              backface-visibility: hidden;
      border-radius: 50%;

      border: 0.3em solid currentColor;
      border-left-color: currentcolor;
      border-left-color: transparent;
    }

  .button--loading {
    padding-right: 7rem !important;
  }

  .button--loading .button__loading {
      opacity: 1;
      -webkit-animation-play-state: running;
              animation-play-state: running;
    }

  .button::before {
    content: '';
    display: block;

    width: 100%;
    height: 100%;

    position: absolute;
    z-index: 1;

    border-radius: 2.6rem;
    background: transparent;

    -webkit-transition: background-color 0.8s var(--transition-ease--out-quintic),
      -webkit-transform var(--transition-duration--normal) 0.8s
        var(--transition-ease--out-quintic);

    transition: background-color 0.8s var(--transition-ease--out-quintic),
      -webkit-transform var(--transition-duration--normal) 0.8s
        var(--transition-ease--out-quintic);

    transition: transform var(--transition-duration--normal) 0.8s
        var(--transition-ease--out-quintic),
      background-color 0.8s var(--transition-ease--out-quintic);

    transition: transform var(--transition-duration--normal) 0.8s
        var(--transition-ease--out-quintic),
      background-color 0.8s var(--transition-ease--out-quintic),
      -webkit-transform var(--transition-duration--normal) 0.8s
        var(--transition-ease--out-quintic);
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }

  .button--success {
    border: none;
    background-color: var(--apple) !important;

    -webkit-transition: background-color 0s step-start 0.8s;

    transition: background-color 0s step-start 0.8s;
  }

  .button--success:hover,
    .button--success:focus {
      box-shadow: none !important;
      -webkit-transform: none !important;
              transform: none !important;
    }

  .button--success::before {
      -webkit-transition: background-color 0s step-start, border 0s step-start,
        -webkit-transform var(--transition-duration--normal)
          var(--transition-ease--out-quintic);
      transition: background-color 0s step-start, border 0s step-start,
        -webkit-transform var(--transition-duration--normal)
          var(--transition-ease--out-quintic);
      transition: transform var(--transition-duration--normal)
          var(--transition-ease--out-quintic),
        background-color 0s step-start, border 0s step-start;
      transition: transform var(--transition-duration--normal)
          var(--transition-ease--out-quintic),
        background-color 0s step-start, border 0s step-start,
        -webkit-transform var(--transition-duration--normal)
          var(--transition-ease--out-quintic);

      border: 1px solid var(--apple);
      background-color: var(--apple);
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

  .button__checkmark {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;

    -webkit-transform: translate(-50%, -50%);

            transform: translate(-50%, -50%);

    opacity: 0;

    -webkit-transition: opacity var(--transition-duration--normal)
      var(--transition-ease--out-quintic);

    transition: opacity var(--transition-duration--normal)
      var(--transition-ease--out-quintic);
  }

  .button__checkmark svg {
      width: 1.6rem;
      height: 1.6rem;
      stroke: var(--white);
    }

  .button--success .button__checkmark {
      -webkit-transition-delay: 0.4s;
              transition-delay: 0.4s;
      opacity: 1;
    }

  .button--icon-left .button__children-wrapper {
      -webkit-box-ordinal-group: 3;
              order: 2;
    }

  .button__icon {
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: center;
            justify-content: center;

    width: 3.2rem;
    height: 3.2rem;

    margin-left: 1.1rem;

    -webkit-transition: opacity var(--transition-duration--normal)
      var(--transition-ease--out-quintic);

    transition: opacity var(--transition-duration--normal)
      var(--transition-ease--out-quintic);
  }

  .button--size-small .button__icon {
      width: 2.7rem;
      height: 2.7rem;
    }

  .button--icon-left .button__icon {
      margin-left: 0;
      margin-right: 1.1rem;
    }

  .button__icon--bg-remembering svg rect {
        fill: var(--lifeline-remembering) !important;
      }

  .button__icon--bg-relationships svg rect {
        fill: var(--lifeline-relationships) !important;
      }

  .button__icon--bg-achievements svg rect {
        fill: var(--lifeline-achievements) !important;
      }

  .button__icon--bg-white svg rect {
        fill: var(--white) !important;
      }

  .button__icon--bg-white svg path {
        stroke: var(--smalt) !important;
      }

  .button--success .button__icon {
      opacity: 0;
    }

  .button__icon svg {
      width: 100%;
      height: 100%;
    }

  .button--shake {
    -webkit-animation: buttonShake 1s linear;
            animation: buttonShake 1s linear;
  }

  .button--has-icon {
    font-family: var(--font-heading);
    font-size: 1.5rem;
  }

  @media (min-width: 768px) {

  .button--has-icon {
      font-size: 1.5rem
  }
    }

  .button--primary {
    background-color: var(--white);
    color: var(--smalt);

    //transition: var(--transition-duration--fast);
    -webkit-transition-property: box-shadow, padding-right, -webkit-transform;
    transition-property: box-shadow, padding-right, -webkit-transform;
    transition-property: box-shadow, transform, padding-right;
    transition-property: box-shadow, transform, padding-right, -webkit-transform;
  }

  @media (min-width: 1024px) and (hover: hover) {
      .button--primary:hover {
        box-shadow: 0 6px 12px rgba(255, 255, 255, 0.4);
        -webkit-transform: translateY(-5px);
                transform: translateY(-5px);
      }
    }

  .button--primary:focus {
      box-shadow: 0 6px 12px rgba(255, 255, 255, 0.4);
      -webkit-transform: translateY(-5px);
              transform: translateY(-5px);
    }

  .button--primary.button--disabled {
      -webkit-transform: none;
              transform: none;
      box-shadow: none;
    }

  .button--alt-primary {
    background-color: var(--white);
    color: var(--smalt);
    border-color: var(--smalt);
  }

  @media (min-width: 1024px) and (hover: hover) {
      .button--alt-primary:hover {
        box-shadow: 0 6px 12px rgba(0, 54, 152, 0.4);
        -webkit-transform: translateY(-5px);
                transform: translateY(-5px);
      }
    }

  .button--alt-primary:focus {
      box-shadow: 0 6px 12px rgba(0, 54, 152, 0.4);
      -webkit-transform: translateY(-5px);
              transform: translateY(-5px);
    }

  .button--secondary {
    background-color: var(--smalt);
    color: var(--white);
    border-color: var(--smalt);
  }

  @media (min-width: 1024px) and (hover: hover) {
      .button--secondary:hover {
        box-shadow: 0 6px 12px rgba(0, 54, 152, 0.4);
        -webkit-transform: translateY(-5px);
                transform: translateY(-5px);
      }
    }

  .button--secondary:focus {
      box-shadow: 0 6px 12px rgba(0, 54, 152, 0.4);
      -webkit-transform: translateY(-5px);
              transform: translateY(-5px);
    }

  .button--resolution-blue {
    background-color: var(--resolution-blue);
    color: var(--white);
    border-color: var(--resolution-blue);
  }

  @media (min-width: 1024px) and (hover: hover) {
      .button--resolution-blue:hover {
        box-shadow: 0 6px 12px rgba(0, 38, 128, 0.4);
        -webkit-transform: translateY(-5px);
                transform: translateY(-5px);
      }
    }

  .button--resolution-blue:focus {
      box-shadow: 0 6px 12px rgba(0, 38, 128, 0.4);
      -webkit-transform: translateY(-5px);
              transform: translateY(-5px);
    }

  .button--tertiary {
    background-color: var(--pinkish);
    color: var(--white);
    border-color: var(--pinkish);
  }

  @media (min-width: 1024px) and (hover: hover) {
      .button--tertiary:hover {
        box-shadow: 0 6px 12px rgba(228, 64, 109, 0.4);
        -webkit-transform: translateY(-5px);
                transform: translateY(-5px);
      }
    }

  .button--tertiary:focus {
      box-shadow: 0 6px 12px rgba(228, 64, 109, 0.4);
      -webkit-transform: translateY(-5px);
              transform: translateY(-5px);
    }

  .button--alt-tertiary {
    background-color: var(--rose);
    border-color: var(--rose);
  }

  @media (min-width: 1024px) and (hover: hover) {
      .button--alt-tertiary:hover {
        box-shadow: 0 6px 12px rgb(55, 191, 209, 0.4);
        -webkit-transform: translateY(-5px);
                transform: translateY(-5px);
      }
    }

  .button--alt-tertiary:focus {
      box-shadow: 0 6px 12px rgb(55, 191, 209, 0.4);
      -webkit-transform: translateY(-5px);
              transform: translateY(-5px);
    }

  .button--alt-secondary {
    background-color: var(--scooter);
    border-color: var(--scooter);
  }

  @media (min-width: 1024px) and (hover: hover) {
      .button--alt-secondary:hover {
        box-shadow: 0 6px 12px rgb(55, 191, 209, 0.4);
        -webkit-transform: translateY(-5px);
                transform: translateY(-5px);
      }
    }

  .button--alt-secondary:focus {
      box-shadow: 0 6px 12px rgb(55, 191, 209, 0.4);
      -webkit-transform: translateY(-5px);
              transform: translateY(-5px);
    }

  .button--lifeline-relationships {
    background-color: var(--lifeline-relationships);
    color: var(--white);
  }

  .button--alt-lifeline-relationships {
    background-color: var(--white);
    color: var(--lifeline-relationships);
    border-color: var(--lifeline-relationships);
  }

  .button--lifeline-relationships-no-border {
    background-color: var(--lifeline-relationships);
    color: var(--white);
    border-color: var(--lifeline-relationships);
  }

  .button--alt-lifeline-relationships-no-border {
    background-color: var(--lifeline-relationships);
    color: var(--smalt);
    border-color: var(--smalt);
  }

  .button--lifeline-achievements {
    background-color: var(--lifeline-achievements);
    color: var(--white);
    margin-bottom: 0.5rem;
  }

  .button--alt-lifeline-achievements {
    background-color: var(--white);
    color: var(--lifeline-achievements);
    border-color: var(--lifeline-achievements);
    margin-bottom: 0.5rem;
  }

  .button--lifeline-interests {
    background-color: var(--smalt);
    color: var(--white);
    border-color: var(--smalt);
  }

  .button--alt-lifeline-interests {
    background-color: var(--lifeline-interests);
    color: var(--smalt);
    border-color: var(--smalt);
  }

  .button--crop {
    background-color: var(--smalt);
  }

  .button--google {
    color: var(--white);
    background: var(--google);
    border-color: var(--google);
  }

  .button--google .button__icon {
      height: 1.8rem;
    }

  @media (min-width: 1024px) and (hover: hover) {
      .button--google:hover {
        -webkit-transform: translateY(-5px);
                transform: translateY(-5px);
        box-shadow: 0 6px 12px rgb(223, 75, 56, 0.4);
      }
    }

  .button--google:focus {
      -webkit-transform: translateY(-5px);
              transform: translateY(-5px);
      box-shadow: 0 6px 12px rgb(223, 75, 56, 0.4);
    }

  .button--facebook {
    color: var(--white);
    background: var(--facebook);
    border-color: var(--facebook);
  }

  .button--facebook .button__icon {
      height: 1.8rem;
    }

  @media (min-width: 1024px) and (hover: hover) {
      .button--facebook:hover {
        -webkit-transform: translateY(-5px);
                transform: translateY(-5px);
        box-shadow: 0 6px 12px rgb(76, 98, 163, 0.4);
      }
    }

  .button--facebook:focus {
      -webkit-transform: translateY(-5px);
              transform: translateY(-5px);
      box-shadow: 0 6px 12px rgb(76, 98, 163, 0.4);
    }

  .button--messenger {
    color: var(--white);
    background: var(--messenger);
    border-color: var(--messenger);
  }

  .button--messenger .button__icon {
      height: 2.5rem;
    }

  @media (min-width: 1024px) and (hover: hover) {
      .button--messenger:hover {
        -webkit-transform: translateY(-5px);
                transform: translateY(-5px);
        box-shadow: 0 6px 12px rgb(0, 132, 255, 0.4);
      }
    }

  .button--messenger:focus {
      -webkit-transform: translateY(-5px);
              transform: translateY(-5px);
      box-shadow: 0 6px 12px rgb(0, 132, 255, 0.4);
    }

  .button--disabled {
    opacity: 0.5 !important;
  }

  .button--disabled:hover,
    .button--disabled:focus {
      box-shadow: none !important;
      -webkit-transform: none !important;
              transform: none !important;
    }

  .button--block {
    display: -webkit-box;
    display: flex;
  }

  .button--block-align-right {
    margin-left: auto;
    margin-right: 0;
  }

  .button--size-nano {
    min-width: 9.2rem;
    padding: 1rem 1.4rem;

    font-size: 13px;
    line-height: 1.23em;
  }

  .button--size-small {
    padding: 0.8rem 1.4rem;
  }

  @media (min-width: 768px) {

  .button--size-small {
      padding: 1rem 1.4rem
  }
    }

  .button--size-small.button--has-icon {
      padding: 0.6rem 0.6rem 0.6rem 2.3rem;
    }

  @media (min-width: 768px) {

  .button--size-small.button--has-icon {
        padding: 0.8rem 1rem 0.8rem 2.3rem
    }
      }

  .button--size-small.button--has-icon.button--icon-left {
        padding: 0.6rem 2.3rem 0.6rem 0.6rem;
      }

  @media (min-width: 768px) {

  .button--size-small.button--has-icon.button--icon-left {
          padding: 0.8rem 2.3rem 0.8rem 1rem
      }
        }

  .button--size-medium {
    padding: 1.6rem 2.2rem;
  }

  .button--size-medium.button--big-icon {
      padding: 1.25rem 2.2rem;
    }

  .button--size-large {
    padding: 1.6rem 3rem;
  }

  .button--size-large.button--big-icon {
      padding: 1.25rem 3rem;
    }

@-webkit-keyframes buttonShake {
  10%,
  90% {
    -webkit-transform: translateX(-1px);
            transform: translateX(-1px);
  }

  20%,
  80% {
    -webkit-transform: translateX(2px);
            transform: translateX(2px);
  }

  30%,
  50%,
  70% {
    -webkit-transform: translateX(-4px);
            transform: translateX(-4px);
  }

  40%,
  60% {
    -webkit-transform: translateX(4px);
            transform: translateX(4px);
  }
}

@keyframes buttonShake {
  10%,
  90% {
    -webkit-transform: translateX(-1px);
            transform: translateX(-1px);
  }

  20%,
  80% {
    -webkit-transform: translateX(2px);
            transform: translateX(2px);
  }

  30%,
  50%,
  70% {
    -webkit-transform: translateX(-4px);
            transform: translateX(-4px);
  }

  40%,
  60% {
    -webkit-transform: translateX(4px);
            transform: translateX(4px);
  }
}

@-webkit-keyframes loadingSpin {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.5856, 0.0703, 0.4143, 0.9297);
            animation-timing-function: cubic-bezier(0.5856, 0.0703, 0.4143, 0.9297);
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(1800deg);
            transform: rotate(1800deg);
  }
}

@keyframes loadingSpin {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.5856, 0.0703, 0.4143, 0.9297);
            animation-timing-function: cubic-bezier(0.5856, 0.0703, 0.4143, 0.9297);
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(1800deg);
            transform: rotate(1800deg);
  }
}
