.lifeline-end {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-pack: center;
          justify-content: center;

  padding: 0;
  margin-bottom: 10rem;
  min-height: 100%;
}

  @media (min-width: 768px) {.lifeline-end {
    -webkit-box-pack: start;
            justify-content: flex-start
}
  }

  .lifeline-output-recording .lifeline-end {
    -webkit-box-pack: center;
            justify-content: center;
  }

  .lifeline-end__heading {
    width: 100%;
    padding: 0 5rem;
    margin: 0;

    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;

    -webkit-transform: translate(-50%, -50%);

            transform: translate(-50%, -50%);

    font-size: 2.3rem;
    font-weight: var(--font-semibold);
    text-align: center;

    opacity: 0;
    -webkit-transition: opacity 2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transition: opacity 2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  }

  @media (min-width: 1024px) {

  .lifeline-end__heading {
      font-size: 3rem
  }
    }

  @media (min-width: 1600px) {

  .lifeline-end__heading {
      font-size: 5.7rem
  }
    }

  .lifeline-end__heading--visible {
      opacity: 1;
    }

  .lifeline-end__cover-wrapper {
    opacity: 0;
    -webkit-transition: opacity 2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transition: opacity 2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  }

  .lifeline-end__cover-wrapper--visible {
      opacity: 1;
      -webkit-transition-delay: 2s;
              transition-delay: 2s;
    }

  .lifeline-end__actions {
    text-align: center;
    margin-top: 5rem;
  }

  @media (min-width: 1024px) {

  .lifeline-end__actions {
      margin-top: 0
  }
    }

  .lifeline-output-recording .lifeline-end__actions {
      display: none;
    }

  .lifeline-end__credits {
    display: none;

    margin-top: 5rem;
    margin-bottom: 4rem;
    text-align: center;
  }

  @media (min-width: 1024px) {

  .lifeline-end__credits {
      margin-top: 0
  }
    }

  .lifeline-end__credits p {
      margin: 0;

      font-size: 1.6rem;
      line-height: 1.2em;
      color: var(--resolution-blue);
    }

  @media (min-width: 1600px) {

  .lifeline-end__credits p {
        font-size: 4.5rem
    }
      }

  .lifeline-end__credits p span {
        font-weight: bold;
      }

  .lifeline-output-recording .lifeline-end__credits {
      display: block;
    }
