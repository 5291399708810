.simple-modal {
  text-align: center;
  padding: 4rem;
}

  .simple-modal h2 {
    max-width: 28rem;
    margin: 0 auto;

    font-weight: var(--font-semibold);
  }

  .simple-modal p {
    margin-top: 1.5rem;
  }

  @media (min-width: 1024px) {

  .simple-modal p {
      margin-top: 3rem
  }
    }

  @media (min-width: 1024px) {.simple-modal {
    padding: 6rem 11rem 3rem;
    max-width: 70rem
}
  }

  .simple-modal--buttons {
    margin-top: 4rem;
  }

  @media (min-width: 1024px) {

  .simple-modal--buttons {
      margin-top: 7.5rem;
      margin-bottom: 2.5rem
  }
    }

  .simple-modal .button {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .simple-modal--export-video p {
      font-size: 1.4rem;
      line-height: 2.4rem;
    }

  .simple-modal .loading-shape {
    position: relative;
    left: 0;

    width: 100%;
    width: 22rem;
    height: 22rem;

    -webkit-transform: none;

            transform: none;
    margin: 6.4rem auto 5.8rem auto;
  }

  .simple-modal .loading-shape .svg svg {
      overflow: visible;
    }
