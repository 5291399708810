.lifeline-memories {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: start;
          align-items: flex-start;

  width: 100%;
  max-width: 85rem;
  /* min-height: 100%; */
  margin: 0 auto;
  padding: 6rem 2rem 10rem;

  overflow: hidden;
}

  .lifeline-memories__quotes-wrapper {
    width: 100%;
  }

  .lifeline-memories__quotes {
    position: relative;

    white-space: nowrap;
    font-size: 0;

    -webkit-transition: height 0.7s 0.3s, -webkit-transform 0s 0.4s;

    transition: height 0.7s 0.3s, -webkit-transform 0s 0.4s;

    transition: transform 0s 0.4s, height 0.7s 0.3s;

    transition: transform 0s 0.4s, height 0.7s 0.3s, -webkit-transform 0s 0.4s;

    -webkit-user-select: none;

       -moz-user-select: none;

        -ms-user-select: none;

            user-select: none;
  }

  .lifeline-memories__quotes-symbol {
    display: block;
    width: 100%;

    text-align: left;
    font-family: var(--font-heading);
    font-size: 6rem;
    margin-bottom: 3.5rem;

    color: currentColor;
    line-height: 0;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

  @media (min-width: 768px) {

  .lifeline-memories__quotes-symbol {
      display: none
  }
    }

  .lifeline-memories__quotes-dots {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: center;
            justify-content: center;
  }

  .lifeline-memories__quotes-dot {
    display: block;
    width: 0.7rem;
    height: 0.7rem;
    background-color: currentColor;
    border-radius: 100%;
  }

  .lifeline-memories__quotes-dot-wrapper {
    display: inline-block;

    padding: 4rem 2rem;
    padding-left: 0;

    opacity: 0.3;
    -webkit-transition: opacity var(--transition-duration--fast);
    transition: opacity var(--transition-duration--fast);

    cursor: pointer;
  }

  .lifeline-memories__quotes-dot-wrapper.active {
      opacity: 1;
    }

  .lifeline-memories__quote {
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;

    width: 100%;

    white-space: normal;
    font-size: 1.6rem;
    text-align: left;

    -webkit-transition: opacity var(--transition-duration--normal);

    transition: opacity var(--transition-duration--normal);
    opacity: 0;
  }

  @media (min-width: 768px) {

  .lifeline-memories__quote {
      padding: 0 2rem
  }
    }

  .lifeline-memories__quote.active {
      opacity: 1;
      -webkit-transition: opacity var(--transition-duration--normal) 0.4s;
      transition: opacity var(--transition-duration--normal) 0.4s;
    }

  .lifeline-memories__quote-author {
    font-size: 1.6rem;
  }

  @media (min-width: 768px) {

  .lifeline-memories__quote-author {
      font-size: 2rem
  }
    }

  @media (min-width: 1600px) {

  .lifeline-memories__quote-author {
      font-size: 2.4rem
  }
    }

  .lifeline-memories__quote-text-wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    -webkit-box-align: start;
            align-items: flex-start;
    -webkit-box-pack: center;
            justify-content: center;

    font-family: var(--font-heading);
    color: currentColor;
  }

  @media (min-width: 768px) {
      .lifeline-memories__quote-text-wrapper::before,
      .lifeline-memories__quote-text-wrapper::after {
        display: block;
        font-weight: var(--font-normal);
        font-size: 9rem;
        line-height: 1;
        -webkit-transform: translateY(-11px);
                transform: translateY(-11px);
      }
      .lifeline-memories__quote-text-wrapper::before {
        content: '“';

        margin-right: 3rem;
      }
      .lifeline-memories__quote-text-wrapper::after {
        content: '”';

        margin-left: 3rem;
      }
    }

  .lifeline-memories__quote-text {
    margin: 0 0 1rem;

    font-size: 2.2rem;
    font-weight: var(--font-semibold);
    line-height: 3rem;
  }

  @media (min-width: 768px) {

  .lifeline-memories__quote-text {
      margin: 0;

      font-size: 3rem;
      font-weight: var(--font-medium);

      line-height: 4rem
  }
    }
