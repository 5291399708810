.lifeline-family {
  position: relative;
  height: 100%;

  overflow: hidden;

  --gutter: calc(100vw / (2 * 24 + 23));
  --column: calc(2 * var(--gutter));

  --gutter-row: calc(100vh / (2 * 20 + 19));
  --row: calc(2 * var(--gutter-row));

  pointer-events: none;
}

  /*
  &--wrapper {
    position: relative;
  }
  */

  .lifeline-family .lifeline-family--friend_quote {
    display: none;
  }

  @media (min-width: 768px) {

  .lifeline-family .lifeline-family--friend_quote {
      display: block
  }
    }

  .lifeline-family__waypoint {
    height: 8px;
    width: 100%;

    position: absolute;
    left: 0;

    pointer-events: none;
    opacity: 0;
  }

  /* background: yellowgreen; */

  @media (max-width: 767px) {

  .lifeline-family__waypoint {
      display: none
  }
    }

  .lifeline-family--video-container p {
      margin-left: 1rem;
    }

  .lifeline-family--cover {
    padding: 0 3rem;
  }

  @media (min-width: 768px) {

  .lifeline-family--cover {
      width: calc(var(--column) * 11 + var(--gutter) * 11);
      padding-left: calc(var(--column) * 3 + var(--gutter) * 3);
      padding-right: 0;
      line-height: 15rem;
      position: relative;
      z-index: 1
  }
    }

  .lifeline-family--cover h2 {
      font-size: 8rem;
      line-height: 8rem;
      color: #ff004d;
      width: 100%;
      margin-top: calc(3 * var(--row) + 2 * var(--gutter-row));
      margin-bottom: 3rem;
    }

  @media (min-width: 768px) {

  .lifeline-family--cover h2 {
        font-size: 17rem;
        line-height: 15rem;
        width: 88rem;
        margin-bottom: calc(2 * var(--row) + var(--gutter-row))
    }
      }

  .lifeline-family--cover p {
      font-size: 1.6rem;
      line-height: 2.4rem;
      width: 100%;
    }

  @media (min-width: 768px) {

  .lifeline-family--cover p {
        font-size: 2rem;
        line-height: 2.9rem;
        width: calc(var(--column) * 7 + var(--gutter) * 6)
    }
      }

  @media (min-width: 1600px) {

  .lifeline-family--cover p {
        font-size: 3.8rem;
        line-height: 1.26em
    }
      }

  .lifeline-family--friend_quote-container {
    margin-bottom: 10vh;
  }

  @media (min-width: 768px) {

  .lifeline-family--friend_quote-container {
      -webkit-box-flex: 1;
              flex: 1 0 auto;
      display: -webkit-box;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
              flex-direction: row;
      -webkit-box-align: center;
              align-items: center;
      margin-bottom: unset
  }
      .lifeline-family--friend_quote-container .parallax-container {
        position: absolute;
      }

      .lifeline-family--friend_quote-container .lifeline-family--friend_quote {
        font-family: var(--font-heading);
        font-size: 9rem;
        line-height: 14rem;
      }

        .lifeline-family--friend_quote-container .lifeline-family--friend_quote span {
          display: inline-block;
          /*
          transform:
            translate3d(15rem,-8rem,0)
            rotate(-40deg)
            skewX(70deg)
            scale(1.5);
          text-shadow: 0 0 20px black;
          */
          opacity: 0;
        }

          .lifeline-family--friend_quote-container .lifeline-family--friend_quote span.anim-active {
            /* animation: smoky 0.4s 0.4s reverse both; */
            -webkit-animation: textblur 0.6s 0s both;
                    animation: textblur 0.6s 0s both;
          }
          .lifeline-family--friend_quote-container .lifeline-family--friend_quote span.anim-inactive {
            /* animation: smoky 0.4s 0.4s reverse both; */
            -webkit-animation: textblurRemove 1s 0s both;
                    animation: textblurRemove 1s 0s both;
          }
          .lifeline-family--friend_quote-container .lifeline-family--friend_quote span:nth-child(even).anim-active {
            /* animation-name: smoky-mirror; */
          }
    }

  @media (min-width: 768px) {.lifeline-family {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    position: fixed;
    top: 0;
    left: 0;
    right: 0

    /*
    &--wrapper {
      display: flex;
      flex-direction: row;
      flex: 0 0 auto;
    }*/
}

    .lifeline-family--scroller {
      width: 100%;
      height: 5000px;
    }
      .lifeline-family__spacer--10vw {
        width: 10vw;
        flex-basis: 10vw;
        flex-shrink: 0;
      }
      .lifeline-family__spacer--25vw {
        width: 25vw;
        flex-basis: 25vw;
        flex-shrink: 0;
      }
      .lifeline-family__spacer--50vw {
        width: 50vw;
        flex-basis: 50vw;
        flex-shrink: 0;
      }
      .lifeline-family__spacer--75vw {
        width: 75vw;
        flex-basis: 75vw;
        flex-shrink: 0;
      }

    .lifeline-family .lifeline-memories {
      margin-right: 12rem;
      width: 59rem;
      -webkit-box-flex: 0;
              flex: 0 0 59rem;
      padding: 0;
      align-self: center;
    }

      .lifeline-family .lifeline-memories .lifeline-memories__quote {
        padding: 0;
      }

      .lifeline-family .lifeline-memories .lifeline-memories__quote-text-wrapper {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
                flex-direction: column;
      }

        .lifeline-family .lifeline-memories .lifeline-memories__quote-text-wrapper::before {
          /* color: black; */
          font-family: var(--font-paragraph);
          font-size: 7rem;
        }
        .lifeline-family .lifeline-memories .lifeline-memories__quote-text-wrapper::after {
          display: none;
        }

      .lifeline-family .lifeline-memories .lifeline-memories__quote-text {
        font-family: var(--font-paragraph);
        font-size: 2rem;
        line-height: 2.9rem;
        /* color: black; */
        font-weight: normal;
        text-align: left;
      }

        @media (min-width: 1600px) {

      .lifeline-family .lifeline-memories .lifeline-memories__quote-text {
          font-size: 3rem;
          line-height: 3.6rem
      }
        }

      .lifeline-family .lifeline-memories .lifeline-memories__quote-author {
        text-align: left;
        font-size: 1.5rem;
        margin-top: 5rem;
      }

        @media (min-width: 1600px) {

      .lifeline-family .lifeline-memories .lifeline-memories__quote-author {
          font-size: 2.4rem;
          line-height: 1.21em
      }
        }

      .lifeline-family .lifeline-memories .lifeline-memories__quotes-dots {
        -webkit-box-pack: start;
                justify-content: flex-start;
      }
        .lifeline-family .lifeline-memories .lifeline-memories__quotes-dots > span > span {
          /* margin: 5rem 2rem 0 0; */
          /* background: black; */
          width: 0.8rem;
          height: 0.8rem;
        }

    .lifeline-family--video-container {
      -webkit-box-flex: 0;
              flex: 0 0 108rem;
      display: -webkit-box;
      display: flex;
      align-self: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
              flex-direction: column;
      padding: 0 15rem;
      -webkit-box-pack: center;
              justify-content: center;
    }

      .lifeline-family--video-container p {
        margin-top: 1rem;
        font-size: 1.5rem;
        line-height: 1.8rem;
        margin-left: 0;
      }
  }

/* This positions memories slider correctly in case
   when there are fewer than 4 friendhip connections
   on Family page*/
.lifeline-family--friend_quote-container + .lifeline-memories {
  margin-top: 15rem;
}

@media (min-width: 768px) {
  .lifeline-family--friend_quote-container + .lifeline-memories {
    margin-top: 0;
    margin-left: 50rem;
  }
}

@-webkit-keyframes textblur {
  from {
    color: rgb(0, 0, 0, 0);
    opacity: 0;
    text-shadow: 0 0 50px black;
  }
  80% {
    color: rgb(0, 0, 0, 0);
  }
  to {
    color: rgb(0, 0, 0, 1);
    opacity: 1;
    text-shadow: 0 0 0 black;
  }
}

@keyframes textblur {
  from {
    color: rgb(0, 0, 0, 0);
    opacity: 0;
    text-shadow: 0 0 50px black;
  }
  80% {
    color: rgb(0, 0, 0, 0);
  }
  to {
    color: rgb(0, 0, 0, 1);
    opacity: 1;
    text-shadow: 0 0 0 black;
  }
}

@-webkit-keyframes textblurRemove {
  from {
    color: rgb(0, 0, 0, 1);
    opacity: 1;
    text-shadow: 0 0 0 black;
  }
  20% {
    color: rgb(0, 0, 0, 0);
  }
  to {
    color: rgb(0, 0, 0, 0);
    opacity: 0;
    text-shadow: 0 0 50px black;
    -webkit-transform: translate3d(2rem, -5rem, 0);
            transform: translate3d(2rem, -5rem, 0);
  }
}

@keyframes textblurRemove {
  from {
    color: rgb(0, 0, 0, 1);
    opacity: 1;
    text-shadow: 0 0 0 black;
  }
  20% {
    color: rgb(0, 0, 0, 0);
  }
  to {
    color: rgb(0, 0, 0, 0);
    opacity: 0;
    text-shadow: 0 0 50px black;
    -webkit-transform: translate3d(2rem, -5rem, 0);
            transform: translate3d(2rem, -5rem, 0);
  }
}

@-webkit-keyframes smoky {
  from {
    text-shadow: 0 0 0 black;
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
  60% {
    text-shadow: 0 0 40px black;
    opacity: 0;
  }
  to {
    -webkit-transform: translate3d(15rem, -8rem, 0) rotate(-40deg) skewX(70deg)
      scale(1.5);
            transform: translate3d(15rem, -8rem, 0) rotate(-40deg) skewX(70deg)
      scale(1.5);
    text-shadow: 0 0 20px black;
    opacity: 0;
  }
}

@keyframes smoky {
  from {
    text-shadow: 0 0 0 black;
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
  60% {
    text-shadow: 0 0 40px black;
    opacity: 0;
  }
  to {
    -webkit-transform: translate3d(15rem, -8rem, 0) rotate(-40deg) skewX(70deg)
      scale(1.5);
            transform: translate3d(15rem, -8rem, 0) rotate(-40deg) skewX(70deg)
      scale(1.5);
    text-shadow: 0 0 20px black;
    opacity: 0;
  }
}

@-webkit-keyframes smoky-mirror {
  from {
    text-shadow: 0 0 0 black;
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
  60% {
    text-shadow: 0 0 40px black;
    opacity: 0;
  }
  to {
    -webkit-transform: translate3d(18rem, -8rem, 0) rotate(-40deg) skewX(-70deg)
      scale(2);
            transform: translate3d(18rem, -8rem, 0) rotate(-40deg) skewX(-70deg)
      scale(2);
    text-shadow: 0 0 20px black;
    opacity: 0;
  }
}

@keyframes smoky-mirror {
  from {
    text-shadow: 0 0 0 black;
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
  60% {
    text-shadow: 0 0 40px black;
    opacity: 0;
  }
  to {
    -webkit-transform: translate3d(18rem, -8rem, 0) rotate(-40deg) skewX(-70deg)
      scale(2);
            transform: translate3d(18rem, -8rem, 0) rotate(-40deg) skewX(-70deg)
      scale(2);
    text-shadow: 0 0 20px black;
    opacity: 0;
  }
}
