.select {
  position: relative;
}

  .select .input__field {
    padding-right: 2rem;
  }

  .select--arrow {
    position: absolute;
    top: 3.6rem;
    right: 0;
    width: 1.9rem;
    height: 0.95rem;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    box-sizing: content-box;
    padding: 1rem 0 1rem 1rem;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }

  @media (min-width: 768px) {

  .select--arrow {
      top: 3.9rem
  }
    }

  @media (min-width: 1441px) {

  .select--arrow {
      top: calc(50% + 0.75rem)
  }
    }

  .select--arrow svg {
      display: block;
      width: 100%;
      height: 100%;
    }

  .select--smalt .select--arrow svg path {
      stroke: var(--smalt);
    }

  .select--black .select--arrow svg path {
      stroke: var(--black);
    }

  .select--autocomplete-box {
    position: absolute;
    top: 100%;

    display: block;

    width: 100%;
    max-height: 15rem;

    overflow: auto;

    color: var(--smalt);
    background: var(--white);
    text-align: left;
    z-index: 30;

    opacity: 1;
  }

  .select--black .select--autocomplete-box {
      box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.15);
    }

  .select--autocomplete-box li {
      margin: 0;
      padding: 0.8rem 1.2rem;
      cursor: pointer;
    }

  .select--autocomplete-box li:hover {
        background: var(--san-marino);
      }

  .select--autocomplete-box li.highlighted {
        background: var(--san-marino);
        color: var(--white);
      }

  .select--autocomplete-box--visible {
      opacity: 1;
    }

  .select--autocomplete-box--enter {
      opacity: 0;
    }

  .select--autocomplete-box--enter-active {
      opacity: 1;
      -webkit-transition: opacity var(--transition-duration--instant);
      transition: opacity var(--transition-duration--instant);
    }

  .select--autocomplete-box--exit {
      opacity: 0;
    }

  .select--autocomplete-box--exit-done {
      opacity: 0;
      -webkit-transition: opacity var(--transition-duration--instant);
      transition: opacity var(--transition-duration--instant);
    }
