.lifeline-cover {
  height: 100vh;
  padding-top: 5.5rem;

  position: relative;
}

  @media (min-width: 1024px) {.lifeline-cover {
    padding-top: 6.4rem
}
  }

  .lifeline-cover > * {
    opacity: 0;
  }

  .lifeline-cover::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: var(--resolution-blue);
  }

  .lifeline-cover--end {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
            flex-direction: column-reverse;
    padding-top: 0;
    height: 60vh;
    -webkit-box-pack: center;
            justify-content: center;
  }

  .lifeline-cover--end .lifeline-cover__titles {
      margin-top: 0;
    }

  .lifeline-cover--end .lifeline-cover__titles h2 {
        margin-bottom: -2rem;
      }

  @media (min-width: 1024px) {

  .lifeline-cover--end {
      margin-top: 0;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
              flex-direction: column;
      height: calc(100vh - 10.2rem);
      padding-top: 6.4rem
  }
        .lifeline-cover--end .lifeline-cover__titles h2 {
          margin-bottom: 0;
        }
    }

  .lifeline-cover.enter > * {
      opacity: 1;
      -webkit-transition: opacity var(--transition-duration--slow)
          var(--transition-ease--out-quintic) 1s,
        -webkit-transform 1.4s var(--transition-ease--out-quintic);
      transition: opacity var(--transition-duration--slow)
          var(--transition-ease--out-quintic) 1s,
        -webkit-transform 1.4s var(--transition-ease--out-quintic);
      transition: opacity var(--transition-duration--slow)
          var(--transition-ease--out-quintic) 1s,
        transform 1.4s var(--transition-ease--out-quintic);
      transition: opacity var(--transition-duration--slow)
          var(--transition-ease--out-quintic) 1s,
        transform 1.4s var(--transition-ease--out-quintic),
        -webkit-transform 1.4s var(--transition-ease--out-quintic);
    }

  .lifeline-cover.enter > canvas {
      z-index: -1;
    }

  @media (min-width: 1024px) {

  .lifeline-cover.enter > canvas {
        z-index: 0
    }
      }

  .lifeline-cover.enter::before {
      -webkit-transition: -webkit-transform 1s ease-in-out;
      transition: -webkit-transform 1s ease-in-out;
      transition: transform 1s ease-in-out;
      transition: transform 1s ease-in-out, -webkit-transform 1s ease-in-out;
      -webkit-transform: scale(1, 0);
              transform: scale(1, 0);
      -webkit-transform-origin: top;
              transform-origin: top;
    }

  @media (min-width: 1024px) {

  .lifeline-cover.enter .lifeline-cover__titles {
        -webkit-transition: opacity 1s ease-in-out 1.5s, -webkit-transform 1s ease-in-out 1.5s;
        transition: opacity 1s ease-in-out 1.5s, -webkit-transform 1s ease-in-out 1.5s;
        transition: opacity 1s ease-in-out 1.5s, transform 1s ease-in-out 1.5s;
        transition: opacity 1s ease-in-out 1.5s, transform 1s ease-in-out 1.5s, -webkit-transform 1s ease-in-out 1.5s;

        opacity: 1
        /*transform: translateY(-50%);*/
    }

        .lifeline-cover.enter .lifeline-cover__titles > *:nth-child(1) {
          -webkit-transition: -webkit-transform 1s ease-in-out 1.5s;
          transition: -webkit-transform 1s ease-in-out 1.5s;
          transition: transform 1s ease-in-out 1.5s;
          transition: transform 1s ease-in-out 1.5s, -webkit-transform 1s ease-in-out 1.5s;
          -webkit-transform: translateY(0%);
                  transform: translateY(0%);
        }
        .lifeline-cover.enter .lifeline-cover__titles > *:nth-child(2) {
          -webkit-transition: -webkit-transform 1s ease-in-out 1.5s;
          transition: -webkit-transform 1s ease-in-out 1.5s;
          transition: transform 1s ease-in-out 1.5s;
          transition: transform 1s ease-in-out 1.5s, -webkit-transform 1s ease-in-out 1.5s;
          -webkit-transform: translateY(0%);
                  transform: translateY(0%);
        }
        .lifeline-cover.enter .lifeline-cover__titles > *:nth-child(3) {
          -webkit-transition: -webkit-transform 1s ease-in-out 1.5s;
          transition: -webkit-transform 1s ease-in-out 1.5s;
          transition: transform 1s ease-in-out 1.5s;
          transition: transform 1s ease-in-out 1.5s, -webkit-transform 1s ease-in-out 1.5s;
          -webkit-transform: translateY(0%);
                  transform: translateY(0%);
        }
      }

  .lifeline-cover__titles {
    margin-top: calc(50vh - 8rem);
  }

  @media (min-width: 1024px) {

  .lifeline-cover__titles {
      position: absolute;
      top: 50%;
      width: 100%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      display: -webkit-box;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
              flex-direction: column;
      -webkit-box-pack: center;
              justify-content: center;
      opacity: 0
  }

      .lifeline-cover__titles > *:nth-child(1) {
        -webkit-transform: translateY(50%);
                transform: translateY(50%);
      }
      .lifeline-cover__titles > *:nth-child(2) {
        -webkit-transform: translateY(75%);
                transform: translateY(75%);
      }
      .lifeline-cover__titles > *:nth-child(3) {
        -webkit-transform: translateY(100%);
                transform: translateY(100%);
      }
    }

  @media (min-width: 1024px) {

  .lifeline-cover__titles {
      margin-top: 0
  }
    }

  .lifeline-cover__titles__cta-wrapper {
      display: block;
      align-self: center;

      margin-top: 5.7rem;
      margin-bottom: -11rem;

      text-align: center;
    }

  @media (min-width: 768px) {
      }

  @media (min-width: 1024px) {

  .lifeline-cover__titles__cta-wrapper {
        display: inline-block
    }
      }

  @media (min-width: 1600px) {

  .lifeline-cover__titles__cta {
        font-size: 2.1rem;
        padding: 1.07em 2em
    }
      }

  .lifeline-cover__titles__cta span span {
        text-transform: capitalize;
      }

  .lifeline-cover h1 {
    width: 66.66%;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;

    font-size: 4rem;
    line-height: 1.225em;
    text-align: center;
    text-transform: capitalize;
    white-space: nowrap;
  }

  @media (min-width: 1024px) {

  .lifeline-cover h1 {
      font-size: 6rem
  }
    }

  @media (min-width: 1024px) {

  .lifeline-cover h1 {
      margin-top: 0
  }
    }

  .lifeline-cover h1 span {
      opacity: 0;
    }

  .lifeline-cover h2 {
    font-family: var(--font-paragraph);
    font-size: 1.6rem;
    line-height: 6.1rem;
    text-align: center;
  }

  @media (min-width: 1024px) {

  .lifeline-cover h2 {
      font-size: 2rem
  }
    }

  @media (min-width: 1600px) {

  .lifeline-cover h2 {
      font-size: 4.8rem
  }
    }

  .lifeline-cover__service {
    position: absolute;
    bottom: 2.2rem;
    right: 2.2rem;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    -webkit-box-align: center;
            align-items: center;
  }

  .lifeline-cover__service.militaryExit {
      -webkit-transform: translateY(-200%);
              transform: translateY(-200%);
    }

  @media (min-width: 1024px) {

  .lifeline-cover__service {
      bottom: 4.3rem;
      right: 4.3rem
  }
    }

  .lifeline-cover__service p {
      font-family: var(--font-paragraph);
      font-size: 1.4rem;
      line-height: 1.7rem;
      color: var(--resolution-blue);
    }

  .lifeline-cover__service .svg {
      display: inline-block;
      height: 4.7rem;
      width: 4.7rem;
      margin-left: 0.7rem;
    }

  @media (min-width: 1024px) {

  .lifeline-cover__service .svg {
        height: 5.5rem;
        width: 5.5rem
    }
      }

  .lifeline-cover canvas {
    pointer-events: none;
  }
