.lifeline-birthplace {
  position: relative;

  background: transparent;
  color: var(--smalt);
  height: 100%;
}

  @media (min-width: 768px) {.lifeline-birthplace {
    width: 100%
}
  }

  @media (max-width: 767px) {

  .lifeline-birthplace--places {
      height: 100% !important
  }
    }

  .lifeline-birthplace .map-pin--birth-place {
    cursor: unset;
  }

  .lifeline-birthplace__beacon {
    display: block;

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
  }

  @media (min-width: 768px) {

  .lifeline-birthplace__beacon {
      top: -35rem
  }
    }

  .lifeline-birthplace__place-info {
    position: absolute;
    bottom: 6rem;
    left: 0;

    width: 100%;

    text-align: center;

    opacity: 0;
    -webkit-transform: translateY(4rem);
            transform: translateY(4rem);

    -webkit-transition: opacity 1s var(--transition-ease--out-quintic),
      -webkit-transform 1s var(--transition-ease--out-quintic);

    transition: opacity 1s var(--transition-ease--out-quintic),
      -webkit-transform 1s var(--transition-ease--out-quintic);

    transition: opacity 1s var(--transition-ease--out-quintic),
      transform 1s var(--transition-ease--out-quintic);

    transition: opacity 1s var(--transition-ease--out-quintic),
      transform 1s var(--transition-ease--out-quintic),
      -webkit-transform 1s var(--transition-ease--out-quintic);
  }

  @media (min-width: 1600px) {

  .lifeline-birthplace__place-info {
      bottom: 12rem
  }
    }

  .lifeline-birthplace__place-info h2 {
      font-size: 2.2rem;
      font-weight: var(--font-semibold);
    }

  @media (min-width: 1600px) {

  .lifeline-birthplace__place-info h2 {
        font-size: 4.8rem;
        line-height: 1.23em
    }
      }

  .lifeline-birthplace__place-info p {
      font-size: 1.6rem;
      margin: 1.7rem 0 0;
    }

  @media (min-width: 1600px) {

  .lifeline-birthplace__place-info p {
        font-size: 3.1rem;
        line-height: 1.19em
    }
      }

  .lifeline-birthplace__place-info--enter {
      -webkit-transition: opacity 1s var(--transition-ease--out-quintic) 0.6s,
        -webkit-transform 1s var(--transition-ease--out-quintic) 0.6s;
      transition: opacity 1s var(--transition-ease--out-quintic) 0.6s,
        -webkit-transform 1s var(--transition-ease--out-quintic) 0.6s;
      transition: opacity 1s var(--transition-ease--out-quintic) 0.6s,
        transform 1s var(--transition-ease--out-quintic) 0.6s;
      transition: opacity 1s var(--transition-ease--out-quintic) 0.6s,
        transform 1s var(--transition-ease--out-quintic) 0.6s,
        -webkit-transform 1s var(--transition-ease--out-quintic) 0.6s;
      -webkit-transition-delay: 0.5s;
              transition-delay: 0.5s;
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

  .lifeline-birthplace__place-anim {
    -webkit-transition: var(--transition-duration--slow)
      var(--transition-ease--out-quintic);
    transition: var(--transition-duration--slow)
      var(--transition-ease--out-quintic);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform;
  }

  .lifeline-birthplace__place-anim-enter {
      opacity: 0;
      -webkit-transform: translateY(4rem);
              transform: translateY(4rem);
    }

  .lifeline-birthplace__place-anim-enter-active {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

  .lifeline-birthplace__place-anim-exit {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

  .lifeline-birthplace__place-anim-exit-active {
      opacity: 0;
      -webkit-transform: translateY(4rem);
              transform: translateY(4rem);
    }

  .lifeline-birthplace__container {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  @media (max-width: 767px) {

  .lifeline-birthplace__container {
      height: 100% !important
  }
    }

  .lifeline-birthplace__overlay {
    width: 100%;
    height: 100%;

    background: var(--scooter);

    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;

    opacity: 0;

    pointer-events: none;
  }

  .lifeline-birthplace__place-waypoint {
    height: 8px;
    width: 100%;

    position: absolute;
    top: 75%;
    left: 0;

    pointer-events: none;
    opacity: 0;
  }

  /* background: magenta; */

  @media (max-width: 767px) {

  .lifeline-birthplace__place-waypoint {
      display: none
  }
    }

  .lifeline-birthplace__intro,
  .lifeline-birthplace__places {
    max-width: 41rem;
    padding: 4rem 2rem 0;
    margin: 1rem 0 0;

    position: absolute;
    top: 0;
    left: 0;

    font-size: 2.2rem;
    font-family: var(--font-heading);
    font-weight: var(--font-semibold);
    line-height: 2.7rem;

    -webkit-transform: translateY(4rem);

            transform: translateY(4rem);

    opacity: 0;
    pointer-events: none;

    z-index: 10;
  }

  @media (min-width: 768px) {

  .lifeline-birthplace__intro,
  .lifeline-birthplace__places {
      margin: 0 0 0 10rem;
      padding: 0;

      top: 15.82vh;
      left: auto;

      font-size: 3rem;
      line-height: 4rem
  }
    }

  @media (min-width: 1600px) {

  .lifeline-birthplace__intro,
  .lifeline-birthplace__places {
      max-width: 72rem;

      font-size: 5.7rem;
      line-height: 1.33em
  }
    }

  .lifeline-birthplace__intro {
    margin-top: 3rem;
  }

  @media (min-width: 768px) {

  .lifeline-birthplace__intro {
      margin-top: 1rem
  }
    }

  .lifeline-birthplace .lifeline-map {
    -webkit-transition: -webkit-clip-path 1.2s cubic-bezier(0.86, 0, 0.07, 1);
    transition: -webkit-clip-path 1.2s cubic-bezier(0.86, 0, 0.07, 1);
    transition: clip-path 1.2s cubic-bezier(0.86, 0, 0.07, 1);
    transition: clip-path 1.2s cubic-bezier(0.86, 0, 0.07, 1), -webkit-clip-path 1.2s cubic-bezier(0.86, 0, 0.07, 1);

    -webkit-clip-path: circle(0);

            clip-path: circle(0);
  }

  .lifeline-birthplace--enter .lifeline-map {
      -webkit-clip-path: circle(100%);
              clip-path: circle(100%);
    }

  .lifeline-birthplace p.lifeline-birthplace__intro--enter,
  .lifeline-birthplace p.lifeline-birthplace__places--enter {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: opacity 1s 0.6s ease-in-out, -webkit-transform 1s 0.6s ease-in-out;
    transition: opacity 1s 0.6s ease-in-out, -webkit-transform 1s 0.6s ease-in-out;
    transition: opacity 1s 0.6s ease-in-out, transform 1s 0.6s ease-in-out;
    transition: opacity 1s 0.6s ease-in-out, transform 1s 0.6s ease-in-out, -webkit-transform 1s 0.6s ease-in-out;
  }

  .lifeline-birthplace p.lifeline-birthplace__intro--exit,
  .lifeline-birthplace p.lifeline-birthplace__places--exit {
    opacity: 0;
    -webkit-transform: translateY(4rem);
            transform: translateY(4rem);
    -webkit-transition: opacity 1s ease-in-out, -webkit-transform 1s ease-in-out;
    transition: opacity 1s ease-in-out, -webkit-transform 1s ease-in-out;
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
    transition: opacity 1s ease-in-out, transform 1s ease-in-out, -webkit-transform 1s ease-in-out;
  }

  .lifeline-birthplace p.lifeline-birthplace__places--enter {
    -webkit-transition-delay: 0.5s;
            transition-delay: 0.5s;
  }
