.loading-screen {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center;

  width: 100vw;
  min-height: var(--page-height);

  color: var(--white);
}

  .loading-screen.fullscreen-loading-screen {
    width: 100vw;
    height: 100vh;

    min-height: 0;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
  }

  .loading-screen,
  .loading-screen--remembering {
    background: var(--lifeline-remembering);
  }

  .loading-screen--relationships {
    background: var(--lifeline-relationships);
  }

  .loading-screen--achievements {
    background: var(--lifeline-achievements);
  }

  .loading-screen--interests {
    background: var(--lifeline-interests);
  }

  .loading-screen--output {
    min-height: 100vh;
    background: var(--resolution-blue);
  }

  .loading-screen--appear,
  .loading-screen--enter {
    opacity: 0;
  }

  .loading-screen--appear-active, .loading-screen--enter-active {
      -webkit-transition: opacity 1s var(--transition-ease--out-quintic);
      transition: opacity 1s var(--transition-ease--out-quintic);
      opacity: 1;
    }

  .loading-screen--exit {
    opacity: 1;
  }

  .loading-screen--exit-active {
      -webkit-transition: opacity 1s var(--transition-ease--out-quintic);
      transition: opacity 1s var(--transition-ease--out-quintic);
      opacity: 0;
    }

  .loading-screen__content {
    position: relative;
  }

  .loading-screen__shape {
    width: 6rems;
    height: 6rems;

    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%, -100%) translateY(-1rem);
            transform: translate(-50%, -100%) translateY(-1rem);
  }

  .loading-screen__shape .loading-shape {
      width: 100%;
      height: 100%;

      position: static;
      -webkit-transform: none;
              transform: none;
    }

  .loading-screen__shape .loading-shape span {
        display: block;
      }

  .loading-screen__shape .loading-shape span svg {
          display: block;

          width: 100%;
          height: 100%;
        }

  .loading-screen__title,
  .loading-screen__message {
    line-height: 1.2em;
    text-align: center;
  }

  .loading-screen__title {
    font-size: 2.4rem;
  }

  @media (min-width: 768px) {

  .loading-screen__title {
      font-size: 2rem
  }
    }

  .loading-screen__title__error {
      font-family: var(--font-heading);
      font-size: 3.2rem;
    }

  .loading-screen__message {
    margin-top: 2.4rem;
    font-size: 1.6rem;
  }

  @media (min-width: 768px) {

  .loading-screen__message {
      margin-top: 4.2rem;
      font-size: 2rem
  }
    }

  .loading-screen__button {
    width: 100%;
    margin-top: 8rem;

    text-align: center;
  }

  @media (min-width: 768px) {

  .loading-screen__button {
      margin-top: 0;
      margin-bottom: 8rem;

      position: absolute;
      bottom: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%)
  }
    }
