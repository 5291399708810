.music-player {
  display: none;
  height: 5.6rem;

  position: fixed;
  bottom: 5.1rem;
  left: 5.9rem;
  right: 5.9rem;
  z-index: 6;

  pointer-events: none;

  -webkit-transition: opacity 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);

  transition: opacity 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}

  @media (min-width: 768px) {.music-player {
    display: block
}
  }

  .music-player--disabled {
    opacity: 0;
  }

  .music-player--open {
  }

  .music-player--playing {
  }

  .lifeline-output-recording .music-player {
    opacity: 0 !important;
    -webkit-transition: none !important;
    transition: none !important;
  }

  .music-player__outer-toggle {
    width: 5.6rem;
    height: 5.6rem;

    border: 1px solid var(--lifeline-output--foreground-color);
    border-radius: 50%;
    background-color: var(--lifeline-output--background-color);

    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;

    pointer-events: auto;

    -webkit-transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);

    transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transition-property: border-color, background-color;
    transition-property: border-color, background-color;
  }

  .music-player--disabled .music-player__outer-toggle {
      pointer-events: none;
    }

  .music-player__outer-toggle .svg {
      display: block;
    }

  .music-player__outer-toggle .svg svg {
        display: block;
        margin: auto;

        fill: none;
      }

  .music-player__outer-toggle .svg svg .s {
          -webkit-transition: stroke 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
          transition: stroke 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);

          stroke: var(--lifeline-output--foreground-color);
        }

  .music-player__outer-toggle .svg svg .f {
          -webkit-transition: fill 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
          transition: fill 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);

          fill: var(--lifeline-output--foreground-color);
        }

  .music-player__inner {
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    right: 2.8rem;
    z-index: 1;

    overflow: hidden;
  }

  .music-player__player {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    -webkit-box-align: center;
            align-items: center;

    width: 100%;
    max-width: 41.4rem;
    height: 5.6rem;
    padding: 0.9rem 3.7rem 0.9rem 0.9rem;

    border-radius: 2.8rem 0 0 2.8rem;
    background-color: var(--lifeline-output--foreground-color);
    color: var(--lifeline-output--background-color);

    position: absolute;
    top: 0;
    right: 0;

    -webkit-transition: color 0.6s cubic-bezier(0.215, 0.61, 0.355, 1),
      background-color 0.6s cubic-bezier(0.215, 0.61, 0.355, 1),
      -webkit-transform var(--transition-duration--normal)
        var(--transition-ease--out-quintic);

    transition: color 0.6s cubic-bezier(0.215, 0.61, 0.355, 1),
      background-color 0.6s cubic-bezier(0.215, 0.61, 0.355, 1),
      -webkit-transform var(--transition-duration--normal)
        var(--transition-ease--out-quintic);

    transition: transform var(--transition-duration--normal)
        var(--transition-ease--out-quintic),
      color 0.6s cubic-bezier(0.215, 0.61, 0.355, 1),
      background-color 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);

    transition: transform var(--transition-duration--normal)
        var(--transition-ease--out-quintic),
      color 0.6s cubic-bezier(0.215, 0.61, 0.355, 1),
      background-color 0.6s cubic-bezier(0.215, 0.61, 0.355, 1),
      -webkit-transform var(--transition-duration--normal)
        var(--transition-ease--out-quintic);

    will-change: transform;

    -webkit-transform: translateX(100%);

            transform: translateX(100%);

    pointer-events: auto;
  }

  .music-player--disabled .music-player__player {
      pointer-events: none;
    }

  .music-player--open .music-player__player {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

  .music-player__song-name {
    flex-basis: 10rem;
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 0;

    display: -webkit-box;

    max-height: 100%;
    margin: 0 1.2rem;

    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;

    font-size: 1.6rem;
    line-height: 1.1875em;
    color: currentColor;
  }

  .music-player__controls {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    -webkit-box-align: center;
            align-items: center;
  }

  .music-player__controls-button {
    padding: 1rem;

    -webkit-transition: opacity var(--transition-duration--normal)
      var(--transition-ease--out-quintic);

    transition: opacity var(--transition-duration--normal)
      var(--transition-ease--out-quintic);
  }

  .music-player__controls-button--previous {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
    }

  .music-player__controls-button--next {
    }

  .music-player__controls-button--disabled {
      opacity: 0.4;
    }

  .music-player__controls-button .svg {
      display: block;
    }

  .music-player__controls-button .svg svg {
        display: block;
      }

  .music-player__controls-button .svg svg .triangle {
          fill: currentColor;
        }

  .music-player__controls-button .svg svg .line {
          stroke: currentColor;
        }

  .music-player__inner-toggle {
    flex-shrink: 0;

    width: 3.6rem;
    height: 3.6rem;

    border: 1px solid currentColor;
    border-radius: 50%;
  }

  .music-player__inner-toggle .svg--music-play {
        display: block;
      }

  .music-player--playing .music-player__inner-toggle .svg--music-play {
          display: none;
        }

  .music-player__inner-toggle .svg--music-pause {
        display: none;
      }

  .music-player--playing .music-player__inner-toggle .svg--music-pause {
          display: block;
        }

  .music-player__inner-toggle .svg svg {
        display: block;
        margin: auto;
      }

  .music-player__inner-toggle .svg svg path {
          fill: currentColor;
        }

  .music-player__audio {
    display: none;
  }
