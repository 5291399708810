.lifeline-shape {
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;

  opacity: 0;

  -webkit-transition: opacity 2s cubic-bezier(0.075, 0.82, 0.165, 1);

  transition: opacity 2s cubic-bezier(0.075, 0.82, 0.165, 1);
}

  .lifeline-shape.cloud {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 4;

    pointer-events: none;
  }

  .lifeline-shape.fade-in {
    opacity: 1;
  }

.dg.ac {
  z-index: 1 !important;
}
