.lifeline-interests-descriptions {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-pack: center;
          justify-content: center;

  padding: 10rem 2rem 20rem;
}

  @media (min-width: 768px) {.lifeline-interests-descriptions {
    margin: 0 8rem
}
  }

  @media (min-width: 1200px) {.lifeline-interests-descriptions {
    margin: 0
}
  }

  .lifeline-interests-descriptions__item + .lifeline-interests-descriptions__item {
      margin-top: 5.8rem;
    }

  @media (min-width: 768px) {

  .lifeline-interests-descriptions__item + .lifeline-interests-descriptions__item {
        margin-top: 4.2rem
    }
      }

  .lifeline-interests-descriptions__item-title {
    font-family: var(--font-heading);
    font-size: 3.5rem;
    line-height: 1.5em;
    text-align: center;
    color: white;
  }

  @media (min-width: 768px) {

  .lifeline-interests-descriptions__item-title {
      font-size: 8.5rem
  }
    }

  @media (min-width: 1600px) {

  .lifeline-interests-descriptions__item-title {
      font-size: 10rem
  }
    }

  .lifeline-interests-descriptions__item-details {
    font-size: 1.8rem;
    line-height: 1.5em;
    text-align: center;
    padding: 0 2rem;

    color: white;
  }

  @media (min-width: 768px) {

  .lifeline-interests-descriptions__item-details {
      font-size: 2rem;
      padding: 0
  }
    }

  @media (min-width: 1600px) {

  .lifeline-interests-descriptions__item-details {
      font-size: 3rem
  }
    }
