.container {
  max-width: var(--container-size);
  margin: 0 auto;

  padding: 2rem;
}

  .container--extra-small {
    --container-size: 49rem;
  }

  .container--small {
    --container-size: 65rem;
  }

  .container--large {
    --container-size: 100%;

    /*
    @media (--wide) {
      --container-size: 80%;
    }
    */
  }

  @media (min-width: 1024px) {

  .container--large {
      --container-size: 111.4rem
  }
    }

  .container--extra-large {
    --container-size: 100%;

    /*
    @media (--wide) {
      --container-size: 80%;
    }
    */
  }

  @media (min-width: 1024px) {

  .container--extra-large {
      --container-size: 128.2rem
  }
    }
