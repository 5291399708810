.lifeline-closure {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-pack: center;
          justify-content: center;

  min-height: 100%;
  padding: 8rem 2rem;
}

  .lifeline-closure__container {
    max-width: 97rem;
    margin: 0 auto;
  }

  @media (min-width: 768px) {

  .lifeline-closure__adjectives {
      padding: 0 5rem
  }
    }

  .lifeline-closure__adjective {
    text-transform: capitalize;
    font-size: 5rem;
    text-align: center;
    font-family: var(--font-heading);
    font-weight: var(--font-semibold);
    margin-bottom: 2.4rem;
  }

  @media (min-width: 768px) {

  .lifeline-closure__adjective {
      margin-bottom: 3.8rem;
      font-size: 8.5rem
  }
    }

  @media (min-width: 1600px) {

  .lifeline-closure__adjective {
      font-size: 15rem
  }
    }

  .lifeline-closure h2 {
    max-width: 49rem;
    margin: 0 auto;

    margin-bottom: 7rem;

    text-align: center;
    font-weight: var(--font-semibold);
  }

  @media (min-width: 768px) {

  .lifeline-closure h2 {
      max-width: 98rem;

      margin-bottom: 10rem
  }
    }
