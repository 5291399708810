.loading-shape {
  position: absolute;
  top: calc(50% - 2.5rem);
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 2;
  pointer-events: none;

  -webkit-transition: opacity var(--transition-duration--fast);

  transition: opacity var(--transition-duration--fast);
}

  .loading-shape.hidden {
    opacity: 0;
  }

  .loading-shape.colored .svg svg g:nth-child(1) path {
        stroke: var(--scooter);
      }

  .loading-shape.colored .svg svg g:nth-child(2) path {
        stroke: var(--lifeline-remembering);
      }

  .loading-shape.colored .svg svg g:nth-child(3) path {
        stroke: var(--lifeline-interests);
      }

  .loading-shape.smalt {
    width: 100%;
  }

  .loading-shape.smalt .svg svg {
      overflow: visible;
    }

  .loading-shape.smalt .text {
      font-size: 3.2rem;
      color: var(--smalt);
    }

  .loading-shape.export .text {
      font-size: 2.5rem;
      line-height: 3rem;
      color: var(--smalt);
      width: 100%;
    }

  .loading-shape .text {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-size: 1.2rem;
    font-family: var(--font-heading);
    font-weight: var(--font-normal);

    opacity: 0;
    -webkit-transition: opacity var(--transition-duration--fast);
    transition: opacity var(--transition-duration--fast);
  }

  .loading-shape .svg-homepage {
      width: 86.4vw;
    }

  @media (min-width: 500px) {

  .loading-shape .svg-homepage {
        max-width: 48rem
    }
      }

  @media (min-width: 1024px) {

  .loading-shape .svg-homepage {
        max-width: 40rem
    }

        @media (min-height: 640px) {

  .loading-shape .svg-homepage {
          max-width: 48rem
    }
        }

        @media (min-height: 768px) {

  .loading-shape .svg-homepage {
          max-width: 56rem
    }
        }

        @media (min-height: 900px) {

  .loading-shape .svg-homepage {
          max-width: 66rem
    }
        }
      }

  .loading-shape .svg svg g {
        -webkit-transition: 0.27s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: 0.27s cubic-bezier(0.645, 0.045, 0.355, 1);
        -webkit-transition-property: opacity, -webkit-transform;
        transition-property: opacity, -webkit-transform;
        transition-property: opacity, transform;
        transition-property: opacity, transform, -webkit-transform;
        -webkit-transform: scale(0.6);
                transform: scale(0.6);
        -webkit-transform-origin: center;
                transform-origin: center;
        opacity: 0;
      }

  .loading-shape .svg svg g path {
          -webkit-transform-origin: center;
                  transform-origin: center;
          -webkit-animation: headerRotateCounterclockwise 25s linear infinite;
                  animation: headerRotateCounterclockwise 25s linear infinite;
          -webkit-animation-play-state: paused;
                  animation-play-state: paused;
        }

  /* &:nth-child(1) {
          transition-delay: 0s;
          path {
            animation-duration: 25s;
          }
        } */

  .loading-shape .svg svg g:nth-child(2) {
          -webkit-transition-delay: 0.1s;
                  transition-delay: 0.1s;
        }

  .loading-shape .svg svg g:nth-child(2) path {
            -webkit-animation-duration: 15s;
                    animation-duration: 15s;
          }

  .loading-shape .svg svg g:nth-child(3) {
          -webkit-transition-delay: 0.2s;
                  transition-delay: 0.2s;
        }

  .loading-shape .svg svg g:nth-child(3) path {
            -webkit-animation-name: headerRotateClockwise;
                    animation-name: headerRotateClockwise;
            -webkit-animation-duration: 10s;
                    animation-duration: 10s;
          }

  .loading-shape .paused .svg svg g path {
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }

  .loading-shape.active .text {
      opacity: 1;
    }

  .loading-shape.active .svg svg g {
          opacity: 1;
          -webkit-transform: scale(1);
                  transform: scale(1);
        }

  .loading-shape.active .svg svg g path {
            -webkit-animation-play-state: running;
                    animation-play-state: running;
          }

@-webkit-keyframes headerRotateClockwise {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes headerRotateClockwise {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes headerRotateCounterclockwise {
  from {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@keyframes headerRotateCounterclockwise {
  from {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
