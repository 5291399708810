.lifeline-intro {
  padding: 0 2.2rem 20rem;
}

  @media (min-width: 768px) {.lifeline-intro {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    -webkit-box-pack: justify;
            justify-content: space-between;
    -webkit-box-align: start;
            align-items: flex-start;

    width: 100%;
    max-width: 144rem;
    padding: 5.8rem 8rem calc(50vh - 8.05rem - 6.5rem);
    margin: auto
}
  }

  @media (min-width: 1024px) {.lifeline-intro {
    padding: 5.8rem 10% calc(50vh - 8.05rem - 6.5rem)
}
  }

  @media (min-width: 1441px) {.lifeline-intro {
    max-width: 160rem;

    padding: 5.8rem 18.7rem calc(50vh - 8.05rem - 6.5rem)
}
  }

  .lifeline-intro__photo {
    display: inline-block;

    height: 100%;
  }

  @media (min-width: 768px) {

  .lifeline-intro__photo {
      height: 16.1rem;

      position: -webkit-sticky;

      position: sticky;
      top: calc(50% - 8.05rem)
  }
    }

  .lifeline-intro__photo__wrapper {
      position: relative;
    }

  .lifeline-intro__photo img {
      width: 13.3rem;
      height: 13.3rem;
      border-radius: 50%;

      -o-object-fit: cover;

         object-fit: cover;
    }

  @media (min-width: 768px) {

  .lifeline-intro__photo img {
        width: 16.1rem;
        height: 16.1rem
    }
      }

  .lifeline-intro__military {
    position: absolute;
    right: 0rem;
    bottom: -0.9rem;

    opacity: 0;
    -webkit-transform: rotate(360deg) scale(1.2);
            transform: rotate(360deg) scale(1.2);

    -webkit-transition: opacity var(--transition-duration--normal)
        var(--transition-ease--out-quintic),
      -webkit-transform 0.8s var(--transition-ease--out-quintic) 0.8s;

    transition: opacity var(--transition-duration--normal)
        var(--transition-ease--out-quintic),
      -webkit-transform 0.8s var(--transition-ease--out-quintic) 0.8s;

    transition: opacity var(--transition-duration--normal)
        var(--transition-ease--out-quintic),
      transform 0.8s var(--transition-ease--out-quintic) 0.8s;

    transition: opacity var(--transition-duration--normal)
        var(--transition-ease--out-quintic),
      transform 0.8s var(--transition-ease--out-quintic) 0.8s,
      -webkit-transform 0.8s var(--transition-ease--out-quintic) 0.8s;
  }

  @media (min-width: 768px) {

  .lifeline-intro__military {
      bottom: -1.5rem
  }
    }

  .lifeline-intro__military .svg {
      display: inline-block;
      width: 4.5rem;
      height: 4.5rem;
    }

  @media (min-width: 768px) {

  .lifeline-intro__military .svg {
        width: 5.5rem;
        height: 5.5rem
    }
      }

  .lifeline-intro__military--visible {
      opacity: 1;
      -webkit-transform: rotate(0deg) scale(1);
              transform: rotate(0deg) scale(1);

      -webkit-transition: opacity 1.2s var(--transition-ease--out-quintic),
        -webkit-transform 1.2s var(--transition-ease--out-quintic);

      transition: opacity 1.2s var(--transition-ease--out-quintic),
        -webkit-transform 1.2s var(--transition-ease--out-quintic);

      transition: opacity 1.2s var(--transition-ease--out-quintic),
        transform 1.2s var(--transition-ease--out-quintic);

      transition: opacity 1.2s var(--transition-ease--out-quintic),
        transform 1.2s var(--transition-ease--out-quintic),
        -webkit-transform 1.2s var(--transition-ease--out-quintic);
    }

  .lifeline-intro__adjectives__wrapper {
    -webkit-box-flex: 1;
            flex-grow: 1;

    max-width: 76rem;
  }

  @media (min-width: 768px) {

  .lifeline-intro__adjectives__wrapper {
      margin-top: calc((16.1rem - 7rem) / 2);
      margin-left: 8rem
  }
    }

  @media (min-width: 1024px) {

  .lifeline-intro__adjectives__wrapper {
      margin-top: calc((16.1rem - 10rem) / 2);
      margin-left: 16rem
  }
    }

  @media (min-width: 1441px) {

  .lifeline-intro__adjectives__wrapper {
      max-width: 90rem
  }
    }

  .lifeline-intro__adjectives {
    margin-top: 3rem;
    font-size: 3.6rem;
    line-height: 2em;
    text-transform: capitalize;
  }

  @media (min-width: 768px) {

  .lifeline-intro__adjectives {
      margin-top: 0;
      font-size: 6vw
  }
    }

  @media (min-width: 1200px) {

  .lifeline-intro__adjectives {
      font-size: 9rem
  }
    }

  @media (min-width: 1600px) {

  .lifeline-intro__adjectives {
      font-size: 10rem
  }
    }

  .lifeline-intro__adjectives li {
      margin-bottom: 4rem;
    }

  @media (min-width: 1200px) {

  .lifeline-intro__adjectives li {
        margin-bottom: 7rem
    }
      }

  .lifeline-intro__adjectives h3 {
      color: var(--smalt);
      font-size: inherit;
      line-height: 1;
      position: relative;
    }

  @media (min-width: 768px) {

  .lifeline-intro__adjectives h3 {
        line-height: 7rem
    }
      }

  @media (min-width: 1024px) {

  .lifeline-intro__adjectives h3 {
        line-height: 10rem
    }
      }

  .lifeline-intro__copy {
    margin: 7.4rem 0 0;
    font-size: 1.8rem;
    line-height: 2.5rem;
    color: black;
  }

  @media (min-width: 768px) {

  .lifeline-intro__copy {
      font-size: 2rem
  }
    }

  @media (min-width: 1600px) {

  .lifeline-intro__copy {
      font-size: 3.1rem
  }
    }

  .lifeline-intro__copy span {
      text-transform: capitalize;
      font-weight: var(--font-bold);
    }
