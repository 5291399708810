.lifeline-map {
  width: 100%;
  height: 100%;
  pointer-events: none;
}

  @media (min-width: 768px) {.lifeline-map {
    padding-top: 0
    /*clip-path: circle(0);*/
    /*transform: translate(0, 10vh);*/
}
  }

  .lifeline-map .worldmap-st0 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #d9eff2;
    stroke: var(--white);
    stroke-width: 0.2;
    stroke-miterlimit: 10;
  }

  .lifeline-map .worldmap-st1 {
    fill: #d9eff2;
    stroke: var(--white);
    stroke-width: 0.2;
    stroke-miterlimit: 10;
  }

  .lifeline-map__banner {
    width: 100%;
    height: auto;

    position: absolute;
    left: 0;
    z-index: 1000;
  }

  .lifeline-map__banner h2 {
      font-size: 2.2rem;
    }

  @media (min-width: 768px) {

  .lifeline-map__banner h2 {
        font-size: 2.5rem
    }
      }

  @media (min-width: 1600px) {

  .lifeline-map__banner h2 {
        font-size: 3.8rem
    }
      }

  .lifeline-map__banner h2::first-letter {
        text-transform: uppercase;
      }

  .lifeline-map__banner--top {
      padding-top: calc(2rem + var(--header-height));
      top: 0;
    }

  @media (min-width: 768px) {

  .lifeline-map__banner--top {
        padding-top: 10vh
    }
      }

  .lifeline-map__banner--bottom {
      padding-bottom: 7rem;
      bottom: 0;
    }

  @media (min-width: 768px) {

  .lifeline-map__banner--bottom {
        padding-bottom: 12rem
    }
      }

  .lifeline-map__map-gradient {
    width: 100%;
    height: 15rem;

    position: absolute;
    left: 0;
    z-index: 900;
  }

  @media (min-width: 768px) {

  .lifeline-map__map-gradient {
      height: 23rem
  }
    }

  .lifeline-map__map-gradient--top {
      top: 0;

      background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(rgba(255, 255, 255, 1)),
        to(rgba(255, 255, 255, 0))
      );

      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }

  .lifeline-map__map-gradient--bottom {
      top: auto;
      bottom: 0;

      background: -webkit-gradient(
        linear,
        left bottom, left top,
        from(rgba(255, 255, 255, 1)),
        to(rgba(255, 255, 255, 0))
      );

      background: linear-gradient(
        to top,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }

  .lifeline-map .mapboxgl-map {
    background: transparent;
  }

  .lifeline-map .mapboxgl-map .map-pin {
      position: absolute;
      width: 6.5rem;
      height: 6.5rem;
      left: -3.25rem;
      top: -3.25rem;
      pointer-events: auto;
    }

  .lifeline-map .mapboxgl-map .map-pin::before,
      .lifeline-map .mapboxgl-map .map-pin::after,
      .lifeline-map .mapboxgl-map .map-pin span {
        content: '';
        position: absolute;
        display: block;
        border-radius: 50%;

        -webkit-transition: background 0.3s;

        transition: background 0.3s;
      }

  .lifeline-map .mapboxgl-map .map-pin::before {
        left: 2.75rem;
        top: 2.75rem;
        width: 1rem;
        height: 1rem;
        background: #0081da;
        -webkit-animation: map-pin-inner 1s ease-in-out;
                animation: map-pin-inner 1s ease-in-out;
      }

  .lifeline-map .mapboxgl-map .map-pin--birth-place::after,
        .lifeline-map .mapboxgl-map .map-pin--birth-place span,
        .lifeline-map .mapboxgl-map .map-pin--death-place::after,
        .lifeline-map .mapboxgl-map .map-pin--death-place span,
        .lifeline-map .mapboxgl-map .map-pin.active::after,
        .lifeline-map .mapboxgl-map .map-pin.active span {
          top: 0;
          width: 6.5rem;
          height: 6.5rem;
          background: rgba(0, 129, 218, 0.5);
          -webkit-animation: map-pin-outer 3s ease-in-out 0s infinite both;
                  animation: map-pin-outer 3s ease-in-out 0s infinite both;
        }

  .lifeline-map .mapboxgl-map .map-pin--birth-place span, .lifeline-map .mapboxgl-map .map-pin--death-place span, .lifeline-map .mapboxgl-map .map-pin.active span {
          background: rgba(0, 129, 218, 0.1);
          -webkit-animation: map-pin-outer 3s ease-in-out 1.5s infinite both;
                  animation: map-pin-outer 3s ease-in-out 1.5s infinite both;
        }

  .lifeline-map .mapboxgl-map .map-pin.active::after,
        .lifeline-map .mapboxgl-map .map-pin.active span {
          -webkit-animation-iteration-count: infinite;
                  animation-iteration-count: infinite;
          -webkit-animation-delay: 0.3s;
                  animation-delay: 0.3s;
        }

  .lifeline-map .mapboxgl-map .map-pin.active::before {
          background: rgba(249, 178, 51, 1);
        }

  .lifeline-map .mapboxgl-map .map-pin.active::after {
          background: rgba(249, 178, 51, 0.5);
        }

  .lifeline-map .mapboxgl-map .map-pin.active span {
          background: rgba(249, 178, 51, 0.1);
        }

  .lifeline-map .mapboxgl-map .map-pin.active h1 {
          -webkit-animation: map-pin-text-enter 1s both;
                  animation: map-pin-text-enter 1s both;
        }

  .lifeline-map .mapboxgl-map .map-pin h1 {
        position: absolute;
        top: 2.5rem;
        left: 0;

        width: 20rem;

        font-size: 1.6rem;
        font-weight: var(--font-semibold);
        line-height: 1em;
        padding-left: 4.5rem;
        opacity: 0;
        -webkit-transform: translateY(1rem);
                transform: translateY(1rem);
      }

  @media (min-width: 1600px) {

  .lifeline-map .mapboxgl-map .map-pin h1 {
          font-size: 3rem
      }
        }

  .lifeline-map .mapboxgl-map .map-pin h1::first-letter {
          text-transform: uppercase;
        }

  .lifeline-map .mapboxgl-map .map-pin h2 {
        font-family: var(--font-paragraph);
        font-size: 1.4rem;
        margin-left: 6.5rem;
        opacity: 0;
        -webkit-transform: translateY(1rem);
                transform: translateY(1rem);
      }

  .lifeline-map .mapboxgl-map .map-pin.enter h1,
        .lifeline-map .mapboxgl-map .map-pin.enter h2 {
          -webkit-transition: opacity 1s ease-in-out, -webkit-transform 1s ease-in-out;
          transition: opacity 1s ease-in-out, -webkit-transform 1s ease-in-out;
          transition: opacity 1s ease-in-out, transform 1s ease-in-out;
          transition: opacity 1s ease-in-out, transform 1s ease-in-out, -webkit-transform 1s ease-in-out;
          opacity: 1;
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          width: 20rem;
        }

@-webkit-keyframes map-pin-text-enter {
  0% {
    opacity: 0;
    -webkit-transform: translateY(1rem);
            transform: translateY(1rem);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes map-pin-text-enter {
  0% {
    opacity: 0;
    -webkit-transform: translateY(1rem);
            transform: translateY(1rem);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes map-pin-inner {
  0% {
    opacity: 0;
    -webkit-clip-path: circle(0);
            clip-path: circle(0);
  }

  100% {
    opacity: 1;
    -webkit-clip-path: circle(0.5rem);
            clip-path: circle(0.5rem);
  }
}

@keyframes map-pin-inner {
  0% {
    opacity: 0;
    -webkit-clip-path: circle(0);
            clip-path: circle(0);
  }

  100% {
    opacity: 1;
    -webkit-clip-path: circle(0.5rem);
            clip-path: circle(0.5rem);
  }
}

@-webkit-keyframes map-pin-outer {
  0% {
    opacity: 1;
    -webkit-clip-path: circle(0);
            clip-path: circle(0);
  }

  55% {
    opacity: 1;
    -webkit-clip-path: circle(3.25rem);
            clip-path: circle(3.25rem);
  }
  90% {
    opacity: 1;
    -webkit-clip-path: circle(3.25rem);
            clip-path: circle(3.25rem);
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes map-pin-outer {
  0% {
    opacity: 1;
    -webkit-clip-path: circle(0);
            clip-path: circle(0);
  }

  55% {
    opacity: 1;
    -webkit-clip-path: circle(3.25rem);
            clip-path: circle(3.25rem);
  }
  90% {
    opacity: 1;
    -webkit-clip-path: circle(3.25rem);
            clip-path: circle(3.25rem);
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
