@font-face {
  font-family: 'Aeonik';
  src: url(https://www.memories.com/fonts/Aeonik-Medium--6d173b9a8334137238397b57782b464b.woff2) format('woff2'),
    url(https://www.memories.com/fonts/Aeonik-Medium--029c2e5db9c7486172e1966d4ecf0463.woff) format('woff');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Aeonik';
  src: url(https://www.memories.com/fonts/Aeonik-Medium--6d173b9a8334137238397b57782b464b.woff2) format('woff2'),
    url(https://www.memories.com/fonts/Aeonik-Medium--029c2e5db9c7486172e1966d4ecf0463.woff) format('woff');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Aeonik';
  src: url(https://www.memories.com/fonts/Aeonik-Regular--ea9d1548466f49c990e7c0a75ae9cac1.woff2) format('woff2'),
    url(https://www.memories.com/fonts/Aeonik-Regular--706343ec98171a6a186d8190be9a3368.woff) format('woff');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'GT Sectra Display';
  src: url(https://www.memories.com/fonts/GT-Sectra-Display-Medium--5ad5890268a0308fdbea9dde5fdadafb.woff2) format('woff2'),
    url(https://www.memories.com/fonts/GT-Sectra-Display-Medium--d708aa9539e005d053c4874032eb527a.woff) format('woff');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'GT Sectra Display';
  src: url(https://www.memories.com/fonts/GT-Sectra-Display-Regular--1b4f360188ca73db7da53b55dd2858ff.woff2) format('woff2'),
    url(https://www.memories.com/fonts/GT-Sectra-Display-Regular--c5aa0c95682f238b656a9ecc9c37d866.woff) format('woff'),
    url(https://www.memories.com/fonts/GT-Sectra-Display-Regular--a5e9fb5becacf11bced6fd21bb16af79.otf) format('opentype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'GT Sectra Display';
  src: url(https://www.memories.com/fonts/GT-Sectra-Display-Medium--5ad5890268a0308fdbea9dde5fdadafb.woff2) format('woff2'),
    url(https://www.memories.com/fonts/GT-Sectra-Display-Medium--d708aa9539e005d053c4874032eb527a.woff) format('woff'),
    url(https://www.memories.com/fonts/GT-Sectra-Display-Medium--d311b742d79de2c05b23304a7a845e0c.otf) format('opentype');
  font-style: normal;
  font-weight: 600;
}
