.about {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center;
  z-index: 998;
}

  @media (min-width: 1024px) {.about {
    width: 72vw;
    height: 56vh;
    top: 22vh;
    left: 14vw
}
  }

  .about__panel {
    width: 100%;
    height: 100%;
    padding: 2.4rem;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: start;
            align-items: flex-start;
    background-color: var(--white);
    position: relative;
  }

  @media (min-width: 768px) {

  .about__panel {
      padding: 2.4rem 8.8rem
  }
    }

  @media (min-width: 1024px) {

  .about__panel {
      width: 72vw;
      height: 56vh;
      padding: 13.4vh 11.25vw 11.1vh;
      border: 1px solid var(--smalt);
      background-image: url(https://www.memories.com/assets/images/about-us--d586e9730cca1ec483e643f56482c231.png);
      background-repeat: no-repeat;
      background-size: cover
  }
    }

  .about__panel__background {
      width: 100vh;
      height: 100vw;
      -webkit-transform: translate3d(-50%, -50%, 0) rotate(90deg);
              transform: translate3d(-50%, -50%, 0) rotate(90deg);
      position: absolute;
      top: 50%;
      left: 50%;
      background-image: url(https://www.memories.com/assets/images/about-us--d586e9730cca1ec483e643f56482c231.png);
      background-repeat: no-repeat;
      background-size: cover;
    }

  @media (min-width: 1024px) {

  .about__panel__background {
        display: none
    }
      }

  .about__panel h1,
    .about__panel p,
    .about__panel a {
      z-index: 1;
    }

  .about__panel h1 {
      margin-bottom: 3.2rem;
    }

  @media (min-width: 1024px) {

  .about__panel h1 {
        font-size: 4.2rem;
        margin-bottom: 1rem
    }
      }

  @media (--bigScreen) {

  .about__panel h1 {
        margin-bottom: 3.2rem
    }
      }

  .about__panel p {
      margin: 0;
      line-height: 2.7rem;
      color: var(--smalt);
    }

  @media (min-width: 1024px) {

  .about__panel p {
        font-size: 1.7rem;
        line-height: 2.9rem
    }
      }

  .about__panel a {
      margin-left: 0;
      margin-top: 4.8rem;
      flex-shrink: 0;
    }

  .about__panel__close {
      width: 2.4rem;
      height: 2.4rem;
      position: absolute;
      top: 2.4rem;
      right: 2.4rem;
      cursor: pointer;
    }

  @media (min-width: 1024px) {

  .about__panel__close {
        width: 3.4rem;
        height: 3.4rem;
        top: 1.6vw;
        right: 1.6vw
    }
      }

  .about__panel__close:before,
      .about__panel__close:after {
        content: '';
        display: block;
        height: 1px;
        width: calc(100% * 1.41);
        background: var(--smalt);
        position: absolute;
        top: 50%;
        left: 50%;
      }

  .about__panel__close:before {
        -webkit-transform: translate3d(-50%, -50%, 0) rotate(45deg);
                transform: translate3d(-50%, -50%, 0) rotate(45deg);
      }

  .about__panel__close:after {
        -webkit-transform: translate3d(-50%, -50%, 0) rotate(-45deg);
                transform: translate3d(-50%, -50%, 0) rotate(-45deg);
      }
