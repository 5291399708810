.input {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  margin-top: 1.5rem;
}

  .input__label {
    font-size: 1.7rem;
    line-height: 1.18em;
    text-align: inherit;

    color: var(--white);
  }

  .input--size-small .input__label {
      font-size: 1.4rem;
    }

  .input--smalt .input__label,
    .input--alt-smalt .input__label {
      color: var(--black);
      border-bottom-color: var(--smalt);
    }

  .input--black .input__label {
      color: var(--black);
      border-bottom-color: var(--black);
    }

  .input__wrapper {
    position: relative;
  }

  .input__wrapper-counter .word-counter {
        position: absolute;
        font-size: 14px;
        right: 0.3rem;
        bottom: 1rem;
      }

  @media (min-width: 768px) {

  .input__wrapper-counter .word-counter {
          bottom: 1.5rem
      }
        }

  .input__wrapper-counter input {
        text-align: left;
        padding-right: 2.5rem !important;
      }

  .input__wrapper-tag {
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
              align-items: center;
      padding: 0.9rem 1.7rem;
    }

  .input__wrapper-tag input {
        text-align: left;
        padding: 0 1rem 0 0 !important;
      }

  .input__wrapper-tag .svg {
        cursor: pointer;
        opacity: 1;
        -webkit-transform: translateZ(0) perspective(0px);
                transform: translateZ(0) perspective(0px);
      }

  .input__wrapper-tag .svg:hover {
          opacity: 0.4;
        }

  .input__fake-placeholder {
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);

    width: 100%;

    padding: 0.8rem;
    opacity: 0.3;

    font-size: 2.5rem;

    -webkit-transition: opacity 0.3s;

    transition: opacity 0.3s;
    font-family: var(--font-heading);
  }

  @media (min-width: 768px) {

  .input__fake-placeholder {
      font-size: 4rem
  }
    }

  .input__fake-placeholder::first-letter {
      text-transform: uppercase;
    }

  .input__field {
    width: 100%;
    padding: 0.8rem 0;

    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 0;

    color: var(--white);
    font-family: var(--font-heading);
    font-size: 2rem;
    line-height: 1.25em;
    text-align: inherit;
  }

  @media (min-width: 768px) {

  .input__field {
      font-size: 2.5rem
  }
    }

  @media (min-width: 1441px) {

  .input__field {
      font-size: 3rem
  }
    }

  .input--size-small .input__field {
      padding: 1.5rem 0;

      font-size: 2rem;
    }

  @media (min-width: 768px) {

  .input--size-small .input__field {
        font-size: 2rem
    }
      }

  .input--size-large .input__field {
      font-size: 2.5rem;
    }

  @media (min-width: 768px) {

  .input--size-large .input__field {
        font-size: 4rem
    }
      }

  .input--smalt .input__field {
      color: var(--smalt);
      border-bottom-color: rgba(0, 0, 0, 0.5);
    }

  .input--alt-smalt .input__field {
      color: var(--smalt);
      border-bottom-color: var(--smalt);
    }

  .input--black .input__field {
      color: var(--black);
      border-bottom-color: var(--black);
    }

  .input--wrong .input__field {
      /* color: var(--bright-red); */
      border-bottom-color: var(--bright-red);
    }

  .input__field::-webkit-input-placeholder {
      color: currentColor;
      opacity: 0.3;
      -webkit-transition: color 0.3s;
      transition: color 0.3s;
    }

  .input__field::-moz-placeholder {
      color: currentColor;
      opacity: 0.3;
      -webkit-transition: color 0.3s;
      transition: color 0.3s;
    }

  .input__field:-ms-input-placeholder {
      color: currentColor;
      opacity: 0.3;
      -webkit-transition: color 0.3s;
      transition: color 0.3s;
    }

  .input__field::-ms-input-placeholder {
      color: currentColor;
      opacity: 0.3;
      -webkit-transition: color 0.3s;
      transition: color 0.3s;
    }

  .input__field::placeholder {
      color: currentColor;
      opacity: 0.3;
      -webkit-transition: color 0.3s;
      transition: color 0.3s;
    }

  .input__field__suggestion {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      pointer-events: none;
      border-bottom: none;
    }

  .input__field__measure {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      pointer-events: none;
      display: inline;
      width: auto;
      opacity: 0;
    }

  .input--center .input__field__measure {
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
      }

  .input__bottom {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
            justify-content: space-between;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    height: 2.5rem;
  }

  /* **jc** */

  .input__bottom a {
      text-decoration: none;
    }

  .input__bottom__error {
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
              align-items: center;

      margin-top: 1rem; /* **jc** */
      font-size: 1.5rem;
      line-height: 1.5rem;
      color: var(--bright-red);
      text-align: left;

      opacity: 0;
      -webkit-transform: translateY(1.4rem);
              transform: translateY(1.4rem);

      -webkit-transition: opacity 0.6s var(--transition-ease--out-quintic),
        -webkit-transform 0.6s var(--transition-ease--out-quintic);

      transition: opacity 0.6s var(--transition-ease--out-quintic),
        -webkit-transform 0.6s var(--transition-ease--out-quintic);

      transition: opacity 0.6s var(--transition-ease--out-quintic),
        transform 0.6s var(--transition-ease--out-quintic);

      transition: opacity 0.6s var(--transition-ease--out-quintic),
        transform 0.6s var(--transition-ease--out-quintic),
        -webkit-transform 0.6s var(--transition-ease--out-quintic);
    }

  .input__bottom__error .svg--cross {
        width: 1.3rem;
        height: 1.3rem;

        display: inline-block;

        margin-right: 0.8rem;
      }

  .input__bottom__error .svg--cross path {
          stroke: var(--bright-red) !important;
        }

  .input__bottom__error--visible {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
      }

  .input--block {
    display: -webkit-box;
    display: flex;
  }

  .input--center {
    text-align: center;
  }
