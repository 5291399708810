.lifeline-family-mobile--paths {
  position: absolute;
  left: 0;
  top: 0;
  height: '100%';
  /* right: 0;
  bottom: 0; */

  pointer-events: none;
}

  .lifeline-family-mobile--paths .circle circle {
    -webkit-animation: family-circles 3s ease-in-out 0s infinite forwards;
            animation: family-circles 3s ease-in-out 0s infinite forwards;
  }

@-webkit-keyframes family-circles {
  0% {
    opacity: 1;
    -webkit-transform: translate(15px, 15px) scale(0) translate(-15px, -15px);
            transform: translate(15px, 15px) scale(0) translate(-15px, -15px);
  }

  55% {
    opacity: 1;
    -webkit-transform: translate(15px, 15px) scale(1) translate(-15px, -15px);
            transform: translate(15px, 15px) scale(1) translate(-15px, -15px);
  }
  90% {
    opacity: 1;
    -webkit-transform: translate(15px, 15px) scale(1) translate(-15px, -15px);
            transform: translate(15px, 15px) scale(1) translate(-15px, -15px);
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes family-circles {
  0% {
    opacity: 1;
    -webkit-transform: translate(15px, 15px) scale(0) translate(-15px, -15px);
            transform: translate(15px, 15px) scale(0) translate(-15px, -15px);
  }

  55% {
    opacity: 1;
    -webkit-transform: translate(15px, 15px) scale(1) translate(-15px, -15px);
            transform: translate(15px, 15px) scale(1) translate(-15px, -15px);
  }
  90% {
    opacity: 1;
    -webkit-transform: translate(15px, 15px) scale(1) translate(-15px, -15px);
            transform: translate(15px, 15px) scale(1) translate(-15px, -15px);
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
