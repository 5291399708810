.lifeline-family--profiles {
  position: relative;
  color: black;
}

.lifeline-family--profile {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.lifeline-family--profile--main {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }

.lifeline-family--profile--main {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    margin-bottom: 1.8rem;
  }

.lifeline-family--profile--pic {
    flex-shrink: 0;

    border-radius: 50%;
    width: 8rem;
    height: 8rem;
    -o-object-fit: cover;
       object-fit: cover;
  }

.lifeline-family--profile--big .lifeline-family--profile--pic {
      width: 13rem;
      height: 13rem;
    }

.lifeline-family--profile--big .lifeline-family--profile--main {
      max-width: 40rem;
    }

.lifeline-family--profile--info {
    margin-left: 1.8rem;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: center;
            justify-content: center;
    min-width: 1px;
    -webkit-box-align: stretch;
            align-items: stretch;
  }

.lifeline-family--profile--name {
    font-family: var(--font-heading);
    font-size: 2.5rem;
    line-height: 0.92em;
    margin-bottom: 1.2rem;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

@media (min-width: 1600px) {

.lifeline-family--profile--name {
      font-size: 4rem
  }
    }

.lifeline-family--profile--title {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }

@media (min-width: 1600px) {

.lifeline-family--profile--title {
      font-size: 3rem;
      line-height: 1.2em
  }
    }

.lifeline-family--profile--quote {
    font-family: var(--font-heading);
    font-size: 2rem;
    line-height: 1.2;
  }

.lifeline-family--profile--quote span {
      position: absolute;
    }
