.lifeline-ethical-will {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-pack: center;
          justify-content: center;

  min-height: 100%;

  padding: 7rem 2rem;

  position: relative;
}

  @media (min-width: 768px) {.lifeline-ethical-will {
    width: 100%;
    max-width: 117.6rem;
    margin: 0 auto;

    /*padding: 20rem 16.5rem;*/
    padding: 7rem 16.5rem
}
  }

  .lifeline-ethical-will__beacon {
    position: absolute;
    left: 0;

    width: 100%;
    border-bottom: 1px solid red;
  }

  .lifeline-ethical-will__background-beacon__end {
    width: 30%;
    height: 1px;

    position: absolute;
    bottom: 0;

    background: blue;
  }

  .lifeline-ethical-will img {
    display: block;

    width: 11rem;
    height: 11rem;
    margin: 4.8rem auto 0;

    border-radius: 100%;

    -o-object-fit: cover;

       object-fit: cover;

    flex-shrink: 0;
  }

  @media (min-width: 768px) {

  .lifeline-ethical-will img {
      width: 9.5rem;
      height: 9.5rem;
      margin: 0 3.5rem 0 0;

      position: absolute;
      top: 50%;
      right: 100%;

      -webkit-transform: translateY(-50%);

              transform: translateY(-50%)
  }
    }

  @media (min-width: 1600px) {

  .lifeline-ethical-will img {
      width: 12rem;
      height: 12rem
  }
    }

  .lifeline-ethical-will__heading {
    font-size: 1.8rem;
    line-height: 2.7rem;
    color: white;
  }

  @media (min-width: 768px) {

  .lifeline-ethical-will__heading {
      position: relative;
      margin: 0 0 8rem;

      font-size: 2rem;
      line-height: 3.2rem
  }
    }

  @media (min-width: 1600px) {

  .lifeline-ethical-will__heading {
      font-size: 3.5rem;
      line-height: 4.2rem
  }
    }

  .lifeline-ethical-will__heading::first-letter {
      text-transform: uppercase;
    }

  .lifeline-ethical-will__text-wrapper {
    position: relative;
    align-self: flex-start;
  }

  .lifeline-ethical-will__text-wrapper::before,
    .lifeline-ethical-will__text-wrapper::after {
      display: block;

      font-family: var(--font-heading);
      font-weight: var(--font-normal);

      font-size: 6rem;
      line-height: 1;
      text-align: center;
      color: white;

      -webkit-transform: translateY(-11px);

              transform: translateY(-11px);
    }

  .lifeline-ethical-will__text-wrapper::before {
      content: '“';

      margin: -1rem 0 1.3rem;
      -webkit-transform: rotate(180deg) scaleX(-1);
              transform: rotate(180deg) scaleX(-1);
    }

  .lifeline-ethical-will__text-wrapper::after {
      content: '”';
      margin-top: 2.4rem;
      opacity: 1;
      -webkit-transition: opacity var(--transition-duration--fast)
        var(--transition-duration--normal);
      transition: opacity var(--transition-duration--fast)
        var(--transition-duration--normal);
    }

  .lifeline-ethical-will__text-wrapper--collapsed::after {
        opacity: 0.1;
        -webkit-transition-delay: 0s;
                transition-delay: 0s;
      }

  @media (min-width: 768px) {

  .lifeline-ethical-will__text-wrapper--collapsed::after {
          opacity: 1
      }
        }

  @media (min-width: 768px) {

  .lifeline-ethical-will__text-wrapper {
      width: auto
  }

      .lifeline-ethical-will__text-wrapper::before,
      .lifeline-ethical-will__text-wrapper::after {
        position: absolute;
        top: 0;

        font-size: 9rem;
      }

      .lifeline-ethical-will__text-wrapper::before {
        margin: 0 3rem 0 0;
        right: 100%;

        -webkit-transform: translateY(-11px);

                transform: translateY(-11px);
      }

      .lifeline-ethical-will__text-wrapper::after {
        display: block;

        margin-left: 3rem;
        margin-top: auto;
        left: 100%;
      }
    }

  .lifeline-ethical-will__text {
    margin: 0;
    width: calc(100vw - 4rem);
    display: inline-block;
    font-size: 2.5rem;
    font-family: var(--font-heading);
    font-weight: var(--font-semibold);
    line-height: 1.4em;
    color: white;
    -webkit-transition: height var(--transition-duration--normal);
    transition: height var(--transition-duration--normal);
    overflow: hidden;
    position: relative;
  }

  @media (min-width: 768px) {

  .lifeline-ethical-will__text {
      font-size: 3rem;
      line-height: 4.6rem;
      margin-bottom: 1.6rem;
      width: 100%
  }
    }

  @media (min-width: 1600px) {

  .lifeline-ethical-will__text {
      font-size: 4.8rem;
      line-height: 1.375em
  }
    }

  .lifeline-ethical-will__text--collapsed {
      height: calc(42rem + 3rem);
    }

  .lifeline-ethical-will__text--collapsed:after {
        content: '';

        position: absolute;
        bottom: 0;
        left: 0;

        width: 100%;
        height: 25rem;

        color: white;

        background: -webkit-gradient(
          linear,
          left bottom, left top,
          from(var(--scooter)),
          to(rgba(55, 191, 209, 0))
        );

        background: linear-gradient(
          to top,
          var(--scooter) 0%,
          rgba(55, 191, 209, 0) 100%
        );
        /*transition: background-color 0.016s ease-in-out;*/
      }

  .lifeline-ethical-will .button {
    margin-left: 0;
    margin-top: 1rem;
    -webkit-transition-property: opacity, box-shadow, -webkit-transform;
    transition-property: opacity, box-shadow, -webkit-transform;
    transition-property: opacity, transform, box-shadow;
    transition-property: opacity, transform, box-shadow, -webkit-transform;

    position: absolute;
    left: 0;
    top: 100%;
    margin-bottom: 4rem;
  }

  @media (min-width: 1024px) {

  .lifeline-ethical-will .button {
      position: static;
      top: auto;
      left: auto;
      margin-bottom: auto
  }
    }

  .lifeline-ethical-will__hide {
    opacity: 0;
  }
