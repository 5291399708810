.lifeline-death-place {
  position: relative;
  height: 100%;
}

  .lifeline-death-place__beacon {
    display: block;

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 1px;
  }

  .lifeline-death-place .lifeline-map {
    -webkit-transition: -webkit-clip-path 1.2s cubic-bezier(0.86, 0, 0.07, 1);
    transition: -webkit-clip-path 1.2s cubic-bezier(0.86, 0, 0.07, 1);
    transition: clip-path 1.2s cubic-bezier(0.86, 0, 0.07, 1);
    transition: clip-path 1.2s cubic-bezier(0.86, 0, 0.07, 1), -webkit-clip-path 1.2s cubic-bezier(0.86, 0, 0.07, 1);
    -webkit-clip-path: circle(0);
            clip-path: circle(0);
  }

  .lifeline-death-place--enter .lifeline-map {
      -webkit-clip-path: circle(100%);
              clip-path: circle(100%);
    }

  .lifeline-death-place .map-pin--death-place {
    cursor: unset;
  }

  .lifeline-death-place__map {
    height: 100%;

    position: relative;
  }

  .lifeline-death-place__heading,
  .lifeline-death-place__days {
    max-width: 49rem;
    margin: 0 auto;
    padding: 0 2rem;

    text-align: center;
    font-weight: var(--font-semibold);
    font-size: 2rem;

    opacity: 0;
    -webkit-transform: translateY(5rem);
            transform: translateY(5rem);

    -webkit-transition: opacity var(--transition-duration--normal)
        var(--transition-ease--out-quintic),
      -webkit-transform 0.8s var(--transition-ease--out-quintic) 2s;

    transition: opacity var(--transition-duration--normal)
        var(--transition-ease--out-quintic),
      -webkit-transform 0.8s var(--transition-ease--out-quintic) 2s;

    transition: opacity var(--transition-duration--normal)
        var(--transition-ease--out-quintic),
      transform 0.8s var(--transition-ease--out-quintic) 2s;

    transition: opacity var(--transition-duration--normal)
        var(--transition-ease--out-quintic),
      transform 0.8s var(--transition-ease--out-quintic) 2s,
      -webkit-transform 0.8s var(--transition-ease--out-quintic) 2s;
  }

  @media (min-width: 768px) {

  .lifeline-death-place__heading,
  .lifeline-death-place__days {
      max-width: 102rem;
      margin: 0 auto;

      z-index: 1000;

      font-size: 3rem
  }
    }

  .lifeline-death-place--enter .lifeline-death-place__heading, .lifeline-death-place--enter .lifeline-death-place__days {
      -webkit-transition-delay: 0.8s;
              transition-delay: 0.8s;
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

  .lifeline-death-place--enter .lifeline-death-place__days {
      -webkit-transition-delay: 1s;
              transition-delay: 1s;
    }

  @media (min-width: 1600px) {

  .lifeline-death-place__days {
      font-size: 4rem
  }
    }
