.lifeline-interests {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-pack: center;
          justify-content: center;
  padding: 20rem 2rem 10rem;

  position: relative;
  overflow: hidden;
}

  .lifeline-interests__title {
    margin-bottom: 3rem;

    font-size: 4.8rem;
    line-height: 1em;
    text-align: center;

    color: var(--white);
  }

  @media (min-width: 768px) {

  .lifeline-interests__title {
      font-size: 8rem
  }
    }

  @media (min-width: 1024px) {

  .lifeline-interests__title {
      margin-bottom: 7.5rem;

      font-size: 17rem
  }
    }

  .lifeline-interests__claim {
    width: 100%;
    max-width: 67rem;
    margin: 0 auto 3.6rem;

    font-family: var(--font-heading);
    font-size: 2.5rem;
    line-height: 1.4em;
    text-align: center;
    color: white;
  }

  @media (min-width: 768px) {

  .lifeline-interests__claim {
      margin-bottom: 8rem;

      font-size: 3.5rem;
      line-height: 1.2em
  }
    }

  @media (min-width: 1600px) {

  .lifeline-interests__claim {
      max-width: 116rem;
      font-size: 5.7rem
  }
    }

  .lifeline-interests__intro {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
            align-items: center;

    width: 100%;
    max-width: 110rem;
    margin: 0 auto;

    position: relative;
  }

  @media (min-width: 768px) {

  .lifeline-interests__intro {
      margin: 0 6rem;
      width: calc(100% - 6rem * 2);

      -webkit-box-orient: horizontal;

      -webkit-box-direction: normal;

              flex-direction: row;
      -webkit-box-pack: justify;
              justify-content: space-between
  }

      .lifeline-interests__intro.lifeline-interests__intro--text-only {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
                flex-direction: column;
      }

        .lifeline-interests__intro.lifeline-interests__intro--text-only .lifeline-interests__intro-text {
          padding-right: 0;

          text-align: center;
        }
    }

  @media (min-width: 1200px) {

  .lifeline-interests__intro {
      margin: 0 auto;
      width: 100%
  }
    }

  .lifeline-interests__autoscroll-waypoint {
    height: 8px;
    width: 100%;

    position: absolute;
    top: calc(50% - 4px - 50vh);
    left: 0;

    pointer-events: none;
    /* opacity: 0; */

    /* background: yellow; */
  }

  .lifeline-interests__intro-text {
    width: 100%;
    max-width: 46rem;
    margin-bottom: 5.8rem;

    font-size: 1.8rem;
    line-height: 1.5em;
    text-align: center;
    color: white;
  }

  @media (min-width: 768px) {

  .lifeline-interests__intro-text {
      max-width: 49rem;
      margin: 0;
      padding-right: 5rem;

      font-size: 2rem;
      line-height: 1.4em;
      text-align: left
  }
    }

  @media (min-width: 1200px) {

  .lifeline-interests__intro-text {
      padding-right: 3rem
  }
    }

  @media (min-width: 1600px) {

  .lifeline-interests__intro-text {
      max-width: 56rem;
      font-size: 3.8rem
  }
    }

  .lifeline-interests__intro-picture {
    width: 100%;
    max-width: 41.4rem;
  }

  .lifeline-interests__intro-picture img {
      width: 100%;
    }

  @media (min-width: 768px) {
    }
