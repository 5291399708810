.lifeline-interests-details {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-pack: center;
          justify-content: center;

  padding: 0;

  position: relative;
}

  @media (min-width: 768px) {.lifeline-interests-details {
    padding: 2rem
}
  }

  .lifeline-interests-details__waypoint {
    width: 100%;
    position: absolute;
    top: calc((100% - 100vh) / 2);
    left: 0;
  }

  @media (min-width: 768px) {

  .lifeline-interests-details__inner {
      display: -webkit-box;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
              flex-direction: row;
      -webkit-box-align: center;
              align-items: center;
      -webkit-box-pack: justify;
              justify-content: space-between
  }
    }

  @media (min-width: 768px) {

  .lifeline-interests-details__circles,
  .lifeline-interests-details__quote {
      width: 50%
  }
    }

  .lifeline-interests-details__circles {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
            align-items: center;

    width: 100%;

    position: relative;
    will-change: transform;

    opacity: 0;
    -webkit-transition: opacity var(--transition-duration--normal)
      var(--transition-ease--out-quintic);
    transition: opacity var(--transition-duration--normal)
      var(--transition-ease--out-quintic);
  }

  @media (min-width: 768px) {

  .lifeline-interests-details__circles {
      width: 50%;
      margin: 0 5rem 0 auto
  }
    }

  @media (min-width: 1024px) {

  .lifeline-interests-details__circles {
      margin-right: 0
  }
    }

  .lifeline-interests-details--no-description .lifeline-interests-details__circles {
      margin: 0 auto;
    }

  .lifeline-interests-details__circles ul {
      width: 100%;
      height: 100%;

      position: relative;
    }

  .lifeline-interests-details__circles--visible {
      opacity: 1;
    }

  .lifeline-interests-details__circles--count-1 {
      height: 20rem;
    }

  @media (min-width: 768px) {

  .lifeline-interests-details__circles--count-1 {
        height: 24rem
    }
      }

  .lifeline-interests-details__circles--count-2 {
      height: 25rem;
    }

  @media (min-width: 768px) {

  .lifeline-interests-details__circles--count-2 {
        height: 32rem
    }
      }

  .lifeline-interests-details__circles--count-3 {
      height: 30rem;
    }

  @media (min-width: 768px) {

  .lifeline-interests-details__circles--count-3 {
        height: 38rem
    }
      }

  .lifeline-interests-details__circles--count-4 {
      height: 35rem;
    }

  @media (min-width: 768px) {

  .lifeline-interests-details__circles--count-4 {
        height: 46rem
    }
      }

  .lifeline-interests-details__circles--count-5 {
      height: 40rem;
    }

  @media (min-width: 768px) {

  .lifeline-interests-details__circles--count-5 {
        height: 52rem
    }
      }

  .lifeline-interests-details__circles--count-6 {
      height: 45rem;
    }

  @media (min-width: 768px) {

  .lifeline-interests-details__circles--count-6 {
        height: 60rem
    }
      }

  .lifeline-interests-details__circles--count-7 {
      height: 50rem;
    }

  @media (min-width: 768px) {

  .lifeline-interests-details__circles--count-7 {
        height: 68rem
    }
      }

  .lifeline-interests-details__circle {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
            align-items: center;

    border-radius: 50%;
    background: var(--white);

    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translate(-100%, -50%) translateX(-2.1rem);
            transform: translate(-100%, -50%) translateX(-2.1rem);

    overflow: hidden;

    font-family: var(--font-heading);
    font-size: 1.7rem;
    color: var(--resolution-blue);
    text-align: center;
  }

  @media (min-width: 1600px) {

  .lifeline-interests-details__circle {
      font-size: 3.2rem
  }
    }

  .lifeline-interests-details__circle,
    .lifeline-interests-details__circle--value-0 {
      width: 4.8rem;
      height: 4.8rem;
    }

  @media (min-width: 768px) {

  .lifeline-interests-details__circle,
    .lifeline-interests-details__circle--value-0 {
        width: 6rem;
        height: 6rem
    }
      }

  @media (min-width: 1600px) {

  .lifeline-interests-details__circle,
    .lifeline-interests-details__circle--value-0 {
        width: 11rem;
        height: 11rem
    }
      }

  .lifeline-interests-details__circle--value-1 {
      width: 7.2rem;
      height: 7.2rem;
    }

  @media (min-width: 768px) {

  .lifeline-interests-details__circle--value-1 {
        width: 9rem;
        height: 9rem
    }
      }

  @media (min-width: 1600px) {

  .lifeline-interests-details__circle--value-1 {
        width: 14.5rem;
        height: 14.5rem
    }
      }

  .lifeline-interests-details__circle--value-2 {
      width: 9.6rem;
      height: 9.6rem;
    }

  @media (min-width: 768px) {

  .lifeline-interests-details__circle--value-2 {
        width: 12rem;
        height: 12rem
    }
      }

  @media (min-width: 1600px) {

  .lifeline-interests-details__circle--value-2 {
        width: 18rem;
        height: 18rem
    }
      }

  .lifeline-interests-details__circle--value-3 {
      width: 12rem;
      height: 12rem;
    }

  @media (min-width: 768px) {

  .lifeline-interests-details__circle--value-3 {
        width: 15rem;
        height: 15rem
    }
      }

  @media (min-width: 1600px) {

  .lifeline-interests-details__circle--value-3 {
        width: 21.5rem;
        height: 21.5rem
    }
      }

  .lifeline-interests-details__circle--value-4 {
      width: 14.4rem;
      height: 14.4rem;
    }

  @media (min-width: 768px) {

  .lifeline-interests-details__circle--value-4 {
        width: 18rem;
        height: 18rem
    }
      }

  @media (min-width: 1600px) {

  .lifeline-interests-details__circle--value-4 {
        width: 25rem;
        height: 25rem
    }
      }

  .lifeline-interests-details__circle--main {
      background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(var(--lifeline-remembering)),
        to(var(--governor-bay))
      );
      background: linear-gradient(
        var(--lifeline-remembering) 0%,
        var(--governor-bay) 100%
      );

      color: white;
    }

  .lifeline-interests-details__quote-wrapper {
    display: inline-block;
  }

  .lifeline-interests-details__quote {
    display: none;

    width: 100%;
    max-width: 67.8rem;
    padding: 0 7.9rem;

    color: white;
  }

  @media (min-width: 768px) {

  .lifeline-interests-details__quote {
      display: block;
      width: 50%;
      margin: 0 auto 0 0
  }
    }

  @media (min-width: 1600px) {

  .lifeline-interests-details__quote {
      max-width: 71.6rem
  }
    }

  .lifeline-interests-details__quote blockquote {
      margin: 0;
      padding: 0;

      position: relative;

      font-family: var(--font-heading);
      font-size: 2.5rem;
      line-height: 1.5em;
    }

  @media (min-width: 768px) {

  .lifeline-interests-details__quote blockquote {
        font-size: 2rem
    }

        .lifeline-interests-details__quote blockquote::before,
        .lifeline-interests-details__quote blockquote::after {
          display: block;
          font-weight: var(--font-normal);
          font-size: 11rem;
          line-height: 1;
          -webkit-transform: translateY(-8px);
                  transform: translateY(-8px);

          position: absolute;
          top: 0;
        }
        .lifeline-interests-details__quote blockquote::before {
          content: '“';

          margin-right: 3rem;
          right: 100%;
        }
        .lifeline-interests-details__quote blockquote::after {
          content: '”';

          margin-left: 3rem;
          left: 100%;
        }
      }

  @media (min-width: 1200px) {

  .lifeline-interests-details__quote blockquote {
        font-size: 2.5rem
    }
      }

  @media (min-width: 1600px) {

  .lifeline-interests-details__quote blockquote {
        font-size: 4.8rem
    }
      }

  .lifeline-interests-details__quote cite {
      display: block;
      margin-top: 3rem;

      font-size: 2rem;
      font-style: normal;
    }

  @media (min-width: 768px) {

  .lifeline-interests-details__quote cite {
        font-size: 1.7rem
    }
      }

  @media (min-width: 1200px) {

  .lifeline-interests-details__quote cite {
        font-size: 2rem
    }
      }

  @media (min-width: 1600px) {

  .lifeline-interests-details__quote cite {
        font-size: 3rem;
        line-height: 4.5rem
    }
      }

  .lifeline-interests-details__quote-symbol {
    display: block;
    width: 100%;

    text-align: left;
    font-family: var(--font-heading);
    font-size: 6rem;
    margin-bottom: 3.5rem;
    color: white;

    line-height: 0;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

  @media (min-width: 768px) {

  .lifeline-interests-details__quote-symbol {
      display: none
  }
    }
