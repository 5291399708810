.dummy-header {
  visibility: hidden;
  height: var(--header-height);
}

.header {
  position: fixed;

  top: 0;
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;

  display: -webkit-box;

  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-align: center;
          align-items: center;

  -webkit-user-select: none;

     -moz-user-select: none;

      -ms-user-select: none;

          user-select: none;

  width: 100%;
  height: var(--header-height);
  padding: 0 2rem;

  z-index: 1000;

  background-color: var(--white);
  border-bottom: 0.1rem solid var(--smalt);

  -webkit-perspective: 50rem;

          perspective: 50rem;
}

@media (min-width: 768px) {

.header {
    border-bottom: 0;
    padding: 0 4rem;
    box-shadow: 0 1.4rem 1.6rem rgba(0, 0, 0, 0.05)
}
  }

.header--open {
    position: fixed;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    left: 0;
  }

@media (min-width: 768px) {

.header--open {
      position: static
  }
    }

.header--hidden {
    -webkit-transform: translateY(-5.5rem);
            transform: translateY(-5.5rem);
  }

@media (min-width: 768px) {

.header--hidden {
      -webkit-transform: translateY(-9.4rem);
              transform: translateY(-9.4rem)
  }
    }

.lifeline-output-recording .header {
    display: none;
  }

.header a {
    text-decoration: none;
  }

.header li {
    display: inline-block;
    margin-right: 1rem;
  }

@media (min-width: 881px) {

.header li {
      margin-right: 3.3rem
  }
    }

.header li a {
      color: currentColor;
      text-decoration: none;
    }

body.is-edge .header li a {
        display: inline-block;
        padding-bottom: 1px;
      }

.header--editor .navitem--orbiting .active .svg svg g {
            opacity: 0;
          }

@media (min-width: 1024px) {

.header--editor .navitem--orbiting:hover .svg svg g {
              opacity: 1
          }
            }

.header--editor .button-wrapper {
      width: 48px;
      height: 48px;
      border: 0.5px solid var(--white);
      border-radius: 50%;
      margin-right: 1.1rem;
      position: relative;
    }

.header--editor .button-wrapper a {
        position: absolute;
        width: 100%;
        height: 100%;
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
                align-items: center;
        outline: none;
      }

.header--editor .button-wrapper a .icons-wrapper {
          width: 100%;
          height: 48px;
          overflow: hidden;
          position: relative;
        }

.header--editor .button-wrapper a .icons-wrapper .svg {
            position: absolute;
            left: 50%;
            -webkit-transition: -webkit-transform var(--transition-duration--normal)
              var(--transition-ease--out-quintic);
            transition: -webkit-transform var(--transition-duration--normal)
              var(--transition-ease--out-quintic);
            transition: transform var(--transition-duration--normal)
              var(--transition-ease--out-quintic);
            transition: transform var(--transition-duration--normal)
              var(--transition-ease--out-quintic), -webkit-transform var(--transition-duration--normal)
              var(--transition-ease--out-quintic);
          }

.header--editor .button-wrapper a .icons-wrapper .svg:first-of-type {
            -webkit-transform: translate(-50%, 0);
                    transform: translate(-50%, 0);
          }

.header--editor .button-wrapper a .icons-wrapper .svg:last-of-type {
            -webkit-transform: translate(-50%, -100%);
                    transform: translate(-50%, -100%);
          }

@media (min-width: 1024px) {
              .header--editor .button-wrapper a:hover .icons-wrapper .svg:first-of-type, .header--editor .button-wrapper a:focus .icons-wrapper .svg:first-of-type {
                -webkit-transform: translate(-50%, 100%);
                        transform: translate(-50%, 100%);
              }

              .header--editor .button-wrapper a:hover .icons-wrapper .svg:last-of-type, .header--editor .button-wrapper a:focus .icons-wrapper .svg:last-of-type {
                -webkit-transform: translate(-50%, 0);
                        transform: translate(-50%, 0);
              }
          }

@media (min-width: 768px) {
      .header--editor .header__nav--desktop {
        display: none;
      }
      .header--editor .header__toggle,
      .header--editor .header__nav--mobile {
        display: inherit;
      }
      .header--editor .header__toggle {
        position: relative;
        left: auto;
      }
      .header--editor .header__logo--light {
        color: var(--white);
      }
      .header--editor .header__logo {
        margin-right: auto;
      }
      .header--editor .header__nav-content--top {
        font-weight: 500;
        font-size: 25px;
        line-height: 242%;
        text-align: center;
        font-family: var(--font-heading);
        padding: 0;
      }
        .header--editor .header__nav-content--top a {
          margin: 0;
        }
      .header--editor .header__nav-content--mid {
        font-weight: 500;
        font-size: 17px;
        line-height: 266.7%;
        text-align: center;
        font-family: var(--font-paragraph);
        padding: 0;
        padding-top: 5vh;
      }
        .header--editor .header__nav-content--mid a {
          margin: 0;
        }
    }

@media (min-width: 1024px) {

.header--editor {
      background: none;
      box-shadow: none;
      width: 100%; /* **jc** */
      position: fixed /* **jc** */

      /* **jc** */
  }
      .header--editor .header__nav-inner {
        width: 43%;
      }
      .header--editor .header__nav-inner-right {
        width: 57%;
      }
        .header--editor .header__nav--active .header__nav-inner {
          width: 43%;
          -webkit-transition-duration: 0s;
                  transition-duration: 0s;
          -webkit-transform: translateZ(0) rotateX(0);
                  transform: translateZ(0) rotateX(0);
        }
        .header--editor .header__nav--active .header__nav-inner-right {
          width: 57%;
          background-color: var(--smalt);
          opacity: 0.7;
          -webkit-transition-duration: 0s;
                  transition-duration: 0s;
          -webkit-transform: translateZ(0) rotateX(0);
                  transform: translateZ(0) rotateX(0);
        }
      .header--editor .header__nav--desktop {
        display: none;
      }
      .header--editor .header__toggle,
      .header--editor .header__nav--mobile {
        display: inherit;
      }
      .header--editor .header__toggle {
        position: absolute;
        left: 37vw;
      }
      .header--editor .header__nav--mobile {
        width: 100%;
      }
      .header--editor .header__logo--light {
        color: var(--white);
      }
        .header--editor .header__nav-content--bottom .svg--twitter_green_48,
        .header--editor .header__nav-content--bottom .svg--facebook_green_48,
        .header--editor .header__nav-content--bottom .svg--linkedin_green_48 {
          display: inherit;
        }
    }

.header__logo {
    font-family: var(--font-heading);
    font-size: 2.5rem;
    margin-right: auto;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;

    position: relative;
    z-index: 2;

    color: var(--smalt);
    -webkit-transition: color 0.6s var(--transition-ease--out-quintic);
    transition: color 0.6s var(--transition-ease--out-quintic);

    outline: none;
  }

.header__logo--light {
      color: var(--white);
    }

@media (min-width: 768px) {

.header__logo {
      color: var(--smalt)
  }

      .header__logo:focus:not(:hover) {
        color: var(--pinkish);
      }
    }

.header__nav--mobile {
      width: 100vw;
      height: 100vh;

      position: fixed;
      left: 0;
      top: 0;
      z-index: 1;

      /* background-color: var(--smalt); */

      pointer-events: none;

      overflow: hidden;

      opacity: 0;
      -webkit-transition: opacity 0.6s var(--transition-ease--out-quintic);
      transition: opacity 0.6s var(--transition-ease--out-quintic);
    }

.header__nav--mobile a {
        color: var(--white);
        text-decoration: none;
      }

@media (min-width: 768px) {

.header__nav--mobile {
        display: none
    }
      }

.header__nav--active {
      opacity: 1;
      pointer-events: auto;
    }

.header__nav--desktop {
      display: none;
    }

@media (min-width: 768px) {

.header__nav--desktop {
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
                flex-direction: row;
        -webkit-box-align: center;
                align-items: center;
        -webkit-box-pack: end;
                justify-content: flex-end
    }
      }

.header__nav-inner {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: justify;
            justify-content: space-between;

    height: 100%;
    width: 100%;

    background-color: var(--smalt);

    -webkit-transform: translateZ(2rem) rotateX(3deg);

            transform: translateZ(2rem) rotateX(3deg);

    -webkit-transition: -webkit-transform 0.6s var(--transition-ease--out-quintic);

    transition: -webkit-transform 0.6s var(--transition-ease--out-quintic);

    transition: transform 0.6s var(--transition-ease--out-quintic);

    transition: transform 0.6s var(--transition-ease--out-quintic), -webkit-transform 0.6s var(--transition-ease--out-quintic);
  }

.header__nav--active .header__nav-inner {
      -webkit-transition-duration: 1.2s;
              transition-duration: 1.2s;
      -webkit-transform: translateZ(0) rotateX(0);
              transform: translateZ(0) rotateX(0);
    }

.header__nav-inner-right {
    opacity: 0;
    background-color: var(--smalt);

    -webkit-transform: translateZ(2rem) rotateX(3deg);

            transform: translateZ(2rem) rotateX(3deg);

    -webkit-transition: -webkit-transform 0.6s var(--transition-ease--out-quintic);

    transition: -webkit-transform 0.6s var(--transition-ease--out-quintic);

    transition: transform 0.6s var(--transition-ease--out-quintic);

    transition: transform 0.6s var(--transition-ease--out-quintic), -webkit-transform 0.6s var(--transition-ease--out-quintic);
  }

.header__nav--active .header__nav-inner-right {
      -webkit-transition-duration: 1.2s;
              transition-duration: 1.2s;
      -webkit-transform: translateZ(0) rotateX(0);
              transform: translateZ(0) rotateX(0);
    }

.header__nav-content--top,
    .header__nav-content--mid {
      display: -webkit-box;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
              flex-direction: column;
      -webkit-box-align: center;
              align-items: center;
    }

.header__nav-content--top a, .header__nav-content--mid a {
        margin: 0;
      }

.header__nav-content--top {
      font-weight: 500;
      font-size: 25px;
      line-height: 242%;
      text-align: center;
      font-family: var(--font-heading);
      padding: 0;
    }

.header__nav-content--top .navitem--orbiting {
        color: var(--white);
        margin-right: 3.3rem;
      }

.header__nav-content--top .navitem--dropdown {
        color: var(--white);
        margin: 0;
      }

.header__nav-content--mid {
      font-weight: 500;
      font-size: 17px;
      line-height: 266.7%;
      text-align: center;
      font-family: var(--font-paragraph);
      padding-top: 5vh;
    }

.header__nav-content--mid .navitem--dropdown {
        color: var(--white);
        margin: 0;
      }

.header__nav-content--top--mid {
      height: 100%;
      display: -webkit-box;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
              flex-direction: column;
      -webkit-box-pack: center;
              justify-content: center;
    }

.header__nav-content--bottom {
      display: -webkit-box;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
              flex-direction: row;
      -webkit-box-pack: space-evenly;
              justify-content: space-evenly;
      padding: 0 3rem 5.5rem;
    }

.header__nav-content--bottom .button-wrapper {
        border: 0.5px solid var(--white);
        border-radius: 50%;
      }

.header__nav-content--bottom .svg--twitter_green_48,
      .header__nav-content--bottom .svg--facebook_green_48,
      .header__nav-content--bottom .svg--linkedin_green_48 {
        display: none;
      }

.header--main {
    padding-bottom: 2.2rem;
    font-size: 1.4rem;
    display: none;
    height: 6.4rem;
  }

@media (min-width: 768px) {

.header--main {
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
              align-items: center
  }
    }

.header--login {
    border-left: 0.1rem solid var(--lighter-gray);
    font-size: 1.4rem;
    display: none;
    height: 6.4rem;
    padding-left: 4rem;
    margin-left: 3rem;
  }

@media (min-width: 768px) {

.header--login {
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
              align-items: center
  }
    }

@media (min-width: 881px) {

.header--login {
      margin-left: 0.7rem
  }
    }

.header--login li {
      margin-right: 2rem;
    }

.header--login li:last-child {
        margin: 0;
      }

.header__toggle {
    position: absolute;
    left: 85vw;
    z-index: 2;
  }

.header__toggle svg {
      display: block;
      stroke: var(--smalt);
    }

.header__toggle svg g {
        -webkit-transition: stroke 0.6s var(--transition-ease--out-quintic),
          -webkit-transform 0.45s cubic-bezier(0.9, -0.6, 0.3, 1.6);
        transition: stroke 0.6s var(--transition-ease--out-quintic),
          -webkit-transform 0.45s cubic-bezier(0.9, -0.6, 0.3, 1.6);
        transition: transform 0.45s cubic-bezier(0.9, -0.6, 0.3, 1.6),
          stroke 0.6s var(--transition-ease--out-quintic);
        transition: transform 0.45s cubic-bezier(0.9, -0.6, 0.3, 1.6),
          stroke 0.6s var(--transition-ease--out-quintic),
          -webkit-transform 0.45s cubic-bezier(0.9, -0.6, 0.3, 1.6);

        -webkit-transform: rotate(0deg) translate(0, 0) scale(1);

                transform: rotate(0deg) translate(0, 0) scale(1);
      }

.header__toggle svg g:nth-child(2) {
          -webkit-transform-origin: center;
                  transform-origin: center;
        }

.header__toggle svg g:nth-child(3) {
          -webkit-transform-origin: bottom right;
                  transform-origin: bottom right;
        }

.header__toggle--cross svg {
        stroke: var(--white);
      }

.header__toggle--cross svg g:nth-child(1) {
            -webkit-transform: rotate(45deg) translate(1px, -4.5px) scaleX(1.1);
                    transform: rotate(45deg) translate(1px, -4.5px) scaleX(1.1);
          }

.header__toggle--cross svg g:nth-child(2) {
            -webkit-transform: rotate(-45deg) scaleX(1.3);
                    transform: rotate(-45deg) scaleX(1.3);
          }

.header__toggle--cross svg g:nth-child(3) {
            -webkit-transform: rotate(45deg) translate(2.5px, 5.5px) scaleX(0.8);
                    transform: rotate(45deg) translate(2.5px, 5.5px) scaleX(0.8);
          }

@media (min-width: 768px) {

.header__toggle {
      display: none
  }
    }
