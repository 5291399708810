.share-lifeline-modal {
  width: 100%;

  max-width: none;
  margin: 0 auto;

  padding: 4rem 1.6rem;
}

  @media (min-width: 768px) {.share-lifeline-modal {
    padding: 8rem 13rem 10rem
}
  }

  @media (min-width: 1024px) {
    .share-lifeline-modal.simple-modal {
      max-width: none;
    }
  }

  .share-lifeline-modal h2 {
    max-width: none;
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  @media (min-width: 768px) {

  .share-lifeline-modal h2 {
      font-size: 2.6rem
  }
    }

  .share-lifeline-modal p {
    max-width: 32rem;
    margin: 2rem auto 0;
  }

  @media (min-width: 768px) {

  .share-lifeline-modal p {
      margin: 0;
      max-width: none
  }
    }

  .share-lifeline-modal__group {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }

  @media (min-width: 768px) {

  .share-lifeline-modal__group {
      -webkit-box-align: end;
              align-items: flex-end;
      -webkit-box-orient: unset;
      -webkit-box-direction: unset;
              flex-direction: unset
  }
    }

  .share-lifeline-modal__group .input__bottom {
      display: none;
    }

  .share-lifeline-modal__section {
    max-width: 48rem;
    margin: 6.5rem auto 0;
    text-align: center;
  }

  .share-lifeline-modal__section:first-of-type {
      margin-top: 3.4rem;
    }

  @media (min-width: 768px) {

  .share-lifeline-modal__section:first-of-type {
        margin-top: 6.5rem
    }
      }

  @media (min-width: 768px) {

  .share-lifeline-modal__section {
      text-align: left
  }
    }

  .share-lifeline-modal .input {
    width: 100%;
    -webkit-box-flex: 1;
            flex: 1 0 auto;
    text-align: center;
  }

  @media (min-width: 768px) {

  .share-lifeline-modal .input {
      width: auto;
      margin-right: 1.1rem;

      text-align: left
  }
    }

  .share-lifeline-modal.simple-modal .button {
    max-width: 12rem;
    width: 100%;

    height: 5rem;

    padding: 0;
    margin: 2.2rem 0 0;
  }

  .share-lifeline-modal.simple-modal .button.button--loading {
      padding-right: 3rem !important;
    }

  @media (min-width: 768px) {

  .share-lifeline-modal.simple-modal .button {
      margin: 0 0 0
  }
    }
