:root {
  /* colors */

  /* Gray scale */
  --white: #fff;
  --creamy-white: #fefcef;
  --alabaster: #f8f8f8;
  --gallery: #ededed;
  --lighter-gray: #e9e9e9;
  --silver: #bdbdbd;
  --boulder: #7d7d7d;
  --black: #000000;

  /* Blues */
  --lochmara: #1081da;
  --scooter: #37bfd1;
  --persian-blue: #1f28cf;
  --governor-bay: #463cc5;
  --san-marino: #4c72b7;
  --smalt: #003698;
  --resolution-blue: #002680;

  /* Reds */
  --bright-red: #ff5151;
  --rose: #ff016d;
  --cinnabar: #ea3d32;
  --pinkish: #e4406d;
  --cerise-red: #e44065;
  --maroon-flush: #c71f6d;

  --bright-sun: #ffca43;
  --apple: #60bd40;

  --lifeline-remembering: #003698;
  --lifeline-relationships: #30b3c5;
  --lifeline-achievements: #c71f6d;
  --lifeline-interests: #0070bd;

  /* Brands */
  --google: #df4b38;
  --facebook: #4c62a3;
  --messenger: #0084ff;

  /* fonts */
  --font-heading: 'GT Sectra Display';
  --font-paragraph: 'Aeonik';
  --font-bold: 700;
  --font-semibold: 600;
  --font-medium: 500;
  --font-normal: 400;

  /* transition easings */
  --transition-ease--out-quintic: cubic-bezier(0.23, 1, 0.32, 1);

  /* transition durations */
  --transition-duration--slow: 1.4s;
  --transition-duration--normal: 0.8s;
  --transition-duration--fast: 0.4s;
  --transition-duration--instant: 0.2s;

  --slider-constant: 0;
  --ul-slider-constant: 0;
  --memories-display-items: 0;
  --column-width: calc((100vw - 4rem - 11 * 0.5rem) / 12);

  --header-height: 5.5rem;

  --page-height: calc(100vh - var(--header-height));

  /* output vars */
  --output-background-transition-speed: 0.6s /*0.8s*/;
  --output-background-easing: ease /*cubic-bezier(0.445, 0.05, 0.55, 0.95)*/;
}

  @media (min-width: 768px) {:root {
    --column-width: calc((100vw - 16rem - 11 * 2rem) / 12);
}
  }

  @media (min-width: 768px) {:root {
    --header-height: 6.4rem;
}
  }

.no-transition {
  -webkit-transition: none !important;
  transition: none !important;
}

.no-scroll {
  overflow: hidden !important;
}

.no-overscroll {
  -ms-scroll-chaining: none;
      overscroll-behavior: none;
}

a {
  color: var(--smalt);
}

.underline {
  text-decoration: none;

  position: relative;
}

.underline:after {
    content: '';

    width: 100%;
    height: 1px;

    position: absolute;
    bottom: -0.2rem;
    left: 0;

    display: inline-block;

    background: currentColor;
  }

.anim-slide-in {
  --slide-in-delay: 0.2s;

  opacity: 0;
  -webkit-transform: translateY(5rem);
          transform: translateY(5rem);

  -webkit-transition: opacity var(--transition-duration--normal)
      var(--transition-ease--out-quintic),
    -webkit-transform 0.8s var(--transition-ease--out-quintic) 2s;

  transition: opacity var(--transition-duration--normal)
      var(--transition-ease--out-quintic),
    -webkit-transform 0.8s var(--transition-ease--out-quintic) 2s;

  transition: opacity var(--transition-duration--normal)
      var(--transition-ease--out-quintic),
    transform 0.8s var(--transition-ease--out-quintic) 2s;

  transition: opacity var(--transition-duration--normal)
      var(--transition-ease--out-quintic),
    transform 0.8s var(--transition-ease--out-quintic) 2s,
    -webkit-transform 0.8s var(--transition-ease--out-quintic) 2s;
}

.anim-slide-in--enter {
    -webkit-transition-delay: var(--slide-in-delay);
            transition-delay: var(--slide-in-delay);
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

.anim-slide-in--delay-1 {
    --slide-in-delay: 0.4s;
  }

.anim-slide-in--delay-2 {
    --slide-in-delay: 0.8s;
  }

.anim-slide-in--delay-3 {
    --slide-in-delay: 1.1s;
  }

.anim-slide-in--delay-4 {
    --slide-in-delay: 1.4s;
  }

.anim-slide-in--delay-5 {
    --slide-in-delay: 1.8s;
  }

.anim-slide-in--delay-6 {
    --slide-in-delay: 2s;
  }

.anim-slide-in--delay-7 {
    --slide-in-delay: 2.4s;
  }
