.lifeline-birthday {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;

  position: relative;
  min-height: 100%;
}

  .lifeline-birthday__season {
    display: none;

    opacity: 0;
    -webkit-transform: translateY(3rem);
            transform: translateY(3rem);
    -webkit-transition: opacity 0.6s var(--transition-ease--out-quintic),
      -webkit-transform 0.6s step-end;
    transition: opacity 0.6s var(--transition-ease--out-quintic),
      -webkit-transform 0.6s step-end;
    transition: opacity 0.6s var(--transition-ease--out-quintic),
      transform 0.6s step-end;
    transition: opacity 0.6s var(--transition-ease--out-quintic),
      transform 0.6s step-end,
      -webkit-transform 0.6s step-end;
  }

  .lifeline-birthday--enter .lifeline-birthday__season {
      opacity: 1;
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
      -webkit-transition: opacity var(--transition-duration--slow)
          var(--transition-ease--out-quintic) 0.75s,
        -webkit-transform 1.4s var(--transition-ease--out-quintic) 0.75s;
      transition: opacity var(--transition-duration--slow)
          var(--transition-ease--out-quintic) 0.75s,
        -webkit-transform 1.4s var(--transition-ease--out-quintic) 0.75s;
      transition: opacity var(--transition-duration--slow)
          var(--transition-ease--out-quintic) 0.75s,
        transform 1.4s var(--transition-ease--out-quintic) 0.75s;
      transition: opacity var(--transition-duration--slow)
          var(--transition-ease--out-quintic) 0.75s,
        transform 1.4s var(--transition-ease--out-quintic) 0.75s,
        -webkit-transform 1.4s var(--transition-ease--out-quintic) 0.75s;
    }

  @media (min-width: 1024px) {

  .lifeline-birthday__season {
      position: absolute;
      top: 50%;
      right: 0;

      max-width: 40rem;
      width: 100%;

      display: block
  }

      .lifeline-birthday--enter .lifeline-birthday__season {
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
      }
    }

  @media (min-width: 1600px) {

  .lifeline-birthday__season {
      max-width: 52rem
  }
    }

  .lifeline-birthday__container {
    -webkit-box-flex: 1;
            flex-grow: 1;

    display: -webkit-box;

    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: center;
            justify-content: center;

    width: 100%;
    min-height: 100%;
    margin: 0 auto;
    padding: 10rem 2.2rem;

    color: white;
  }

  @media (min-width: 768px) {

  .lifeline-birthday__container {
      max-width: 144rem;
      padding: 13.2rem 10%
  }
    }

  .lifeline-birthday p {
    margin: 0;

    opacity: 0;
    -webkit-transform: translateY(3rem);
            transform: translateY(3rem);

    font-family: var(--font-heading);
    font-size: 2.5rem;
    line-height: 1.4em;

    -webkit-transition: opacity 0.6s var(--transition-ease--out-quintic),
      -webkit-transform 0.6s step-end;

    transition: opacity 0.6s var(--transition-ease--out-quintic),
      -webkit-transform 0.6s step-end;

    transition: opacity 0.6s var(--transition-ease--out-quintic),
      transform 0.6s step-end;

    transition: opacity 0.6s var(--transition-ease--out-quintic),
      transform 0.6s step-end,
      -webkit-transform 0.6s step-end;
  }

  @media (min-width: 768px) {

  .lifeline-birthday p {
      width: 73rem;

      font-size: 3.5rem;
      line-height: 1.35em
  }
    }

  @media (min-width: 1600px) {

  .lifeline-birthday p {
      font-size: 4.8rem
  }
    }

  .lifeline-birthday.lifeline-birthday--enter p {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transition: opacity var(--transition-duration--slow)
        var(--transition-ease--out-quintic) 0.5s,
      -webkit-transform 1.4s var(--transition-ease--out-quintic) 0.5s;
    transition: opacity var(--transition-duration--slow)
        var(--transition-ease--out-quintic) 0.5s,
      -webkit-transform 1.4s var(--transition-ease--out-quintic) 0.5s;
    transition: opacity var(--transition-duration--slow)
        var(--transition-ease--out-quintic) 0.5s,
      transform 1.4s var(--transition-ease--out-quintic) 0.5s;
    transition: opacity var(--transition-duration--slow)
        var(--transition-ease--out-quintic) 0.5s,
      transform 1.4s var(--transition-ease--out-quintic) 0.5s,
      -webkit-transform 1.4s var(--transition-ease--out-quintic) 0.5s;
  }

  .lifeline-birthday__date {
    width: 100%;
    margin: 3.7rem 0;

    opacity: 0;
    -webkit-transform: translateY(3rem);
            transform: translateY(3rem);

    font-size: 1.7rem;
    line-height: 1.47em;

    -webkit-transition: opacity 0.6s var(--transition-ease--out-quintic),
      -webkit-transform 0.6s step-end;

    transition: opacity 0.6s var(--transition-ease--out-quintic),
      -webkit-transform 0.6s step-end;

    transition: opacity 0.6s var(--transition-ease--out-quintic),
      transform 0.6s step-end;

    transition: opacity 0.6s var(--transition-ease--out-quintic),
      transform 0.6s step-end,
      -webkit-transform 0.6s step-end;
  }

  @media (min-width: 1024px) {

  .lifeline-birthday__date {
      margin: 0 0 3.7rem;
      -webkit-box-ordinal-group: 0;
              order: -1
  }
    }

  @media (min-width: 1600px) {

  .lifeline-birthday__date {
      font-size: 2.9rem
  }
    }

  .lifeline-birthday__date .svg {
      display: block;
      width: 8rem;
      height: 8rem;
      margin: 3.2rem 0 1.5rem;
    }

  @media (min-width: 1024px) {

  .lifeline-birthday__date .svg {
        margin: 0.5rem 0 1.5rem
    }
      }

  .lifeline-birthday--enter .lifeline-birthday__date {
      opacity: 1;
      -webkit-transform: translateY(0%);
              transform: translateY(0%);

      -webkit-transition: opacity var(--transition-duration--slow)
          var(--transition-ease--out-quintic) 0.75s,
        -webkit-transform 1.4s var(--transition-ease--out-quintic) 0.75s;

      transition: opacity var(--transition-duration--slow)
          var(--transition-ease--out-quintic) 0.75s,
        -webkit-transform 1.4s var(--transition-ease--out-quintic) 0.75s;

      transition: opacity var(--transition-duration--slow)
          var(--transition-ease--out-quintic) 0.75s,
        transform 1.4s var(--transition-ease--out-quintic) 0.75s;

      transition: opacity var(--transition-duration--slow)
          var(--transition-ease--out-quintic) 0.75s,
        transform 1.4s var(--transition-ease--out-quintic) 0.75s,
        -webkit-transform 1.4s var(--transition-ease--out-quintic) 0.75s;
    }

  .lifeline-birthday__spotify {
    opacity: 0;
    -webkit-transform: translateY(3rem);
            transform: translateY(3rem);

    -webkit-transition: opacity 0.6s var(--transition-ease--out-quintic),
      -webkit-transform 0.6s step-end;

    transition: opacity 0.6s var(--transition-ease--out-quintic),
      -webkit-transform 0.6s step-end;

    transition: opacity 0.6s var(--transition-ease--out-quintic),
      transform 0.6s step-end;

    transition: opacity 0.6s var(--transition-ease--out-quintic),
      transform 0.6s step-end,
      -webkit-transform 0.6s step-end;
  }

  @media (min-width: 768px) {

  .lifeline-birthday__spotify {
      width: 100%;
      margin-top: 6rem
  }
    }

  .lifeline-birthday--enter .lifeline-birthday__spotify {
      opacity: 1;
      -webkit-transform: translateY(0%);
              transform: translateY(0%);

      -webkit-transition: opacity var(--transition-duration--slow)
          var(--transition-ease--out-quintic) 1s,
        -webkit-transform 1.4s var(--transition-ease--out-quintic) 1s;

      transition: opacity var(--transition-duration--slow)
          var(--transition-ease--out-quintic) 1s,
        -webkit-transform 1.4s var(--transition-ease--out-quintic) 1s;

      transition: opacity var(--transition-duration--slow)
          var(--transition-ease--out-quintic) 1s,
        transform 1.4s var(--transition-ease--out-quintic) 1s;

      transition: opacity var(--transition-duration--slow)
          var(--transition-ease--out-quintic) 1s,
        transform 1.4s var(--transition-ease--out-quintic) 1s,
        -webkit-transform 1.4s var(--transition-ease--out-quintic) 1s;
    }

  .lifeline-birthday__spotify-start {
    display: block;
    width: 8rem;
    height: 8rem;
    padding: 0;

    border-radius: 50%;
    border: 2px solid white;
    background: none;

    position: relative;

    opacity: 1;

    flex-shrink: 0;
  }

  .lifeline-birthday__spotify-start-wrapper {
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
              align-items: center;

      position: absolute;
      top: 50%;
      left: 0;
      z-index: 2;

      -webkit-transform: translateY(-50%) translateY(-2px);

              transform: translateY(-50%) translateY(-2px);
    }

  .lifeline-birthday__spotify--active .lifeline-birthday__spotify-start-wrapper {
        opacity: 0;
        pointer-events: none;

        z-index: 1;

        -webkit-transition: opacity 1s var(--transition-ease--out-quintic);

        transition: opacity 1s var(--transition-ease--out-quintic);
      }

  .lifeline-birthday__spotify-start i {
      display: block;
      height: 0;
      width: 0;

      border-style: solid;
      border-color: transparent transparent transparent white;
      border-width: 2.2rem 0 2.2rem 3.5rem;

      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%) translateX(0.5rem);
              transform: translate(-50%, -50%) translateX(0.5rem);
      -webkit-transition: border-color var(--output-background-transition-speed)
          var(--output-background-easing),
        -webkit-transform 0.3s ease-out;
      transition: border-color var(--output-background-transition-speed)
          var(--output-background-easing),
        -webkit-transform 0.3s ease-out;
      transition: transform 0.3s ease-out,
        border-color var(--output-background-transition-speed)
          var(--output-background-easing);
      transition: transform 0.3s ease-out,
        border-color var(--output-background-transition-speed)
          var(--output-background-easing),
        -webkit-transform 0.3s ease-out;
    }

  .lifeline-birthday__spotify-start:active i {
        -webkit-transform: translate(-50%, -50%) translateX(0.5rem) scale(0.9);
                transform: translate(-50%, -50%) translateX(0.5rem) scale(0.9);
      }

  .lifeline-birthday__spotify-start-info {
    margin-left: 2rem;
  }

  .lifeline-birthday__spotify-title,
  .lifeline-birthday__spotify-artist {
    font-size: 1.7rem;
    line-height: 1.47em;

    text-align: left;
  }

  @media (min-width: 1600px) {

  .lifeline-birthday__spotify-title,
  .lifeline-birthday__spotify-artist {
      font-size: 3.1rem;
      line-height: 1.33em
  }
    }

  .lifeline-birthday__spotify-title {
    font-weight: var(--font-bold);
  }

  .lifeline-birthday__spotify-artist {
  }

  .lifeline-birthday__spotify-iframe {
    position: relative;
    z-index: 1;

    opacity: 0;
    pointer-events: none;

    -webkit-transition: opacity 1s var(--transition-ease--out-quintic);

    transition: opacity 1s var(--transition-ease--out-quintic);
  }

  @media (max-width: 340px) {

  .lifeline-birthday__spotify-iframe {
      width: 100%
  }
    }

  .lifeline-birthday__spotify--active .lifeline-birthday__spotify-iframe {
      opacity: 1;
      pointer-events: auto;
    }
