/*
  z-layers

  5. enabled section (over particles)
  4. particles
  3. enabled section (under particles)
  2. disabled section
  1. background

*/

.lifeline-output {
  height: 100%;
}

.lifeline-output__autoscroll-toggle {
    display: none;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    -webkit-box-align: center;
            align-items: center;

    padding: 0.5em 1em;

    position: fixed;
    bottom: 4.3rem;
    left: 4.3rem;
    z-index: 6;

    -webkit-transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);

    transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transition-property: color, opacity;
    transition-property: color, opacity;
    -webkit-transition-delay: 0s, 3.5s;
            transition-delay: 0s, 3.5s;
  }

@media (min-width: 768px) {

.lifeline-output__autoscroll-toggle {
      display: -webkit-box;
      display: flex
  }
    }

@media (min-width: 1600px) {

.lifeline-output__autoscroll-toggle {
      font-size: 2.9rem
  }
    }

.lifeline-output__autoscroll-toggle,
    .lifeline-output__autoscroll-toggle--dark {
      color: var(--smalt);
    }

.lifeline-output__autoscroll-toggle--light {
      color: var(--white);
    }

.lifeline-output__autoscroll-toggle--disabled {
      -webkit-transition-delay: 0s;
              transition-delay: 0s;
      opacity: 0;
      pointer-events: none;
    }

.lifeline-output__autoscroll-toggle .svg {
      display: block;
      margin-right: 1rem;
    }

.lifeline-output__autoscroll-toggle .svg svg {
        display: block;
      }

.lifeline-output__autoscroll-toggle .svg circle {
        stroke: currentColor;
      }

.lifeline-output__autoscroll-toggle .svg .play,
      .lifeline-output__autoscroll-toggle .svg .pause {
        fill: currentColor;

        -webkit-transition: opacity 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);

        transition: opacity 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
      }

.lifeline-output__autoscroll-toggle .svg .pause {
        opacity: 0;
      }

.lifeline-output__autoscroll-toggle--active .svg .play {
          opacity: 0;
        }

.lifeline-output__autoscroll-toggle--active .svg .pause {
          opacity: 1;
        }

.lifeline-output-recording .lifeline-output__autoscroll-toggle {
      opacity: 0 !important;
      -webkit-transition: none !important;
      transition: none !important;
    }

.lifeline-output__background {
    width: 100%;
    height: 100%;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;

    background: white;

    -webkit-transition: background 1.2s var(--transition-ease--out-quintic);

    transition: background 1.2s var(--transition-ease--out-quintic);
  }

@media (min-width: 768px) {

.lifeline-output__background {
      height: 100vh
  }
    }

.lifeline-output__background--intro {
      background: white;
    }

.lifeline-output__background--birthday {
      background: var(--lochmara);
    }

.lifeline-output__background--birth-place {
      -webkit-transition-delay: 1.2s;
              transition-delay: 1.2s;
      background: white;
    }

.lifeline-output__background--decades {
      background: var(--scooter);
    }

.lifeline-output__background--decades-adulthood {
      background: var(--smalt);
    }

.lifeline-output__background--family {
      background: white;
    }

.lifeline-output__background--memories {
      background: white;
    }

.lifeline-output__background--interests {
      background: var(--pinkish);
    }

.lifeline-output__background--interests-descriptions {
      background: var(--pinkish);
    }

.lifeline-output__background--interests-details {
      background: var(--pinkish);
    }

.lifeline-output__background--achievements {
      background: var(--creamy-white);
    }

.lifeline-output__background--ethical-will {
      background: var(--scooter);
    }

.lifeline-output__background--death-place {
      -webkit-transition-delay: 1.2s;
              transition-delay: 1.2s;
      background: white;
    }

.lifeline-output__background--closure {
      background: white;
    }

.lifeline-output__background--end {
      background: white;
    }

.lifeline-output-recording .lifeline-output {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
  }

/* Firefox */

.lifeline-output-recording .lifeline-output::-webkit-scrollbar {
      display: none;
    }

.lifeline-loader {
  height: var(--page-height);
  background: var(--resolution-blue);
  color: var(--white);
}

.lifeline-loader .loading-shape {
    width: 6.6rem;
    height: 6.6rem;
  }

.lifeline-loader .loading-shape svg {
      width: 100%;
      height: 100%;
    }

.lifeline-loader__message {
    position: absolute;
    top: calc(50% + 3rem);
    left: 50%;

    -webkit-transform: translate(-50%, -50%);

            transform: translate(-50%, -50%);

    margin: 0;
    font-size: 2rem;
  }

.lifeline-output-body {
  -ms-scroll-chaining: none;
      overscroll-behavior: none;
  overflow: hidden;
}

@media (min-width: 768px) {

.lifeline-output-body {
    overflow: visible
}
  }
