.lifeline-output-section {
  width: 100%;
  height: 100%;

  position: fixed;
  top: calc(100vh + 1px);
  left: 0;
  z-index: 2;

  overflow: auto;

  opacity: 0;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -ms-scroll-chaining: none;
      overscroll-behavior: none;
  -webkit-overflow-scrolling: touch;

  -webkit-transition: opacity 1s var(--transition-ease--out-quintic),
    visibility 1s step-end, top 1s step-end;

  transition: opacity 1s var(--transition-ease--out-quintic),
    visibility 1s step-end, top 1s step-end;

  visibility: hidden;
}

  .lifeline-output-recording .lifeline-output-section {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
  }

  /* Firefox */

  .lifeline-output-recording .lifeline-output-section::-webkit-scrollbar {
      display: none;
    }

  .lifeline-output-body--touch .lifeline-output-section {
    overflow: hidden;
  }

  .lifeline-output-section__inner {
    height: 100%;
  }

  .lifeline-output-section--enter,
  .lifeline-output-section--visible,
  .lifeline-output-section--exit {
    top: 0;

    -webkit-transition: opacity 1s var(--transition-ease--out-quintic),
      visibility 1s step-start;

    transition: opacity 1s var(--transition-ease--out-quintic),
      visibility 1s step-start;
    visibility: visible;
  }

  .lifeline-output-section--exit,
  .lifeline-output-section--visible {
    opacity: 1;

    z-index: 5;
  }

  .lifeline-output-section--visible {
    pointer-events: auto;
    -webkit-user-select: all;
       -moz-user-select: all;
        -ms-user-select: all;
            user-select: all;
  }

  .lifeline-output-section--enter,
  .lifeline-output-section--exit {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

  /* decades content has no exit transition */

  .lifeline-output-section--decades.lifeline-output-section--exit {
      opacity: 0;
    }
