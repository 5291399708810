.default-picture-selector {
  margin: 0 -0.7rem;
  padding: 0.9rem 0;
  text-align: left;

  display: -webkit-box;

  display: flex;
  flex-wrap: wrap;
}

  @media (min-width: 768px) {.default-picture-selector {
    margin: 0 -1rem
}
  }

  .default-picture-selector--disabled {
    opacity: 0.1;
    pointer-events: none;
  }

  .default-picture-selector__item {
    display: inline-block;
    width: 4.2rem;
    height: 4.2rem;
    padding: 0;
    margin: 0.7rem;

    border-radius: 50%;
    border: 3px solid transparent;
    background: var(--gallery) no-repeat center / cover;
    background-clip: content-box;

    -webkit-transition: border-color 0.3s ease-out;

    transition: border-color 0.3s ease-out;
  }

  .default-picture-selector__item:focus {
      border-color: var(--gallery);
    }

  .default-picture-selector__item--active,
    .default-picture-selector__item--active:focus {
      border-color: var(--smalt);
    }
