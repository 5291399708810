.date-picker {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
}

  .date-picker--block {
    display: -webkit-box;
    display: flex;
    margin: 1.5rem 0 1.8rem 0;
  }

  .date-picker--disabled {
    pointer-events: none;
  }

  .date-picker--nativePicker {
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);

    display: block;
    height: 0;
    width: 0;
    opacity: 0;
    font-size: 0;
    border: none;
    margin: 0;
    padding: 0;
    font-size: 1.6rem;
  }

  .date-picker .select {
    flex-basis: 30%;
    margin-right: 2rem;
  }

  .date-picker .select .input__field {
      border-radius: 0;
    }

  .date-picker .select:last-child {
      flex-basis: 40%;
      margin-right: 0;
    }

  .date-picker .select input {
      margin-top: 0;
      text-align: left;
    }

  .date-picker .select--arrow {
      /* top: 65%; **jc** */
    }

  .date-picker--smalt {
    padding-left: 0;
    padding-right: 0;
  }

  .date-picker--smalt .select {
      color: var(--smalt);
    }

  .date-picker--smalt .select .select--arrow svg path {
          stroke: var(--smalt) !important;
        }

  .date-picker--small .select {
      margin-right: 1rem;
    }

  .date-picker--small .select .input {
        padding: 0 0 1rem;
        font-size: 1.7rem;
      }

  .date-picker input[type='number']::-webkit-outer-spin-button,
  .date-picker input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .date-picker input[type='number'] {
    -moz-appearance: textfield;
  }
