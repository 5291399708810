.progress-bar {
  position: fixed;

  top: 0;
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;

  display: -webkit-box;

  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-align: start;
          align-items: flex-start;

  -webkit-user-select: none;

     -moz-user-select: none;

      -ms-user-select: none;

          user-select: none;

  width: 100%;
  height: auto;

  z-index: 998; /* under header */

  -webkit-transition: background 1.2s var(--transition-ease--out-quintic);

  transition: background 1.2s var(--transition-ease--out-quintic);

  background-color: var(--white);
}

  @media (max-width: 767px) {.progress-bar {
    background: none !important
}
  }

  .progress-bar__line-wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    -webkit-box-align: start;
            align-items: flex-start;
    -webkit-box-flex: 1;
            flex: 1;
    position: relative;
    overflow: hidden;
  }

  .progress-bar__line {
    position: absolute;
    background-color: red;
    height: 10px;
    color: #000;
    -webkit-transition: width 0.3s, color 0.7s;
    transition: width 0.3s, color 0.7s;
  }

  .progress-bar__line-edge {
    background-color: red;
    height: 10px;
    -webkit-box-flex: 0;
            flex: 0;
    flex-basis: 5px;
    width: 5px;
    -webkit-transition: color 0.7s;
    transition: color 0.7s;
  }

  @media (min-width: 768px) {

  .progress-bar__line-edge {
      flex-basis: 30px;
      width: 30px
  }
    }

  .progress-bar__item {
    color: #000;

    padding: 15px 0;
    font-size: 10px;
    text-transform: capitalize;
    -webkit-transition: color 0.7s, opacity 1s;
    transition: color 0.7s, opacity 1s;
    position: relative;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  @media (min-width: 920px) {

  .progress-bar__item {
      font-size: 13px
  }
    }

  @media (min-width: 1600px) {

  .progress-bar__item {
      padding: 27px 0 15px
  }
    }

  .progress-bar__item:hover .progress-bar__item-underlay {
      opacity: 0.2;
    }

  .progress-bar__item-underlay {
    display: none;
    background-color: currentColor;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 30px;
    left: 0;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;
  }

  .progress-bar__item-label {
    display: none;
    border-left-color: currentColor;
    border-left-width: 1px;
    border-left-style: solid;
    padding-left: 7px;
    height: 13px;
    line-height: 13px;
  }

  @media (min-width: 768px) {

  .progress-bar__item-label {
      display: inline
  }
    }

  @media (min-width: 1600px) {

  .progress-bar__item-label {
      font-size: 2.4rem
  }
    }

  .progress-bar__item:hover .progress-bar__item-label {
    font-weight: bold;
  }
