/* 1280  ---  12 columns
  106
*/

.lifeline-achievements {
  --lateral-padding: 0rem;
  --gap: 2rem;

  --column-width: calc(
    (100vw - 2 * var(--lateral-padding) - 11 * var(--gap)) / 12
  );

  --vertical-padding: 10rem;

  padding: var(--vertical-padding) var(--lateral-padding);

  position: relative;
}

@media (min-width: 768px) {

.lifeline-achievements {
    --vertical-padding: 20rem;
    --lateral-padding: 8rem
}
  }

.lifeline-achievements__title {
    margin-bottom: 3rem;

    font-size: 4.8rem;
    line-height: 1em;
    text-align: center;

    color: var(--black);
  }

@media (min-width: 768px) {

.lifeline-achievements__title {
      font-size: 8rem
  }
    }

@media (min-width: 1024px) {

.lifeline-achievements__title {
      margin-bottom: 7.5rem;

      font-size: 17rem
  }
    }

.lifeline-achievements__subtitle {
    max-width: 33rem;
    margin: 0 auto 10rem;

    font-size: 3rem;
    font-weight: var(--font-semibold);
    color: black;
    text-align: center;
  }

@media (min-width: 768px) {

.lifeline-achievements__subtitle {
      max-width: none;
      margin: 0 0 10rem
  }
    }

@media (min-width: 1600px) {

.lifeline-achievements__subtitle {
      font-size: 5.7rem
  }
    }

.lifeline-achievements__beacon {
    display: block;

    position: absolute;
    top: -30rem;
    left: 0;

    width: 100%;
    height: 1px;

    border-bottom: 1px solid blue;
  }

.lifeline-achievements__background-beacon__end {
    display: block;
    border-bottom: 1px solid blue;
  }

.lifeline-achievements__list {
  }

.lifeline-achievements__achievement {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }

@media (min-width: 768px) {

.lifeline-achievements__achievement {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
              flex-direction: row
  }
    }

.lifeline-achievements__achievement + .lifeline-achievements__achievement {
      margin-top: 10rem;
    }

@media (min-width: 768px) {

.lifeline-achievements__achievement + .lifeline-achievements__achievement {
        margin-top: 5.5rem
    }
      }

.lifeline-achievements__achievement img {
      max-height: 40vh;

      -o-object-fit: cover;

         object-fit: cover;

      flex-shrink: 0;

      margin-bottom: 2rem;
    }

@media (min-width: 768px) {

.lifeline-achievements__achievement img {
        margin-bottom: 0;

        -o-object-fit: contain;

           object-fit: contain
    }
      }

.lifeline-achievements__achievement__info {
      display: -webkit-box;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
              flex-direction: column;
      -webkit-box-pack: center;
              justify-content: center;

      /*outline: 1px solid magenta;*/

      flex-shrink: 0;

      text-align: left;
      -webkit-box-align: start;
              align-items: flex-start;

      padding: 0 2rem;
    }

@media (min-width: 768px) {

.lifeline-achievements__achievement__info {
        padding: 0
    }
      }

.lifeline-achievements__achievement__info h3 {
        display: -webkit-box;
        display: flex;
        flex-wrap: wrap;

        font-size: 6rem;
        line-height: 1em;
        margin-bottom: 5rem;

        color: var(--cerise-red);
        font-weight: var(--font-semibold);
      }

@media (min-width: 768px) {

.lifeline-achievements__achievement__info h3 {
          font-size: 14rem
      }
        }

.lifeline-achievements__achievement__info p {
        font-size: 2rem;
        line-height: 2.8rem;
        margin: 0;
        color: black;
      }

@media (min-width: 1600px) {

.lifeline-achievements__achievement__info p {
          font-size: 3rem;
          line-height: 4rem
      }
        }

@media (min-width: 768px) {

.lifeline-achievements__achievement--style-1 img {
          width: calc(var(--column-width) * 5 + 4 * var(--gap));
          margin-left: calc(var(--column-width) + var(--gap));
          margin-right: calc(var(--column-width) + 2 * var(--gap));

          -o-object-position: right;

             object-position: right
      }
        }

@media (min-width: 1200px) {

.lifeline-achievements__achievement--style-1 img {
          width: calc(var(--column-width) * 7 + 6 * var(--gap));
          margin-left: calc(var(--column-width) + var(--gap));
          margin-right: calc(var(--column-width) + 2 * var(--gap))
      }
        }

@media (min-width: 768px) {

.lifeline-achievements__achievement--style-1 div {
          width: calc(var(--column-width) * 5 + 4 * var(--gap))
      }
        }

@media (min-width: 1200px) {

.lifeline-achievements__achievement--style-1 div {
          width: calc(var(--column-width) * 3 + 2 * var(--gap))
      }
        }

@media (min-width: 768px) {

.lifeline-achievements__achievement--style-2 {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
                flex-direction: row-reverse
    }
      }

@media (min-width: 768px) {

.lifeline-achievements__achievement--style-2 div {
          width: calc(var(--column-width) * 4 + 3 * var(--gap));
          margin-left: 0
      }
        }

@media (min-width: 1200px) {

.lifeline-achievements__achievement--style-2 div {
          width: calc(var(--column-width) * 3 + 3 * var(--gap));
          margin-left: calc(var(--column-width) + var(--gap))
      }
        }

@media (min-width: 768px) {

.lifeline-achievements__achievement--style-2 img {
          width: calc(var(--column-width) * 5 + 4 * var(--gap));
          margin-left: calc(var(--column-width) + 2 * var(--gap));
          margin-right: calc(var(--column-width) * 2 + 2 * var(--gap));

          -o-object-position: left;

             object-position: left
      }
        }

@media (min-width: 1024px) {

.lifeline-achievements__achievement--style-2 img {
          width: calc(var(--column-width) * 4 + 3 * var(--gap))
      }
        }

@media (min-width: 768px) {

.lifeline-achievements__achievement--style-3 img {
          width: calc(var(--column-width) * 6 + 5 * var(--gap));
          margin-left: 0;
          -o-object-position: right;
             object-position: right
      }
        }

@media (min-width: 1200px) {

.lifeline-achievements__achievement--style-3 img {
          width: calc(var(--column-width) * 4 + 3 * var(--gap));
          margin-left: calc(var(--column-width) * 2 + 2 * var(--gap))
      }
        }

@media (min-width: 768px) {

.lifeline-achievements__achievement--style-3 div {
          width: calc(var(--column-width) * 5 + 4 * var(--gap));
          margin-left: calc(var(--column-width) + var(--gap));
          margin-right: calc(var(--column-width) + 2 * var(--gap))
      }
        }

@media (min-width: 1200px) {

.lifeline-achievements__achievement--style-3 div {
          width: calc(var(--column-width) * 4 + 3 * var(--gap));
          margin-left: calc(var(--column-width) + var(--gap));
          margin-right: calc(var(--column-width) + 2 * var(--gap))
      }
        }

@media (min-width: 768px) {

.lifeline-achievements__achievement--style-4 {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
                flex-direction: row-reverse
    }
      }

@media (min-width: 768px) {

.lifeline-achievements__achievement--style-4 img {
          width: calc(var(--column-width) * 6 + 5 * var(--gap));
          margin-right: 0;
          margin-left: calc(var(--column-width) + 2 * var(--gap));

          -o-object-position: left;

             object-position: left
      }
        }

@media (min-width: 1200px) {

.lifeline-achievements__achievement--style-4 img {
          width: calc(var(--column-width) * 7 + 6 * var(--gap));
          margin-right: calc(var(--column-width) + var(--gap));
          margin-left: calc(var(--column-width) + 2 * var(--gap))
      }
        }

@media (min-width: 768px) {

.lifeline-achievements__achievement--style-4 div {
          width: calc(var(--column-width) * 5 + 4 * var(--gap))
      }
        }

@media (min-width: 1200px) {

.lifeline-achievements__achievement--style-4 div {
          width: calc(var(--column-width) * 3 + 2 * var(--gap))
      }
        }

.lifeline-achievements__achievement--no-photo div {
        width: calc(var(--column-width) * 12 + 11 * var(--gap));
        margin-right: 0;
        margin-left: 0;
      }

@media (min-width: 768px) {

.lifeline-achievements__achievement--no-photo div {
          text-align: center;
          -webkit-box-align: center;
                  align-items: center
      }
        }

.lifeline-achievements__achievement--no-photo div h3 {
          margin-bottom: 5rem;
        }

.lifeline-achievements__achievement--no-photo div p {
          max-width: 50rem;
        }

.lifeline-achievements__achievement--no-photo + .lifeline-achievements__achievement--no-photo {
        margin-top: 10rem;
        margin-bottom: 10rem;
      }
